import React, { useMemo, useCallback, useState, useEffect } from 'react'
import {
  View,
  Platform,
  FlatList,
  StyleSheet,
  ListRenderItem,
  TouchableOpacity,
} from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import {
  User,
  GroupAccount,
  UserGenderEnum,
  GroupAccountTypeEnum,
  useGetGroupAccountsQuery,
  useMarkNotificationSeenMutation,
  useGetUnseenInviteNotificationsQuery,
} from '../api/types'
import Avatar from '../components/Avatar'
import Loading from '../components/Loading'
import PickerModal from '../components/PickerModal'
import Separator from '../components/Separator'
import SvgIcon from '../components/SvgIcon'
import TitleText from '../components/Text/TitleText'
import Typography from '../components/Text/Typography'
import core from '../core/core'
import getIsGroupAdminOnly from '../helpers/getIsGroupAdminOnly'
import { useUser } from '../providers/UserProvider'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import FontSizes from '../styles/FontSizes'
import { MainStackNavigationType } from '../types/navigation-types'

const Divider = () => <Separator height={10} />

type ListItemDataType = {
  avatar: {
    url?: string | null
    gender?: GroupAccountTypeEnum | UserGenderEnum | null
  }
  name?: string | null
  info: string
  account: GroupAccount | User
  isUnseenInvitation?: boolean
}
function keyExtractor(item: ListItemDataType, _index: number) {
  return `${item.account.__typename}-${item.account.id}`
}

export default function PickAccountModal(): JSX.Element {
  const { goBack, navigate } =
    useNavigation<MainStackNavigationType<'PickAccount'>>()
  const { t } = useTranslation()

  const { user, switchAccount } = useUser()
  const { isGroupAdminOnly, canCreateGroupAccount } = useMemo(
    () => ({
      isGroupAdminOnly: getIsGroupAdminOnly(user),
      // TODO: Uncomment when GroupAccount creation flow (while signed-in) is implemented
      // canCreateGroupAccount: user?.userType === 'UnlimitedUser' && !user.managedGroupAccount,
      canCreateGroupAccount: false,
    }),
    [user],
  )

  const { data, loading } = useGetGroupAccountsQuery()
  const [isSwitching, setIsSwitching] = useState(false)

  const { data: notificationsData, client } =
    useGetUnseenInviteNotificationsQuery()
  const [markSeen] = useMarkNotificationSeenMutation()

  useEffect(() => {
    notificationsData?.inviteNotifications?.nodes?.forEach(({ id }) => {
      markSeen({ variables: { id } })
    })
  }, [notificationsData, markSeen])

  useEffect(() => {
    return () => {
      client.cache.evict({
        fieldName: 'inviteNotifications',
      })
      client.cache.gc()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const items = useMemo(() => {
    const array = [] as ListItemDataType[]
    if (user && user.userType !== 'LimitedUser' && !isGroupAdminOnly) {
      const { pictureUrl, gender, lastName, firstName, email } = user
      array.push({
        avatar: { url: pictureUrl, gender },
        name: lastName ? `${firstName} ${lastName}` : firstName,
        info: email,
        account: user,
      })
    }
    if (data?.groupAccounts?.nodes) {
      array.push(
        ...data.groupAccounts.nodes.map(account => ({
          avatar: { url: account.pictureUrl, gender: account.accountType },
          name: account.name,
          info: t(`screens.registration.accountType.${account.accountType}`),
          account,
          isUnseenInvitation:
            notificationsData?.inviteNotifications?.nodes?.find(
              noti => noti.groupAccountId === account.id,
            )?.seen === false,
        })),
      )
    }

    return array
  }, [user, data, t, isGroupAdminOnly, notificationsData])

  const onAccountPress = useCallback(
    (account: GroupAccount | User) => {
      setIsSwitching(true)
      switchAccount(account)
        .then(goBack)
        .catch(core.logging.error)
        .finally(() => setIsSwitching(false))
    },
    [switchAccount, goBack],
  )

  const renderItem: ListRenderItem<ListItemDataType> = useCallback(
    ({ item }) => (
      <TouchableOpacity
        style={styles.item}
        onPress={() => onAccountPress(item.account)}>
        <Avatar gender={item.avatar.gender} size={60} uri={item.avatar.url} />
        <Separator width={15} />
        <View style={appStyles.fullSize}>
          <Typography weight="medium" style={styles.nameText}>
            {item.name}
          </Typography>
          <Typography weight="light" style={styles.emailText}>
            {item.info}
          </Typography>
        </View>
        {item.isUnseenInvitation && (
          <SvgIcon name="bell" width={24} height={24} style={styles.bell} />
        )}
      </TouchableOpacity>
    ),
    [onAccountPress],
  )

  const renderFooter = useCallback(() => {
    const onPress = isGroupAdminOnly
      ? Platform.OS === 'web'
        ? () => navigate('Subscriptions', { personalUpgrade: true })
        : () => navigate('UpgradeInfo')
      : canCreateGroupAccount
      ? // TODO: Create this. Use GroupAccountForm, but first pick accountType
        // @ts-ignore
        () => navigate('CreateGroupAccount')
      : undefined
    if (!onPress) return null

    return (
      <TouchableOpacity style={styles.footerPressable} onPress={onPress}>
        <View style={styles.footerIconHolder}>
          <SvgIcon
            width={16}
            height={16}
            name="plusThin"
            color={Colors['layout.dark']}
          />
        </View>
        <Separator width={15} />
        <Typography weight="medium" style={styles.addButtonLabel}>
          {t('modals.pickAccount.addButton')}
        </Typography>
      </TouchableOpacity>
    )
  }, [t, navigate, isGroupAdminOnly, canCreateGroupAccount])

  return (
    <PickerModal close={goBack}>
      <Loading blocking={isSwitching} loading={loading || isSwitching} />
      <FlatList
        data={items}
        contentContainerStyle={styles.flatlistContentContainer}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        ItemSeparatorComponent={Divider}
        ListHeaderComponent={
          <>
            <View style={appStyles.inlineContainer}>
              <View style={appStyles.fullSize}>
                <TitleText color="layout.dark" size="medium">
                  {t('modals.pickAccount.title')}
                </TitleText>
              </View>
              <TouchableOpacity
                // @ts-ignore
                hitSlop={10}
                onPress={goBack}>
                <SvgIcon name="close" />
              </TouchableOpacity>
            </View>
            <Separator height={30} />
          </>
        }
        ListFooterComponent={renderFooter}
      />
    </PickerModal>
  )
}

const styles = StyleSheet.create({
  flatlistContentContainer: {
    paddingTop: 18,
    paddingBottom: 30,
    paddingHorizontal: 24,
  },
  item: {
    minHeight: 107,
    borderRadius: 10,
    paddingHorizontal: 20,
    ...appStyles.inlineContainer,
    backgroundColor: Colors['layout.white'],
  },
  nameText: { fontSize: 18 },
  emailText: { fontSize: 14 },
  bell: {
    top: 10,
    right: 10,
    position: 'absolute',
  },
  footerPressable: {
    paddingVertical: 20,
    marginRight: 'auto',
    ...appStyles.inlineContainer,
  },
  footerIconHolder: {
    width: 50,
    height: 50,
    borderRadius: 10,
    ...appStyles.center,
    ...appStyles.lightShadow,
    backgroundColor: Colors['layout.white'],
  },
  addButtonLabel: { fontSize: FontSizes['button.large'] },
})
