import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react'
import {
  View,
  FlatList,
  Platform,
  ScrollView,
  StyleSheet,
  ListRenderItem,
  KeyboardAvoidingView,
} from 'react-native'

import {
  useRoute,
  RouteProp,
  StackActions,
  useNavigation,
} from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { Asset } from 'react-native-image-picker'

import GroupDetailsForm, { GroupParams } from './components/GroupDetailsForm'
import { ProfileOptionsPicker } from './components/ProfileOptionsPicker'
import { ProfileRegistrationForm } from './components/ProfileRegistrationForm'
import {
  UserTitleEnum,
  UserGenderEnum,
  UserCountryEnum,
  GroupAccountTypeEnum,
} from '../../api/types'
import Loading from '../../components/Loading'
import ProgressBar from '../../components/ProgressBar'
import Typography from '../../components/Text/Typography'
import TouchableSvg from '../../components/TouchableSvg'
import RestApi from '../../core/RestApi'
import buildGetItemLayout from '../../helpers/buildgetLayout'
import {
  getGenderItems,
  getCountriesItems,
  getAccountTypeItems,
  ProfileFormItemKeyType,
} from '../../helpers/ProfileFormItems'
import toISO8601DateString from '../../helpers/toISO8601DateString'
import { useBackAwareFlatList } from '../../hooks/useBackAwareFlatList'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import useStateRef from '../../hooks/useStateRef'
import { useLocale } from '../../providers/LocaleProvider'
import { useWebLayout } from '../../providers/WebLayoutProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import {
  UnauthorizedStackParamsType,
  UnauthorizedStackNavigationType,
} from '../../types/navigation-types'

export default function RegistrationScreen(): JSX.Element {
  const { t } = useTranslation()
  const { webLayoutEnabled } = useWebLayout()
  const navigation =
    useNavigation<UnauthorizedStackNavigationType<'Registration'>>()
  const { params } =
    useRoute<RouteProp<UnauthorizedStackParamsType, 'Registration'>>()
  const { stepIndex, flatListRef, scrollToIndex, navigateBack } =
    useBackAwareFlatList()
  const [selectedCountry, setSelectedCountry] =
    useState<UserCountryEnum | null>(null)
  const [selectedGender, setSelectedGender] = useState<UserGenderEnum | null>(
    null,
  )
  const [selectedGroupAccountType, setSelectedGroupAccountType] =
    useState<GroupAccountTypeEnum | null>(null)
  const groupAccountParamsRef = useRef<GroupParams>()

  const { locale } = useLocale()

  const steps = useMemo(() => Array(4), [])
  const countriesItems = useMemo(() => getCountriesItems(t), [t])
  const accountTypeItems = useMemo(() => getAccountTypeItems(t), [t])
  const genderItems = useMemo(() => getGenderItems(t), [t])
  const [isSaving, setIsSaving, getIsSaving] = useStateRef(false)

  const onCountryPicked = useCallback(
    (key: ProfileFormItemKeyType) => {
      setSelectedCountry(key as UserCountryEnum)
      scrollToIndex(1)
    },
    [scrollToIndex],
  )

  const onAccountTypePicked = useCallback(
    (key: ProfileFormItemKeyType) => {
      setSelectedGroupAccountType(key as GroupAccountTypeEnum)
      scrollToIndex(2)
    },
    [scrollToIndex],
  )

  useEffect(
    () => {
      if (params?.accountTypePickFromDetailedList) {
        onAccountTypePicked(params.accountTypePickFromDetailedList)
        navigation.setParams({ accountTypePickFromDetailedList: undefined })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params?.accountTypePickFromDetailedList, onAccountTypePicked],
  )

  const onGenderPicked = useCallback(
    (key: ProfileFormItemKeyType) => {
      setSelectedGender(key as UserGenderEnum)
      scrollToIndex(3)
    },
    [scrollToIndex],
  )

  const createAccount = useCallback(
    async (
      title: UserTitleEnum | null,
      name: string,
      surname: string,
      email: string,
      birthday: Date,
      password: string,
      picture?: Asset,
    ) => {
      if (getIsSaving()) return

      setIsSaving(true)

      const data = new FormData()
      data.append('email', email)
      data.append('password', password)
      data.append('first_name', name)
      data.append('last_name', surname)
      data.append('country', selectedCountry ?? '')
      title && data.append('title', title)
      data.append('gender', selectedGender ?? '')
      data.append('date_of_birth', toISO8601DateString(birthday))
      data.append('language', locale ?? 'en')
      if (selectedGroupAccountType) {
        const groupParams = groupAccountParamsRef.current

        data.append(
          'owned_group_account_attributes[account_type]',
          selectedGroupAccountType,
        )
        data.append(
          'owned_group_account_attributes[name]',
          groupParams?.groupName,
        )
        data.append(
          'owned_group_account_attributes[founding_year]',
          groupParams?.foundedAt?.toISOString(),
        )
        data.append(
          'owned_group_account_attributes[founding_year_prec]',
          groupParams?.foundedAtPrecision,
        )
      }
      if (picture?.uri) {
        if (Platform.OS === 'web') {
          const file = await fetch(picture.uri)
          const blob = await file.blob()
          data.append('picture', blob)
        } else {
          data.append('picture', {
            name: picture.fileName,
            type: picture.type,
            uri: picture.uri,
          })
        }
      }
      await RestApi.post('users', data)
        .then(() =>
          navigation.dispatch(StackActions.replace('EmailConfirmation')),
        )
        .finally(() => setIsSaving(false))
    },
    [
      locale,
      navigation,
      getIsSaving,
      setIsSaving,
      selectedGender,
      selectedCountry,
      selectedGroupAccountType,
    ],
  )

  const [width, setWidth] = useState<number>()

  const renderStep: ListRenderItem<number> = useCallback(
    ({ index }) => {
      if (!width) return null
      switch (index) {
        case 0:
          return (
            <ProfileOptionsPicker
              width={width}
              options={countriesItems}
              selectedKey={selectedCountry}
              title={t('screens.registration.selectCountry')}
              onOptionPicked={onCountryPicked}
            />
          )
        case 1:
          return (
            <ProfileOptionsPicker
              width={width}
              options={accountTypeItems}
              selectedKey={selectedGroupAccountType}
              title={t('screens.registration.selectAccountType')}
              ListFooterComponent={
                <Typography
                  weight="light"
                  style={styles.footerText}
                  onPress={() => navigation.navigate('AccountTypesDetailed')}>
                  {t('screens.registration.accountType.moreInfo')}
                </Typography>
              }
              onOptionPicked={onAccountTypePicked}
            />
          )
        case 2:
          if (!selectedGroupAccountType) {
            return (
              <ProfileOptionsPicker
                width={width}
                options={genderItems}
                selectedKey={selectedGender}
                title={t('screens.registration.selectGender')}
                onOptionPicked={onGenderPicked}
              />
            )
          }

          return (
            <GroupDetailsForm
              accountType={selectedGroupAccountType}
              width={width}
              onContinue={groupParams => {
                groupAccountParamsRef.current = groupParams
                scrollToIndex(3)
              }}
            />
          )
        case 3:
          return (
            <ProfileRegistrationForm
              width={width}
              gender={selectedGender}
              isGroupAdmin={!!selectedGroupAccountType}
              setAdminGender={setSelectedGender}
              onCreateAccount={createAccount}
            />
          )
        default:
          return <View style={{ width }} />
      }
    },
    [
      t,
      width,
      navigation,
      genderItems,
      scrollToIndex,
      createAccount,
      countriesItems,
      onGenderPicked,
      selectedGender,
      onCountryPicked,
      selectedCountry,
      accountTypeItems,
      selectedGroupAccountType,
      onAccountTypePicked,
    ],
  )

  return (
    <View
      style={[appStyles.fullSize, { backgroundColor: Colors['layout.light'] }]}
      onLayout={e => setWidth(e.nativeEvent.layout.width)}>
      <Loading blocking loading={isSaving} />
      <View style={useSafeAreaPaddedStyle(styles.headerHolder, headerOptions)}>
        <View
          style={[
            appStyles.inlineContainer,
            styles.header,
            webLayoutEnabled && styles.webHeader,
          ]}>
          <TouchableSvg
            name="back"
            color="layout.dark"
            style={styles.backButton}
            onPress={navigateBack}
          />
          <ProgressBar
            maxSteps={4}
            stepTitle={t('screens.registration.step')}
            textColor="layout.dark"
            currentStep={stepIndex + 1}
          />
        </View>
      </View>
      <KeyboardAvoidingView
        style={appStyles.fullSize}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        <ScrollView
          style={appStyles.fullSize}
          alwaysBounceVertical={false}
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={styles.contentContainer}>
          <FlatList
            ref={flatListRef}
            horizontal
            data={steps}
            extraData={width}
            scrollEnabled={false}
            renderItem={renderStep}
            keyboardShouldPersistTaps="handled"
            showsHorizontalScrollIndicator={false}
            getItemLayout={buildGetItemLayout(width!)}
          />
        </ScrollView>
      </KeyboardAvoidingView>
    </View>
  )
}

const styles = StyleSheet.create({
  headerHolder: {
    paddingTop: 10,
    paddingBottom: 20,
    backgroundColor: Colors['layout.white'],
  },
  header: {
    paddingLeft: 15,
    paddingRight: 43,
  },
  webHeader: {
    paddingLeft: 50,
    paddingRight: 100,
    paddingVertical: 30,
  },
  backButton: { marginRight: 10 },
  contentContainer: { flexGrow: 1 },
  footerText: {
    opacity: 0.5,
    marginTop: 15,
    marginHorizontal: 'auto',
    textDecorationLine: 'underline',
  },
})
