import React, { useCallback } from 'react'
import {
  View,
  FlatList,
  StyleSheet,
  ListRenderItem,
  FlatListProps,
} from 'react-native'

import { PressableItem } from '../../../components/PressableItem'
import Separator from '../../../components/Separator'
import TitleText from '../../../components/Text/TitleText'
import {
  ProfileFormItemType,
  ProfileFormItemKeyType,
} from '../../../helpers/ProfileFormItems'
import { useWebLayout } from '../../../providers/WebLayoutProvider'
import appStyles from '../../../styles/app-styles'
import FontWeights from '../../../styles/FontWeights'

type OptionsHolderPropsType = {
  title: string
  width: number
  options: ProfileFormItemType[]
  selectedKey: ProfileFormItemKeyType | null
  onOptionPicked: (key: ProfileFormItemKeyType) => void
} & Partial<Pick<FlatListProps<ProfileFormItemType>, 'ListFooterComponent'>>

const Divider = () => <Separator height={10} />

export const ProfileOptionsPicker: React.FC<OptionsHolderPropsType> = ({
  title,
  width,
  options,
  selectedKey,
  onOptionPicked,
  ListFooterComponent,
}) => {
  const renderItem: ListRenderItem<ProfileFormItemType> = useCallback(
    ({ item }) => (
      <PressableItem
        item={item}
        isSelected={item.key === selectedKey}
        onPress={onOptionPicked}
      />
    ),
    [selectedKey, onOptionPicked],
  )

  const { webLayoutEnabled } = useWebLayout()

  return (
    <View style={[styles.container, { width }]}>
      <TitleText
        color="layout.dark"
        size="medium"
        style={[styles.titleText, webLayoutEnabled && styles.webTitleText]}>
        {title}
      </TitleText>
      <FlatList
        data={options}
        scrollEnabled={false}
        renderItem={renderItem}
        style={appStyles.fullSize}
        keyExtractor={item => item.key ?? ''}
        ItemSeparatorComponent={Divider}
        ListFooterComponent={ListFooterComponent}
        contentContainerStyle={[
          styles.scrollContentContainer,
          webLayoutEnabled && styles.webScrollContentContainer,
        ]}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
  },
  scrollContentContainer: {
    flexGrow: 1,
    paddingTop: 20,
    paddingHorizontal: 25,
  },
  webScrollContentContainer: { paddingHorizontal: 100, paddingTop: 34 },
  titleText: {
    marginHorizontal: 25,
    fontWeight: FontWeights.bold,
  },
  webTitleText: { marginHorizontal: 100, marginTop: 14 },
})
