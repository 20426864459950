import React, { useMemo, useCallback, useState, useEffect, useRef } from 'react'
import {
  View,
  Keyboard,
  Animated,
  StyleSheet,
  TextInputProps,
  TouchableOpacity,
} from 'react-native'

import { useKeyboard } from '@react-native-community/hooks'
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import {
  Maybe,
  LifeStage,
  GetUserQuery,
  GetUserDocument,
  useUpdateLifeStageMutation,
} from '../api/types'
import Button from '../components/Button'
import Loading from '../components/Loading'
import PickerModal from '../components/PickerModal'
import Separator from '../components/Separator'
import SvgIcon from '../components/SvgIcon'
import TitleText from '../components/Text/TitleText'
import Typography, { TypographyInput } from '../components/Text/Typography'
import core from '../core/core'
import useIsUnsaved from '../hooks/useIsUnsaved'
import usePauseSoftInputAdjust from '../hooks/usePauseSoftInputAdjust'
import useSafeAreaPaddedStyle from '../hooks/useSafeAreaPaddedStyle'
import useUnsavedChanges from '../hooks/useUnsavedChanges'
import { useUser } from '../providers/UserProvider'
import { useWebLayout } from '../providers/WebLayoutProvider'
import { TIMELINE_COLORS } from '../screens/timeline/TimelineScreen'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import {
  MainStackParamsType,
  MainStackNavigationType,
} from '../types/navigation-types'
import { convertHexToRGBA } from '../utils/colors'

type Input = 'title' | 'start' | 'end'

type Error =
  | { code: 'blank' }
  | { code: 'order' }
  | { code: 'future' }
  | { code: 'endless' }
  | { code: 'overlap'; overlap: LifeStage }
type Errors = { [key in Input]?: Error }

function getOverlapPredicate(year: number, id: string) {
  return (stage: LifeStage) =>
    stage.id !== id &&
    year >= stage.startYear! &&
    year <= (stage.endYear ?? Infinity)
}

function getOnChangeNumber(
  setter: React.Dispatch<React.SetStateAction<Maybe<number>>>,
): TextInputProps['onChangeText'] {
  return text => {
    if (!text) return setter(null)

    const number = parseInt(text, 10)
    !isNaN(number) && setter(number)
  }
}

const CalendarIcon: React.FC = () => (
  <View style={styles.calendarIconHolder}>
    <SvgIcon name="calendar" color={Colors['layout.dark']} opacity={0.5} />
  </View>
)

export default function EditLifeStageModal(): JSX.Element {
  const { params } = useRoute<RouteProp<MainStackParamsType, 'EditLifeStage'>>()
  const { goBack } = useNavigation<MainStackNavigationType<'EditLifeStage'>>()
  const { t } = useTranslation()

  const [errors, setErrors] = useState<Errors>()

  const { sortedLifeStages } = useUser()

  const [updateLifeStage, { loading, client }] = useUpdateLifeStageMutation()

  const [title, setTitle] = useState<string>(params.stage.title)
  const [startYear, setStartYear] = useState<number | null>(
    params.stage.startYear ?? null,
  )
  const [endYear, setEndYear] = useState<number | null>(
    params.stage.endYear ?? null,
  )

  usePauseSoftInputAdjust()
  const translateY = useRef(new Animated.Value(0)).current
  const { keyboardShown, keyboardHeight } = useKeyboard()
  useEffect(() => {
    Animated.timing(translateY, {
      toValue: keyboardShown ? -keyboardHeight + 10 : 0,
      duration: 200,
      useNativeDriver: true,
    }).start()
  }, [keyboardShown, keyboardHeight, translateY])

  const shouldSkipUnsavedCheck = useRef(false)
  useUnsavedChanges(
    useIsUnsaved([title, startYear, endYear]),
    () => shouldSkipUnsavedCheck.current,
  )

  const onSavePress = () => {
    const errorMap: Errors = {}
    if (!title) {
      errorMap.title = { code: 'blank' }
    }
    if (!startYear) {
      errorMap.start = { code: 'blank' }
    } else if (startYear > new Date().getFullYear()) {
      errorMap.start = { code: 'future' }
    } else {
      const overlap = sortedLifeStages.find(
        getOverlapPredicate(startYear, params.stage.id),
      )
      if (overlap) {
        errorMap.start = { code: 'overlap', overlap }
      }
    }

    if (!endYear) {
      const endless = sortedLifeStages.find(
        stage => stage.id !== params.stage.id && !stage.endYear,
      )
      if (endless) {
        errorMap.end = { code: 'endless' }
      } else {
        const overlap = sortedLifeStages.find(
          stage =>
            stage.id !== params.stage.id &&
            startYear! < (stage.endYear ?? Infinity),
        )
        if (overlap) {
          errorMap.end = { code: 'overlap', overlap }
        }
      }
    } else if (endYear > new Date().getFullYear()) {
      errorMap.end = { code: 'future' }
    } else {
      const overlap = sortedLifeStages.find(
        getOverlapPredicate(endYear, params.stage.id),
      )
      if (overlap) {
        errorMap.end = { code: 'overlap', overlap }
      } else if (startYear! > endYear) {
        errorMap.end = { code: 'order' }
      }
    }

    if (!errorMap.start && !errorMap.end) {
      const overlap = sortedLifeStages.find(
        stage =>
          stage.id !== params.stage.id &&
          startYear! <= stage.startYear! &&
          endYear! >= (stage.endYear ?? Infinity),
      )
      if (overlap) {
        errorMap.start = { code: 'overlap', overlap }
        errorMap.end = { code: 'overlap', overlap }
      }
    }
    setErrors(errorMap)
    if (Object.keys(errorMap).length) {
      return
    }

    updateLifeStage({
      variables: {
        id: params.stage.id,
        input: { title, startYear: startYear!, endYear },
      },
    })
      .then(({ data }) =>
        client.cache.updateQuery(
          { query: GetUserDocument },
          (userdata: GetUserQuery | null) =>
            userdata && {
              ...userdata,
              me: {
                ...userdata.me,
                lifeStages: {
                  ...userdata.me.lifeStages,
                  nodes: (userdata.me.lifeStages?.nodes ?? []).map(stage =>
                    stage.id === data?.updateLifeStage.id
                      ? data.updateLifeStage
                      : stage,
                  ),
                },
              },
            },
        ),
      )
      .then(() => {
        shouldSkipUnsavedCheck.current = true
        Keyboard.dismiss() // Prevent usePauseSoftInputAdjust unmount making parent screen flicker/adjust
        setTimeout(goBack, 0)
      })
      .catch(core.logging.error)
  }

  const getErrorMessage = useCallback(
    (error: Error | undefined) => {
      switch (error?.code) {
        case 'order':
        case 'endless':
        case 'future':
          return t(`modals.editLifeStage.error.${error.code}`)
        case 'overlap':
          return t(`modals.editLifeStage.error.overlap`, {
            name: error.overlap.title,
            years: `${error.overlap.startYear}\u00A0-\u00A0${
              error.overlap.endYear ?? '...'
            }`,
          })
      }
    },
    [t],
  )

  const stageColor = useMemo(
    () =>
      Colors[
        TIMELINE_COLORS[
          sortedLifeStages.findIndex(({ id }) => id === params.stage.id)
        ]
      ],
    [sortedLifeStages, params.stage.id],
  )

  const { webLayoutEnabled } = useWebLayout()

  return (
    <PickerModal close={goBack} extraTranslateY={translateY}>
      <Loading blocking loading={loading} />
      <View
        style={useSafeAreaPaddedStyle(styles.container, {
          insetBottom: !keyboardShown,
        })}>
        <View style={appStyles.inlineContainer}>
          <View style={appStyles.fullSize}>
            <TitleText color="layout.dark" size="medium">
              {t('modals.editLifeStage.title')}
            </TitleText>
          </View>
          <TouchableOpacity
            // @ts-ignore
            hitSlop={10}
            onPress={goBack}>
            <SvgIcon name="close" />
          </TouchableOpacity>
        </View>
        <Separator height={30} />
        <View>
          <TypographyInput
            value={title}
            weight="medium"
            placeholder={t('modals.editLifeStage.placeholder.title')}
            style={[
              styles.textInput,
              styles.titleInput,
              errors?.title && styles.missingInput,
            ]}
            placeholderTextColor={
              errors?.title ? Colors['brand.red'] : placeholderTextColor
            }
            onChangeText={setTitle}
            onFocus={() => setErrors(c => ({ ...c, title: undefined }))}
          />

          <View
            style={[
              styles.periodColorIndicator,
              { backgroundColor: stageColor },
            ]}
          />
        </View>
        <Separator height={10} />
        <View style={appStyles.row}>
          <View style={styles.yearInputHolder}>
            <TypographyInput
              weight="medium"
              keyboardType="numeric"
              value={String(startYear || '')}
              placeholder={t('modals.editLifeStage.placeholder.start')}
              style={[styles.textInput, errors?.start && styles.missingInput]}
              placeholderTextColor={
                errors?.start?.code === 'blank'
                  ? Colors['brand.red']
                  : placeholderTextColor
              }
              onChangeText={getOnChangeNumber(setStartYear)}
            />
            <CalendarIcon />
            <Typography weight="light" style={styles.yearInputError}>
              {getErrorMessage(errors?.start)}
            </Typography>
          </View>
          <Separator width={11} />
          <View style={styles.yearInputHolder}>
            <TypographyInput
              weight="medium"
              keyboardType="numeric"
              value={String(endYear || '')}
              placeholder={t('modals.editLifeStage.placeholder.end')}
              style={[styles.textInput, errors?.end && styles.missingInput]}
              placeholderTextColor={
                errors?.end?.code === 'endless'
                  ? Colors['brand.red']
                  : placeholderTextColor
              }
              onChangeText={getOnChangeNumber(setEndYear)}
            />
            <CalendarIcon />
            <Separator height={4} />
            <Typography weight="light" style={styles.yearInputError}>
              {getErrorMessage(errors?.end)}
            </Typography>
          </View>
        </View>
        <Separator height={4} />
        <Button text={t('common.save')} onPress={onSavePress} />
      </View>
      {!webLayoutEnabled && (
        <View style={styles.keyboardAnimationBackgroundFix} />
      )}
    </PickerModal>
  )
}

const INPUT_HEIGHT = 54
const placeholderTextColor = convertHexToRGBA(Colors['layout.dark'], 0.5)

const styles = StyleSheet.create({
  container: {
    paddingTop: 18,
    paddingBottom: 24,
    paddingHorizontal: 24,
  },
  yearInputHolder: {
    flex: 1,
  },
  periodColorIndicator: {
    left: 20,
    width: 10,
    height: 10,
    borderRadius: 5,
    position: 'absolute',
    top: (INPUT_HEIGHT - 10) / 2,
  },
  textInput: {
    fontSize: 16,
    borderRadius: 14,
    paddingVertical: 7,
    height: INPUT_HEIGHT,
    paddingHorizontal: 20,
    backgroundColor: Colors['layout.white'],
  },
  missingInput: {
    borderWidth: 1,
    borderColor: Colors['brand.red'],
  },
  titleInput: { paddingLeft: 40 },
  yearInputError: {
    minHeight: 32,
    color: Colors['brand.red'],
  },
  calendarIconHolder: {
    top: 0,
    right: 25,
    height: INPUT_HEIGHT,
    position: 'absolute',
    ...appStyles.verticalCenter,
  },
  keyboardAnimationBackgroundFix: {
    ...StyleSheet.absoluteFillObject,
    top: '100%',
    bottom: -400,
    marginTop: -1,
    backgroundColor: Colors['layout.light'],
  },
})
