import React, { useCallback, useMemo } from 'react'

import { useNavigation, useRoute, RouteProp } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import {
  useGetLimitedUserQuery,
  useUpdateLimitedUserMutation,
} from '../../api/types'
import Loading from '../../components/Loading'
import { ProfileForm, ProfileFormProps } from '../../components/ProfileForm'
import { useDirectUpload } from '../../hooks/useDirectUpload'
import {
  MainStackNavigationType,
  MainStackParamsType,
} from '../../types/navigation-types'

export default function EditLimitedUserScreen(): JSX.Element {
  const { goBack } = useNavigation<MainStackNavigationType<'EditLimitedUser'>>()
  const { params } =
    useRoute<RouteProp<MainStackParamsType, 'EditLimitedUser'>>()

  const { t } = useTranslation()

  const { data, loading } = useGetLimitedUserQuery({
    variables: { id: params.userId },
  })
  const [updateUser, { client }] = useUpdateLimitedUserMutation()
  const { uploadMedia } = useDirectUpload()

  const initialValues: ProfileFormProps['initialValues'] = useMemo(
    () => ({
      email: data?.limitedUser?.email ?? '',
      title: data?.limitedUser?.title ?? null,
      gender: data?.limitedUser?.gender ?? null,
      country: data?.limitedUser?.country ?? null,
      lastName: data?.limitedUser?.lastName ?? null,
      firstName: data?.limitedUser?.firstName ?? null,
      pictureUrl: data?.limitedUser?.pictureUrl ?? null,
      birthday: data?.limitedUser?.dateOfBirth
        ? new Date(data.limitedUser.dateOfBirth)
        : null,
    }),
    [data],
  )

  const validate: ProfileFormProps['validate'] = useCallback(
    ({ email }) =>
      !email
        ? {
            isValid: false,
            errors: { email: { message: t('profile.error.noEmail') } },
          }
        : { isValid: true },
    [t],
  )

  const updatePicture = useCallback(
    async (picture: Parameters<ProfileFormProps['submit']>[1]) => {
      if (picture) {
        const { uri, fileName, type } = picture
        return uploadMedia(uri, null, fileName, type)
      }
    },
    [uploadMedia],
  )

  /**
   * Evicts the LimitedUser from cache, so a refetch is automaticaly performed.
   * In case logged in as LimitedUser, `me` query should also be refetched
   */
  const adjustCache = useCallback(() => {
    client.cache.evict({
      id: client.cache.identify({
        id: params.userId,
        __typename: 'LimitedUser',
      }),
    })

    if (params.asProfile) {
      client.cache.evict({
        fieldName: 'me',
      })
    }

    client.cache.gc()
  }, [params, client.cache])

  const submit: ProfileFormProps['submit'] = useCallback(
    ({ birthday, pictureUrl: _, ...otherProps }, picture) => {
      return updatePicture(picture)
        .then(uploadData =>
          updateUser({
            variables: {
              id: params.userId,
              limitedUserInput: {
                ...otherProps,
                dateOfBirth: birthday,
                pictureBlobId: uploadData?.fileBlobId,
              },
            },
          }),
        )
        .then(result => {
          if (!result?.data?.updateLimitedUser)
            throw new Error('Error while saving data')

          adjustCache()
          goBack()
        })
    },
    [goBack, updateUser, adjustCache, updatePicture, params.userId],
  )
  return (
    <>
      <Loading loading={loading} />
      <ProfileForm
        initialValues={initialValues}
        submit={submit}
        validate={validate}
        onCancelPress={goBack}
      />
    </>
  )
}
