import React from 'react'
import { Pressable, StyleSheet, View } from 'react-native'

import Separator from '../../../components/Separator'
import SvgIcon from '../../../components/SvgIcon'
import Typography from '../../../components/Text/Typography'
import { ProfileFormItemType } from '../../../helpers/ProfileFormItems'
import appStyles from '../../../styles/app-styles'
import Colors from '../../../styles/Colors'
import { convertHexToRGBA } from '../../../utils/colors'

export type AccountTypesDetailedListItemProps = {
  accountType: ProfileFormItemType & { subtitle: string; description: string }
  onPress: () => void
}

export const AccountTypesDetailedListItem: React.FC<
  AccountTypesDetailedListItemProps
> = ({ accountType, onPress }) => (
  <Pressable
    style={({ pressed }) => [styles.item, pressed && styles.itemPressed]}
    onPress={onPress}>
    <View style={appStyles.inlineContainer}>
      {accountType.icon && (
        <SvgIcon name={accountType.icon} width={40} height={40} />
      )}
      <Typography weight="medium" style={styles.itemLabelText}>
        {accountType.label}
      </Typography>
    </View>
    <Separator height={10} />
    <View style={styles.detailsDivider} />

    <Separator height={5} />
    <Typography weight="bold">{accountType.subtitle}</Typography>
    <Separator height={5} />
    <Typography weight="light">{accountType.description}</Typography>
  </Pressable>
)

const styles = StyleSheet.create({
  item: {
    padding: 20,
    elevation: 4,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 1,
    borderRadius: 10,
    shadowOpacity: 0.05,
    shadowColor: Colors['layout.shadow'],
    backgroundColor: Colors['layout.white'],
  },
  itemPressed: { backgroundColor: Colors['layout.selected'] },
  itemLabelText: {
    fontSize: 16,
    marginLeft: 20,
  },
  detailsDivider: {
    flex: 1,
    height: 1,
    backgroundColor: convertHexToRGBA(Colors['layout.gray-dark'], 0.1),
  },
})
