import React, { useEffect, useRef, useState } from 'react'

import {
  getStateFromPath,
  getPathFromState,
  NavigationContainer,
  createNavigationContainerRef,
} from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import MainStack from './MainStack'
import UnauthorizedStack from './UnauthorizedStack'
import { GroupAccountTypeEnum } from '../api/types'
import CONFIG from '../config/app-config'
import { useUser } from '../providers/UserProvider'
import WebLayoutProvider from '../providers/WebLayoutProvider'

const Navigation: React.FC = () => {
  const { isLoggedIn, user, logOut, selectedAccountType } = useUser()
  const navigationRef = createNavigationContainerRef()
  const transferRef = useRef<{ name: string; params: object }>()
  const { t } = useTranslation()

  useEffect(() => {
    if (selectedAccountType === 'User' && user && !user.quizCompleted) {
      if (user.groupAdmin) {
        // @ts-ignore
        navigationRef.current?.navigate('EventsQuizSuccess', {
          groupAccountType:
            user.managedGroupAccount?.accountType ??
            GroupAccountTypeEnum.Enterprise,
        })
      } else {
        // @ts-ignore
        navigationRef.current?.navigate('EventsQuizStart')
      }
    }
  }, [navigationRef, user, selectedAccountType])

  useEffect(() => {
    if (user && transferRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const route = transferRef.current
      // TODO: add back when needed for user-specific content sharing
      // @ts-ignore
      // navigationRef.current?.navigate(route.name, route.params)
      transferRef.current = undefined
    }
  }, [navigationRef, user])

  const [showFulWidth, setShowFulWidth] = useState(false)

  return (
    <NavigationContainer
      ref={navigationRef}
      documentTitle={{
        formatter: (options, route) =>
          `${CONFIG.IS_DEMO() ? '[DEMO] ' : ''}${
            options?.title ? t(options?.title) : route?.name
          } - Relefant`,
      }}
      linking={{
        prefixes: [CONFIG.WEB_APP_URL()],
        config: {
          screens: {
            Welcome: '/',
            AuthRoot: 'auth',
            Registration: 'registration',
            ForgotPassword: 'password/new',
            SignIn: 'signin',
            ResetPassword: 'password/edit',
            EmailConfirmation: 'confirmation',
            Timeline: 'timeline',
            OtherMemories: 'other',
            Settings: 'settings',
            People: 'people',
            EventsQuizStart: 'quiz-start',
            EventsQuiz: 'quiz',
            EventsQuizSuccess: 'quiz-success',
            Subscriptions: 'settings/subscriptions',
            MediaLibrary: 'media-library',
            MediaLibraryPicker: 'media-library/pick',
            ProfileSettingsView: 'settings/profile',
            DeleteAccount: 'settings/profile/delete',
            ProfileSettingsEdit: 'settings/profile/edit',
            EditGroupAccount: 'settings/group-edit',
            GlobalEventSettings: 'settings/global-events',
            Unsubscribe: 'settings/subscriptions/cancel',
            AccountMembers: 'settings/account-members',
            AddAccountMember: 'settings/account-members/add',
            AccountTypesDetailed: 'group-account-types',
            MemoryDetails: {
              path: ':isShared?/:isCommon/:memoryId',
              stringify: {
                isShared: isShared => {
                  return isShared ? 'shared' : ''
                },
                isCommon: isCommon => {
                  return isCommon ? 'common-memory' : 'user-memory'
                },
              },
            },
            PersonDetails: {
              path: ':isShared?/person/:personId',
              stringify: {
                isShared: isShared => {
                  return isShared ? 'shared' : ''
                },
              },
            },
          },
        },
        getStateFromPath: (path, options) => {
          if (path === 'group-account-types') {
            return {
              routes: [
                { name: 'AuthRoot' },
                { name: 'Registration' },
                { name: 'AccountTypesDetailed' },
              ],
              index: 2,
            }
          }
          const sharedMatch = path.match(
            /\/shared\/(user-memory|person)\/(.+)\?shareToken=(.+)/,
          )
          if (sharedMatch) {
            setShowFulWidth(true)
            const routes = [
              { name: isLoggedIn ? 'Root' : 'AuthRoot', params: {} },
            ]
            const sharedId = sharedMatch[2]
            const shareToken = sharedMatch[3]

            switch (sharedMatch[1]) {
              case 'user-memory':
                routes.push({
                  name: 'MemoryDetails',
                  params: {
                    isCommon: false,
                    memoryId: sharedId,
                    isShared: true,
                    shareToken,
                  },
                })
                break
              case 'person':
                routes.push({
                  name: 'PersonDetails',
                  params: { personId: sharedId, shareToken, isShared: true },
                })
                break
            }
            if (!user) transferRef.current = routes.at(-1)
            return {
              routes,
            }
          }

          const memoryMatch = path.match(/\/(user|common)-memory\/(.+)/)
          if (memoryMatch) {
            const isCommon = memoryMatch[1] === 'common'
            const memoryId = memoryMatch[2]
            return {
              routes: [
                { name: 'Root' },
                { name: 'MemoryDetails', params: { isCommon, memoryId } },
              ],
            }
          }

          if (path === '/quiz') {
            return { routes: [{ name: 'Root' }, { name: 'EventsQuizStart' }] }
          }

          if (path.match(/\/password\/edit/) || path.match(/\/confirmation/)) {
            logOut()
            const original = getStateFromPath(path, options)
            return (
              original && {
                ...original,
                routes: [{ name: 'AuthRoot' }, ...original.routes],
              }
            )
          }

          const original = getStateFromPath(path, options)
          const extraRoutes = [{ name: isLoggedIn ? 'Root' : 'AuthRoot' }]

          if (path.match(/\/settings\/\w+/)) {
            extraRoutes.push({ name: 'Settings' })
          }

          if (path.match(/\/settings\/account-members\/\w+/)) {
            extraRoutes.push({ name: 'AccountMembers' })
          }

          if (path.match(/\/settings\/profile\/\w+/)) {
            extraRoutes.push({ name: 'ProfileSettingsView' })
          }

          if (path.match(/\/settings\/subscriptions\/\w+/)) {
            extraRoutes.push({ name: 'Subscriptions' })
          }

          return (
            original && {
              ...original,
              routes: [...extraRoutes, ...original.routes],
            }
          )
        },
        getPathFromState(...args) {
          const o = getPathFromState(...args)
          return o.replace('/Root', '')
        },
      }}>
      {isLoggedIn ? (
        <MainStack />
      ) : (
        <WebLayoutProvider preset={showFulWidth ? 'fullWidth' : 'auth'}>
          <UnauthorizedStack />
        </WebLayoutProvider>
      )}
    </NavigationContainer>
  )
}

export default Navigation
