import React, { useMemo } from 'react'
import { View, StyleSheet } from 'react-native'

import { useTranslation } from 'react-i18next'

import Button from '../../../components/Button'
import Separator from '../../../components/Separator'
import { TermsAndConditionsRow } from '../../../components/TermsAndConditionsRow'
import TitleText from '../../../components/Text/TitleText'
import Typography from '../../../components/Text/Typography'
import { useLocale } from '../../../providers/LocaleProvider'
import appStyles from '../../../styles/app-styles'
import Colors from '../../../styles/Colors'
import {
  SubscriptionsScreenProps,
  AddonType,
} from '../../../types/SubscriptionScreen.types'
import { convertHexToRGBA } from '../../../utils/colors'
import formatPriceEuros from '../helpers/formatPriceEuros'

export const SubscriptionsSummary: React.FC<
  SubscriptionsScreenProps & {
    withDottedLine?: boolean
    addonTypes: AddonType[]
  }
> = ({
  addonTypes,
  selectedPlan,
  termsAccepted,
  withDottedLine,
  selectedAddons,
  currentPlanCode,
  setTermsAccepted,
  currentAddonCodes,
  openPaymentPageUrl,
}) => {
  const { locale } = useLocale()
  const { t, i18n } = useTranslation(undefined, {
    keyPrefix: 'screens.settings.subscriptions',
  })

  const sum = useMemo(
    () =>
      addonTypes.reduce(
        (total, type) => total + (selectedAddons[type]?.price ?? 0),
        selectedPlan?.planCode === currentPlanCode
          ? 0
          : selectedPlan?.price ?? 0,
      ),
    [
      selectedPlan?.planCode,
      currentPlanCode,
      selectedPlan?.price,
      selectedAddons,
      addonTypes,
    ],
  )

  const renderSummaryLine = (addonType: AddonType) => {
    const currentAddonCode = currentAddonCodes[addonType]
    const selectedAddon = selectedAddons[addonType]

    const getAmmountText = () => {
      switch (addonType) {
        case 'duration':
          return selectedAddon?.expiresInYears
            ? t('extended.duration', {
                amount: selectedAddon?.expiresInYears,
              })
            : t('freeAddon.plus.duration')
        case 'members':
          return selectedAddon?.maxUserCount ?? 0
        case 'storage':
          return selectedAddon?.allocatedSpace ?? 1
      }
    }

    const text = t(`planSubscription.addon.${addonType}`, {
      planName: selectedPlan?.name,
      amount: getAmmountText(),
    })

    return (
      <View key={addonType}>
        {selectedAddon?.addonCode !== currentAddonCode && (
          <View style={withDottedLine ? appStyles.row : styles.priceRow}>
            <Typography style={styles.planText}>{text}</Typography>
            <Separator width={10} />
            {withDottedLine && (
              <>
                <View style={styles.dottedLine} />
                <Separator width={10} />
              </>
            )}
            <Typography style={styles.priceText}>
              {formatPriceEuros(selectedAddon?.price ?? 0, locale)}
            </Typography>
          </View>
        )}
        <Separator height={10} />
      </View>
    )
  }
  return (
    <>
      <Separator height={20} />
      <TitleText size="medium" color="layout.dark" style={styles.titleText}>
        {t('summary')}
      </TitleText>
      <Separator height={10} />
      {selectedPlan?.planCode !== currentPlanCode && (
        <View style={withDottedLine ? appStyles.row : styles.priceRow}>
          <Typography style={styles.planText}>
            {t(
              `planSubscription.${selectedPlan?.anual ? 'annual' : 'monthly'}`,
              { planName: selectedPlan?.name },
            )}
          </Typography>
          <Separator width={10} />
          {withDottedLine && (
            <>
              <View style={styles.dottedLine} />
              <Separator width={10} />
            </>
          )}
          <Typography style={styles.priceText}>
            {formatPriceEuros(selectedPlan?.price ?? 0, locale)}
          </Typography>
        </View>
      )}
      <Separator height={10} />
      {addonTypes.map(renderSummaryLine)}
      <View style={styles.totalPriceContainer}>
        <TitleText size="medium" color="layout.dark" style={styles.totalText}>
          {t('total')}
        </TitleText>
        <Separator width={10} />
        <TitleText size="medium" color="layout.dark" style={styles.titleText}>
          {formatPriceEuros(sum, locale)}
        </TitleText>
      </View>
      <Typography style={styles.vatText}>{t('vatIncluded')}</Typography>
      <Separator height={20} />
      <TermsAndConditionsRow
        isAccepted={termsAccepted}
        setIsAccepted={setTermsAccepted}
      />
      <Separator height={40} />
      <Button
        type={selectedPlan && termsAccepted ? 'primary' : 'disabled'}
        style={appStyles.removedOutline}
        text={i18n.t('common.proceed')}
        onPress={openPaymentPageUrl}
      />
    </>
  )
}

const styles = StyleSheet.create({
  titleText: { fontSize: 26 },
  planText: { fontSize: 14 },
  totalText: {
    fontSize: 18,
    lineHeight: 26,
  },
  dottedLine: {
    borderBottomWidth: 2,
    ...appStyles.fullSize,
    borderStyle: 'dotted',
    borderColor: convertHexToRGBA(Colors['layout.dark'], 0.5),
  },
  totalPriceContainer: {
    ...appStyles.row,
    alignItems: 'baseline',
    justifyContent: 'flex-end',
  },
  priceRow: {
    ...appStyles.row,
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  priceText: {
    fontSize: 14,
    flexShrink: 0,
  },
  vatText: {
    fontSize: 14,
    textAlign: 'right',
  },
})
