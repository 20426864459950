import React, { useMemo, useState } from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'

import { useTranslation } from 'react-i18next'
import { Asset } from 'react-native-image-picker'

import { UserGenderEnum, UserTitleEnum } from '../../../api/types'
import Avatar from '../../../components/Avatar'
import Button from '../../../components/Button'
import {
  DateInputField,
  TextInputField,
  PickerInputField,
  PasswordInputField,
} from '../../../components/InputFields'
import Separator from '../../../components/Separator'
import SvgIcon from '../../../components/SvgIcon'
import { TermsAndConditionsRow } from '../../../components/TermsAndConditionsRow'
import TitleText from '../../../components/Text/TitleText'
import Typography from '../../../components/Text/Typography'
import {
  getTitleItems,
  getGenderItems,
} from '../../../helpers/ProfileFormItems'
import { useProfilePictureActionSheet } from '../../../hooks/useProfilePictureActionSheet'
import { useWebLayout } from '../../../providers/WebLayoutProvider'
import Colors from '../../../styles/Colors'

interface ProfileRegistrationFormPropsType {
  width: number
  gender: UserGenderEnum | null
  setAdminGender: (gender: UserGenderEnum | null) => void
  isGroupAdmin: boolean
  onCreateAccount: (
    title: UserTitleEnum | null,
    name: string,
    surname: string,
    email: string,
    birthday: Date,
    password: string,
    picture?: Asset,
  ) => Promise<void>
}

type ApiError = {
  status: number
  error: {
    attribute: string
    type: 'taken' | 'invalid' | 'not_complex' | 'too_short' | string
  }[]
}

export const ProfileRegistrationForm: React.FC<
  ProfileRegistrationFormPropsType
> = ({ width, gender, isGroupAdmin, onCreateAccount, setAdminGender }) => {
  const { t } = useTranslation()
  const { webLayoutEnabled } = useWebLayout()
  const genderItems = useMemo(
    () => (isGroupAdmin ? getGenderItems(t) : []),
    [t, isGroupAdmin],
  )
  const [genderError, setGenderError] = useState<string>('')
  const titleItems = useMemo(() => getTitleItems(t), [t])
  const [title, setTitle] = useState<UserTitleEnum | null>(null)
  const [name, setName] = useState<string>('')
  const [nameError, setNameError] = useState<string>('')
  const [surname, setSurname] = useState<string>('')
  const [surnameError, setSurnameError] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<string>('')
  const [emailErrorHint, setEmailErrorHint] = useState<string>('')
  const [birthday, setBirthday] = useState<Date | null>(null)
  const [birthdayError, setBirthdayError] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordError, setPasswordError] = useState<string>('')
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [termsAcceptanceMissing, setTermsAcceptanceMissing] = useState(false)

  const [picture, showPictureOptions] = useProfilePictureActionSheet()

  const saveAccount = () => {
    let hasErrors = false
    if (!name) {
      setNameError(t('profile.error.noName'))
      hasErrors = true
    }
    if (!surname) {
      setSurnameError(t('profile.error.noSurname'))
      hasErrors = true
    }
    if (isGroupAdmin && !gender) {
      setGenderError(t('profile.error.noGender'))
      hasErrors = true
    }
    if (!email) {
      setEmailError(t('profile.error.noEmail'))
      hasErrors = true
    }
    if (!birthday) {
      setBirthdayError(t('profile.error.noBirthday'))
      hasErrors = true
    }
    if (!password) {
      setPasswordError(t('profile.error.noPassword'))
      hasErrors = true
    }
    if (!termsAccepted) {
      setTermsAcceptanceMissing(true)
      hasErrors = true
    }
    if (hasErrors || !birthday) {
      return
    }
    onCreateAccount(
      title,
      name,
      surname,
      email,
      birthday,
      password,
      picture,
    ).catch(error => {
      if (error.json) {
        error.json().then((apiError: ApiError) => {
          apiError.error.forEach(e => {
            switch (e.attribute) {
              case 'email':
                switch (e.type) {
                  case 'taken':
                    setEmailErrorHint(t('profile.error.emailTaken'))
                    return setEmailError(t('profile.error.emailTaken'))
                  case 'invalid':
                    setEmailErrorHint(t('profile.error.emailInvalid'))
                    return setEmailError(t('profile.error.emailInvalid'))
                  default:
                    return setEmailError(e.type)
                }
              case 'password':
                return setPasswordError(e.type)
            }
          })
        })
      } else {
        console.log(`Error creating account: ${error}`)
      }
    })
  }

  return (
    <View style={[styles.form, { width }, webLayoutEnabled && styles.webForm]}>
      {isGroupAdmin && (
        <>
          <TitleText size="medium" color="layout.dark">
            {t('profile.personTitle.groupAdmin.header')}
          </TitleText>
          <Separator height={5} />
          <Typography weight="light">
            {t('profile.personTitle.groupAdmin.disclaimer')}
          </Typography>
          <Separator height={15} />
        </>
      )}
      <View style={styles.pictureContainer}>
        <TouchableOpacity
          // @ts-ignore
          hitSlop={10}
          style={styles.pictureContainer}
          onPress={() =>
            showPictureOptions({
              hasPictureUrl: false,
              removePictureUrl: () => {},
              containerStyle: { width, marginLeft: 'auto' },
            })
          }>
          <Avatar uri={picture?.uri} gender={gender} />
          <SvgIcon
            name="add"
            color={Colors['layout.dark']}
            style={styles.addPictureButton}
          />
        </TouchableOpacity>
      </View>
      <PickerInputField
        options={titleItems}
        selectedKey={title}
        label={t('profile.personTitle.title')}
        modalTitle={t('modals.titlePicker.title')}
        value={titleItems.find(item => item.key === title)?.label ?? ''}
        onSelectKey={key => setTitle(key as UserTitleEnum)}
      />
      <TextInputField
        value={name}
        maxLength={30}
        autoCapitalize="words"
        errorMessage={nameError}
        label={t('profile.name')}
        clearError={() => setNameError('')}
        onChangeValue={setName}
      />
      <TextInputField
        maxLength={30}
        value={surname}
        autoCapitalize="words"
        errorMessage={surnameError}
        label={t('profile.surname')}
        clearError={() => setSurnameError('')}
        onChangeValue={setSurname}
      />
      {isGroupAdmin && (
        <PickerInputField
          options={genderItems}
          selectedKey={gender}
          errorMessage={genderError}
          label={t('profile.gender.title')}
          modalTitle={t('profile.gender.title')}
          value={genderItems.find(item => item.key === gender)?.label ?? ''}
          onSelectKey={key => {
            setGenderError('')
            setAdminGender(key as UserGenderEnum)
          }}
        />
      )}
      <TextInputField
        value={email}
        inputMode="email"
        autoComplete="email"
        autoCapitalize="none"
        hint={emailErrorHint}
        clearError={() => {
          setEmailError('')
          setEmailErrorHint('')
        }}
        errorMessage={emailError}
        label={t('profile.email')}
        textContentType="emailAddress"
        onChangeValue={setEmail}
      />
      <DateInputField
        value={birthday}
        errorMessage={birthdayError}
        label={t('profile.birthday')}
        clearError={() => setBirthdayError('')}
        onChangeValue={setBirthday}
      />
      <PasswordInputField
        value={password}
        errorMessage={passwordError}
        label={t('profile.password')}
        hint={t('profile.passwordHint')}
        clearError={() => setPasswordError('')}
        onChangeValue={setPassword}
      />
      <Separator height={12} />
      <TermsAndConditionsRow
        isAccepted={termsAccepted}
        isHighlighted={termsAcceptanceMissing}
        setIsAccepted={accepted => {
          setTermsAccepted(accepted)
          if (accepted) setTermsAcceptanceMissing(false)
        }}
      />
      <Button
        style={styles.createAccountButton}
        text={t('screens.registration.createAccount')}
        onPress={saveAccount}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  form: {
    paddingTop: 20,
    paddingHorizontal: 25,
  },
  webForm: {
    paddingTop: 34,
    paddingHorizontal: 100,
  },
  pictureContainer: {
    marginBottom: 10,
    marginRight: 'auto',
  },
  addPictureButton: {
    left: 55,
    zIndex: 1,
    bottom: -30,
    position: 'absolute',
  },
  createAccountButton: { marginVertical: 30 },
})
