import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  View,
  FlatList,
  Animated,
  Pressable,
  TextInput,
  Dimensions,
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
} from 'react-native'

import { useRoute, RouteProp, useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { SearchPeopleQuery, useSearchPeopleQuery } from '../../api/types'
import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import { CheckBox } from '../../components/CheckBox'
import HeaderButton from '../../components/HeaderButton'
import Loading from '../../components/Loading'
import ModalHeader from '../../components/ModalHeader'
import Separator from '../../components/Separator'
import SvgIcon from '../../components/SvgIcon'
import Typography, { TypographyInput } from '../../components/Text/Typography'
import WebModal from '../../components/WebModal'
import buildGetItemLayout from '../../helpers/buildgetLayout'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import { QueryKey, useShouldRefetch } from '../../providers/RefetchProvider'
import { useWebLayout } from '../../providers/WebLayoutProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import FontSizes from '../../styles/FontSizes'
import {
  MainStackParamsType,
  MainStackNavigationType,
} from '../../types/navigation-types'
import { convertHexToRGBA } from '../../utils/colors'

export type PeoplePickerNode = SearchPeopleQuery['people']['nodes'][0]
type ListItemType = PeoplePickerNode & {
  isSelected: boolean
}

const Divider = () => <View style={styles.divider} />

/* eslint-disable-next-line react/display-name */
const ListItem = memo(
  ({
    item,
    onPress,
  }: {
    item: ListItemType
    onPress: TouchableOpacityProps['onPress']
  }) => {
    const { gender, firstName, lastName, title, pictureUrl } = item
    const { t } = useTranslation()
    return (
      <TouchableOpacity style={styles.item} onPress={onPress}>
        <Avatar size={44} gender={gender} uri={pictureUrl} />
        <Separator width={18} />
        <Typography weight="medium" style={styles.nameText}>
          {!!title && `${t(`profile.personTitle.${title}`)} `}
          {`${firstName} ${lastName}`}
        </Typography>
        <Separator width={5} />
        <CheckBox isChecked={item.isSelected} />
      </TouchableOpacity>
    )
  },
  (a, b) => a.item.id === b.item.id && a.item.isSelected === b.item.isSelected,
)

const SelectedRowSeparator = () => <Separator width={10} />
/* eslint-disable-next-line react/display-name */
const SelectedRowItem = memo(
  ({
    item,
    onRemovePress,
  }: {
    item: PeoplePickerNode
    onRemovePress: () => void
  }) => {
    const opacity = useRef(new Animated.Value(0)).current
    useEffect(() => {
      Animated.timing(opacity, {
        toValue: 1,
        duration: 230,
        useNativeDriver: true,
      }).start()
    }, [opacity])

    return (
      <Animated.View
        style={{
          width: TAGGED_ROW_AVATAR_SIZE,
          opacity,
          transform: [{ scale: opacity }],
        }}>
        <Avatar
          gender={item.gender}
          uri={item.pictureUrl}
          size={TAGGED_ROW_AVATAR_SIZE}
        />
        <Typography
          numberOfLines={1}
          maxFontSizeMultiplier={1}
          style={styles.taggedNameText}>
          {item.firstName}
        </Typography>
        <TouchableOpacity
          // @ts-ignore
          hitSlop={30}
          style={styles.taggedRemoveButton}
          onPress={onRemovePress}>
          <SvgIcon
            name="close"
            height={16}
            width={16}
            color={Colors['layout.dark']}
          />
        </TouchableOpacity>
      </Animated.View>
    )
  },
  (a, b) => a.item.id === b.item.id,
)

const selectedPredicate = ({ isSelected }: ListItemType) => isSelected

export default function PeoplePickerScreen(): JSX.Element {
  const { params } = useRoute<RouteProp<MainStackParamsType, 'PeoplePicker'>>()
  const { goBack, navigate } =
    useNavigation<MainStackNavigationType<'PeoplePicker'>>()
  const { t } = useTranslation()

  const searchInputRef = useRef<TextInput>(null)
  const [searchInput, setSearchInput] = useState<string>('')
  const [selectablePeople, setSelectablePeople] = useState<ListItemType[]>([])
  const selectedPeople = useMemo(
    () => selectablePeople.filter(selectedPredicate),
    [selectablePeople],
  )

  const isTaggedRowShown = selectedPeople.length > 0

  const taggedFlatListRef = useRef<FlatList>(null)
  const allFlatListRef = useRef<FlatList>(null)
  const lastTaggedId = useRef<string>()

  useEffect(() => {
    if (!lastTaggedId.current) return

    const index = selectedPeople.findIndex(
      ({ id }) => id === lastTaggedId.current,
    )
    if (index === -1) return

    taggedFlatListRef.current?.scrollToIndex({
      index,
      viewPosition: 0.3,
    })
  }, [selectedPeople])

  const { data, refetch, loading } = useSearchPeopleQuery({
    variables: { search: searchInput },
    fetchPolicy: searchInput ? 'network-only' : undefined,
  })
  useShouldRefetch(QueryKey.SearchPeople, refetch)

  useEffect(() => {
    if (!data) return

    setSelectablePeople(
      data.people.nodes.map(person => ({
        ...person,
        isSelected: !!params?.taggedPeople?.find(({ id }) => id === person.id),
      })),
    )
  }, [data, params?.taggedPeople])

  const onSavePress = () => {
    navigate({
      merge: true,
      name: 'MemoryForm',
      params: {
        taggedPeople: selectedPeople,
      },
    })
  }

  const [collapasingHeight, setCollapsingHeight] = useState(160)
  const translateY = useRef(new Animated.Value(-collapasingHeight)).current
  useEffect(() => {
    Animated.timing(translateY, {
      useNativeDriver: true,
      duration: TAGGED_ROW_ENTERANCE_DURATION,
      toValue: isTaggedRowShown ? 0 : -collapasingHeight,
    }).start()
  }, [isTaggedRowShown, collapasingHeight, translateY])

  const appHeaderStyle = useSafeAreaPaddedStyle(styles.header, headerOptions)

  const { webLayoutEnabled } = useWebLayout()
  // TODO: onSavePress in web
  return (
    <WebModal fullHeight>
      <Loading loading={loading} />
      {webLayoutEnabled ? (
        <ModalHeader
          close={goBack}
          title={t('screens.createMemory.tagPerson')}
          leftItem={
            <Button
              text={t('common.save')}
              style={styles.webSaveButton}
              onPress={onSavePress}
            />
          }
        />
      ) : (
        <View style={appHeaderStyle}>
          <HeaderButton secondary text={t('common.cancel')} onPress={goBack} />
          <HeaderButton text={t('common.save')} onPress={onSavePress} />
        </View>
      )}
      <Pressable
        style={styles.searchContainer}
        onPress={() => searchInputRef.current?.focus()}>
        <Separator width={18} />
        <SvgIcon name="search" />
        <TypographyInput
          ref={searchInputRef}
          weight="medium"
          value={searchInput}
          style={styles.searchInput}
          placeholder={t('screens.peoplePicker.searchPlaceholder')}
          placeholderTextColor={convertHexToRGBA(Colors['layout.dark'], 0.5)}
          onChangeText={setSearchInput}
        />
        {!!searchInput.length && (
          <TouchableOpacity onPress={() => setSearchInput('')}>
            <SvgIcon name="close" color={Colors['layout.gray']} />
          </TouchableOpacity>
        )}
        <Separator width={8} />
      </Pressable>
      <View onLayout={e => setCollapsingHeight(e.nativeEvent.layout.height)}>
        <Typography weight="medium" style={styles.sectionHeader}>
          {t('screens.peoplePicker.tagged')}
        </Typography>
        <Separator height={10} />
        <View style={styles.taggedRowContainer}>
          <FlatList
            ref={taggedFlatListRef}
            horizontal
            data={selectedPeople}
            ItemSeparatorComponent={SelectedRowSeparator}
            contentContainerStyle={styles.taggedRowContentContainer}
            getItemLayout={buildGetItemLayout(TAGGED_ROW_AVATAR_SIZE, 10)}
            renderItem={({ item }) => (
              <SelectedRowItem
                item={item}
                onRemovePress={() =>
                  setSelectablePeople(c =>
                    c.map(p =>
                      p.id === item.id ? { ...p, isSelected: false } : p,
                    ),
                  )
                }
              />
            )}
          />
        </View>
        <Separator height={10} />
        <Typography weight="medium" style={styles.sectionHeader}>
          {t('screens.peoplePicker.all')}
        </Typography>
        <Separator height={10} />
      </View>
      <Animated.View
        style={[
          styles.listContainer,
          !isTaggedRowShown && {
            marginBottom: -collapasingHeight,
          },
          { transform: [{ translateY }] },
        ]}>
        <FlatList
          ref={allFlatListRef}
          data={selectablePeople}
          style={appStyles.fullSize}
          extraData={collapasingHeight}
          ItemSeparatorComponent={Divider}
          getItemLayout={buildGetItemLayout(
            styles.item.height,
            styles.divider.height,
          )}
          contentContainerStyle={useSafeAreaPaddedStyle(
            useMemo(
              () =>
                webLayoutEnabled
                  ? { ...styles.scrollContentContainer, ...styles.webPadding }
                  : styles.scrollContentContainer,
              [webLayoutEnabled],
            ),
            {
              insetLeft: true,
            },
          )}
          keyExtractor={useCallback(({ id }: ListItemType) => id, [])}
          renderItem={({ item, index }) => (
            <ListItem
              item={item}
              onPress={({ nativeEvent }) => {
                setSelectablePeople(c =>
                  c.map(p => {
                    if (p.id !== item.id) return p

                    const isSelected = !p.isSelected

                    if (isSelected) {
                      lastTaggedId.current = item.id
                      if (
                        !c.find(selectedPredicate) &&
                        nativeEvent.pageY >
                          Dimensions.get('window').height - collapasingHeight
                      ) {
                        setTimeout(
                          () =>
                            allFlatListRef.current?.scrollToIndex({
                              viewPosition: 0.8,
                              index,
                            }),
                          TAGGED_ROW_ENTERANCE_DURATION,
                        )
                      }
                    }

                    return { ...p, isSelected }
                  }),
                )
              }}
            />
          )}
        />
      </Animated.View>
    </WebModal>
  )
}

const TAGGED_ROW_ENTERANCE_DURATION = 300
const TAGGED_ROW_AVATAR_SIZE = 70
const TAGGED_ROW_ITEM_H = TAGGED_ROW_AVATAR_SIZE + 20

const styles = StyleSheet.create({
  header: {
    width: '100%',
    paddingTop: 15,
    paddingBottom: 14,
    paddingHorizontal: 25,
    ...appStyles.lightShadow,
    ...appStyles.inlineContainer,
    justifyContent: 'space-between',
    backgroundColor: Colors['layout.white'],
  },
  webSaveButton: { width: 'auto' },
  searchContainer: {
    marginTop: 34,
    marginBottom: 32,
    borderRadius: 10,
    marginHorizontal: 25,
    ...appStyles.inlineContainer,
    backgroundColor: Colors['layout.white'],
  },
  searchInput: {
    height: 54,
    paddingLeft: 10,
    ...appStyles.fullSize,
    ...appStyles.removedOutline,
  },
  taggedRowContainer: {
    width: '100%',
    height: TAGGED_ROW_ITEM_H,
  },
  taggedRowContentContainer: {
    flexGrow: 1,
    paddingHorizontal: 35,
  },
  taggedNameText: {
    textAlign: 'center',
    width: TAGGED_ROW_AVATAR_SIZE,
  },
  taggedRemoveButton: {
    top: 0,
    right: 0,
    borderRadius: 20,
    position: 'absolute',
    backgroundColor: Colors['layout.gray'],
  },
  sectionHeader: {
    marginHorizontal: 35,
    fontSize: FontSizes['label.medium'],
  },
  listContainer: {
    flexGrow: 1,
    ...appStyles.fullSize,
    backgroundColor: Colors['layout.white'],
  },
  scrollContentContainer: {
    flexGrow: 1,
    paddingHorizontal: 25,
  },
  webPadding: { paddingHorizontal: 50 },
  item: {
    height: 60,
    ...appStyles.inlineContainer,
  },
  nameText: {
    flex: 1,
    fontSize: FontSizes['label.medium'],
  },
  divider: {
    height: 1,
    width: '100%',
    backgroundColor: Colors['layout.light'],
  },
})
