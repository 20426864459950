import { ZohoAddon } from '../../../api/types'
import { AddonType } from '../../../types/SubscriptionScreen.types'

export function getAddonPredicate(addonType: AddonType) {
  const flagKey = (
    {
      duration: 'extraTimeAddon',
      members: 'extraAccessAddon',
      storage: 'extraStorageAddon',
    } as const
  )[addonType]
  return (addon: ZohoAddon) => !!addon[flagKey]
}
