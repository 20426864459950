import React, { useCallback } from 'react'
import { Linking, StyleSheet, View } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import {
  useDeleteAccountMutation,
  useDeleteLimitedUserMutation,
} from '../../api/types'
import Button from '../../components/Button'
import RootContainer from '../../components/RootContainer'
import Separator from '../../components/Separator'
import SvgIcon from '../../components/SvgIcon'
import TitleText from '../../components/Text/TitleText'
import Typography from '../../components/Text/Typography'
import TouchableSvg from '../../components/TouchableSvg'
import loggingCore from '../../core/logging-core'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import { useUser } from '../../providers/UserProvider'
import { useWebLayout } from '../../providers/WebLayoutProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import { MainStackNavigationType } from '../../types/navigation-types'
import { convertHexToRGBA } from '../../utils/colors'

export default function DeleteAccountScreen(): JSX.Element {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'screens.settings.profileSettings.delete',
  })
  const { logOut, user } = useUser()
  const { goBack } = useNavigation<MainStackNavigationType<'DeleteAccount'>>()
  const { navigate } = useNavigation()
  const { webLayoutEnabled } = useWebLayout()
  const [deleteAccountMutation] = useDeleteAccountMutation()
  const [deleteLimitedUserMutation] = useDeleteLimitedUserMutation({
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    variables: { id: user?.id! },
  })

  const onDeletePress = useCallback(() => {
    const deleteAccount =
      user?.userType === 'LimitedUser'
        ? deleteLimitedUserMutation
        : deleteAccountMutation

    deleteAccount()
      .then(logOut)
      // @ts-ignore
      .then(() => navigate('AccountDeleted'))
      .catch(loggingCore.error)
  }, [user, logOut, navigate, deleteAccountMutation, deleteLimitedUserMutation])

  return (
    <RootContainer contentContainerStyle={styles.container}>
      <View style={useSafeAreaPaddedStyle(styles.header, headerOptions)}>
        <TouchableSvg name="back" color="layout.dark" onPress={goBack} />
        <Separator width={5} />
        <Typography weight="medium" style={styles.headerText}>
          {t('title')}
        </Typography>
      </View>
      <View style={styles.contentContainer}>
        <Separator height={35} />
        <TitleText size="medium" color="layout.dark">
          {t('warning.title')}
        </TitleText>
        <Separator height={40} />
        <View style={styles.infoContainer}>
          <View style={appStyles.inlineContainer}>
            <SvgIcon name="cross" />
            <Typography weight="medium" style={styles.warningText}>
              {t('warning.logOut')}
            </Typography>
          </View>
          <View style={appStyles.inlineContainer}>
            <SvgIcon name="cross" />
            <Typography weight="medium" style={styles.warningText}>
              {t(
                user?.userType === 'LimitedUser'
                  ? 'warning.deleteLimitedUser'
                  : 'warning.deleteData',
              )}
            </Typography>
          </View>

          <Separator height={40} />
          <Typography style={styles.recoveryText}>
            {t('recovery.text')}
            <Typography
              style={styles.emailText}
              onPress={() => Linking.openURL('mailto:service@relefant.eu')}>
              {t('recovery.email')}
            </Typography>
          </Typography>
        </View>
        <Button
          type="destructive"
          text={t('title')}
          style={webLayoutEnabled ? styles.buttonWeb : styles.button}
          onPress={onDeletePress}
        />
      </View>
    </RootContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    ...appStyles.fullSize,
    backgroundColor: Colors['layout.light'],
  },
  contentContainer: {
    ...appStyles.fullSize,
    paddingHorizontal: 24,
  },
  header: {
    paddingTop: 20,
    paddingBottom: 29,
    paddingHorizontal: 24,
    ...appStyles.lightShadow,
    ...appStyles.inlineContainer,
    backgroundColor: Colors['layout.white'],
  },
  headerText: {
    fontSize: 20,
    lineHeight: 24,
  },
  infoContainer: {
    marginHorizontal: 10,
  },
  warningText: {
    fontSize: 18,
    ...appStyles.fullSize,
  },
  recoveryText: {
    fontSize: 15,
    color: convertHexToRGBA(Colors['layout.dark'], 0.5),
  },
  emailText: {
    textDecorationLine: 'underline',
    color: convertHexToRGBA(Colors['layout.dark'], 0.5),
  },
  button: {
    marginBottom: 48,
    marginTop: 'auto',
  },
  buttonWeb: {
    marginTop: 40,
    alignSelf: 'flex-start',
  },
})
