import React, { useEffect, useMemo, useRef, useCallback } from 'react'
import { View, StyleSheet, ScrollView } from 'react-native'

import { useTranslation } from 'react-i18next'

import { freeAddon } from './components/PassiveMembershipCard'
import { PassiveMembershipRowMobile } from './components/PassiveMembershipRowMobile'
import { SubscriptionsSummary } from './components/SubscriptionsSummary'
import { getAddonPredicate } from './helpers/getAddonPredicate'
import { ZohoAddon } from '../../api/types'
import Button from '../../components/Button'
import Separator from '../../components/Separator'
import TitleText from '../../components/Text/TitleText'
import Typography from '../../components/Text/Typography'
import TouchableSvg from '../../components/TouchableSvg'
import UserStorageInfo from '../../components/UserStorageInfo'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import {
  Step,
  AddonType,
  SubscriptionsScreenProps,
} from '../../types/SubscriptionScreen.types'
import { convertHexToRGBA } from '../../utils/colors'

const NEXT_STEP_MAP: Record<AddonType, Step> = {
  duration: 'members',
  members: 'storage',
  storage: 'summary',
}
const PREV_STEP_MAP: Record<AddonType, Step> = {
  storage: 'members',
  members: 'duration',
  duration: 'plan',
}

export const SubscriptionsScreenMobileAddon: React.FC<
  SubscriptionsScreenProps & { addonType: AddonType }
> = props => {
  const {
    setStep,
    addonType,
    selectAddon,
    unsubscribe,
    selectedPlan,
    selectedAddons,
    currentPlanCode,
    setTermsAccepted,
    resetSelectedPlan,
    currentAddonCodes,
    forGroupAccountType,
  } = props
  const { t, i18n } = useTranslation(undefined, {
    keyPrefix: 'screens.settings.subscriptions',
  })
  const scrollViewRef = useRef<ScrollView>(null)
  const addons: ZohoAddon[] = useMemo(() => {
    if (!selectedPlan?.zohoAddons?.nodes) return []
    return [
      freeAddon,
      ...selectedPlan.zohoAddons.nodes.filter(getAddonPredicate(addonType)),
    ]
  }, [selectedPlan?.zohoAddons?.nodes, addonType])

  const selectedAddon = selectedAddons[addonType]
  const currentAddonCode = currentAddonCodes[addonType]
  const selectedAddonCode = useMemo(() => {
    const addonCode =
      addons.find(addon => addon.addonCode === selectedAddon?.addonCode)
        ?.addonCode ?? ''
    if (currentPlanCode === selectedPlan?.planCode && !addonCode)
      return currentAddonCodes ?? ''
    return addonCode
  }, [
    addons,
    currentPlanCode,
    currentAddonCodes,
    selectedPlan?.planCode,
    selectedAddon?.addonCode,
  ])

  useEffect(() => {
    scrollViewRef.current?.scrollTo({ y: 0, animated: true })
  }, [])

  const setSelectedAddon = useCallback(
    (addon?: ZohoAddon) => {
      selectAddon(addonType, addon)
    },
    [addonType, selectAddon],
  )

  const onBackPress = useCallback(() => {
    if (forGroupAccountType) {
      setStep(PREV_STEP_MAP[addonType])
    } else {
      resetSelectedPlan()
      setTermsAccepted(false)
    }
  }, [
    setStep,
    addonType,
    setTermsAccepted,
    resetSelectedPlan,
    forGroupAccountType,
  ])

  const translationKey =
    addonType === 'duration' ? 'passiveMembership' : addonType

  const isSelectionChanged =
    selectedPlan?.planCode &&
    (selectedPlan?.planCode !== (currentPlanCode ?? '') ||
      selectedAddon?.addonCode !== (currentAddonCode ?? ''))

  return (
    <ScrollView
      ref={scrollViewRef}
      style={styles.container}
      contentContainerStyle={styles.scrollContentContainer}>
      <View style={styles.header}>
        <TouchableSvg name="back" color="layout.dark" onPress={onBackPress} />
        <Separator width={5} />
        <Typography weight="medium" style={styles.headerText}>
          {t('title')}
        </Typography>
      </View>
      <Separator height={15} />
      <View style={styles.contentContainer}>
        <TitleText size="medium" color="layout.dark" style={styles.titleText}>
          {t(`${translationKey}.title`)}
        </TitleText>
        <Separator height={20} />
        <Typography weight="light" style={styles.descriptionText}>
          {t(`${translationKey}.description`)}
        </Typography>
        <Separator height={20} />
        <View style={styles.addonsContainer}>
          <Separator height={10} />
          <TitleText
            size="medium"
            color="layout.dark"
            style={{ color: selectedPlan?.colorCode ?? Colors['layout.dark'] }}>
            {selectedPlan?.name}
          </TitleText>
          <Typography style={styles.planText}>
            {t(`${translationKey}.shortTitle`)}
          </Typography>
          <Separator height={10} />
          <View style={styles.line} />
          <Separator height={20} />
          {!selectedPlan?.planCode && (
            <Typography weight="bold" style={styles.freePlanAddonText}>
              {addonType === 'members'
                ? t(`freePlan.included.members.${forGroupAccountType!}`)
                : t(`freePlan.included.${addonType}`)}
            </Typography>
          )}
          {addons.map(addon => (
            <View key={addon.id}>
              <PassiveMembershipRowMobile
                addon={addon}
                addonType={addonType}
                forGroupAccountType={forGroupAccountType}
                isSelected={selectedAddonCode === addon.addonCode}
                setSelected={setSelectedAddon}
              />
              <Separator height={10} />
            </View>
          ))}
          <Separator height={10} />
        </View>
        {forGroupAccountType && (
          <>
            <Separator height={20} />
            <Button
              text={i18n.t('common.proceed')}
              onPress={() => {
                setStep(NEXT_STEP_MAP[addonType])
              }}
            />
          </>
        )}
        {!forGroupAccountType && isSelectionChanged && (
          <>
            <Separator height={20} />
            <SubscriptionsSummary {...props} addonTypes={['duration']} />
          </>
        )}
        {!forGroupAccountType && currentPlanCode && (
          <>
            <Separator height={50} />
            <Typography>{t('cancel.followLink')}</Typography>
            <Separator height={18} />
            <Button
              type="secondary"
              text={t('cancel.title')}
              style={styles.unsubscribeButton}
              onPress={unsubscribe}
            />
          </>
        )}
      </View>
      <Separator height={50} />
      <UserStorageInfo />
      <Separator height={40} />
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    ...appStyles.fullSize,
    backgroundColor: Colors['layout.light'],
  },
  scrollContentContainer: {
    height: 0,
    flexGrow: 1,
  },
  header: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingBottom: 24,
    ...appStyles.lightShadow,
    ...appStyles.inlineContainer,
    backgroundColor: Colors['layout.white'],
  },
  headerText: {
    fontSize: 20,
    lineHeight: 24,
  },
  contentContainer: {
    paddingHorizontal: 25,
  },
  addonsContainer: {
    borderRadius: 10,
    paddingHorizontal: 14,
    backgroundColor: Colors['layout.white'],
  },
  line: {
    height: 1,
    backgroundColor: convertHexToRGBA(Colors['layout.black'], 0.2),
  },
  titleText: { fontSize: 26 },
  descriptionText: { fontSize: 16 },
  freePlanAddonText: {
    fontSize: 14,
    textAlign: 'center',
  },
  planText: { fontSize: 14 },
  unsubscribeButton: {
    padding: 5,
    maxWidth: 130,
  },
})
