import { TFunction } from 'i18next'

import {
  UserTitleEnum,
  UserGenderEnum,
  UserCountryEnum,
  UserLanguageEnum,
  PersonRelationEnum,
  GroupAccountTypeEnum,
} from '../api/types'
import { SvgName } from '../components/SvgIcon'

export type ProfileFormItemKeyType =
  | UserCountryEnum
  | UserTitleEnum
  | UserGenderEnum
  | UserLanguageEnum
  | PersonRelationEnum
  | GroupAccountTypeEnum
  | null

export type ProfileFormItemType = {
  key: ProfileFormItemKeyType
  label: string
  icon?: SvgName
}

export const getTitleItems: (t: TFunction) => ProfileFormItemType[] = t => [
  { key: null, label: t('profile.personTitle.none') },
  { key: UserTitleEnum.Dr, label: t('profile.personTitle.dr') },
  { key: UserTitleEnum.Prof, label: t('profile.personTitle.prof') },
]

export const getCountriesItems: (t: TFunction) => ProfileFormItemType[] = t => [
  {
    key: UserCountryEnum.At,
    label: t('profile.country.austria'),
    icon: 'austrian',
  },
  {
    key: UserCountryEnum.De,
    label: t('profile.country.germany'),
    icon: 'german',
  },
  {
    key: UserCountryEnum.Ch,
    label: t('profile.country.switzerland'),
    icon: 'swiss',
  },
  { key: UserCountryEnum.Other, label: t('common.other'), icon: 'flag-other' },
]

export const getAccountTypeItems: (
  t: TFunction,
) => ProfileFormItemType[] = t => [
  {
    key: null,
    label: t('screens.registration.accountType.personal'),
    icon: 'account-personal',
  },
  {
    key: GroupAccountTypeEnum.Family,
    label: t('screens.registration.accountType.family'),
    icon: 'account-family',
  },
  {
    key: GroupAccountTypeEnum.Club,
    label: t('screens.registration.accountType.club'),
    icon: 'account-club',
  },
  {
    key: GroupAccountTypeEnum.Enterprise,
    label: t('screens.registration.accountType.enterprise'),
    icon: 'account-enterprise',
  },
]

export const getGenderItems: (t: TFunction) => ProfileFormItemType[] = t => [
  { key: UserGenderEnum.Male, label: t('profile.gender.male'), icon: 'male' },
  {
    key: UserGenderEnum.Female,
    label: t('profile.gender.female'),
    icon: 'female',
  },
  { key: UserGenderEnum.Other, label: t('common.other'), icon: 'gender-other' },
]

export const getRelationItems: (t: TFunction) => ProfileFormItemType[] = t =>
  Object.values(PersonRelationEnum).map(key => ({
    key,
    label: t(`enums.personRelation.${key}`),
  }))

export const getLanguageItems: (t: TFunction) => ProfileFormItemType[] = t => [
  {
    key: UserLanguageEnum.En,
    icon: 'english',
    label: t('name', { lng: 'en' }),
  },
  { key: UserLanguageEnum.De, icon: 'german', label: t('name', { lng: 'de' }) },
]
