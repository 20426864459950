import React, { useMemo } from 'react'
import { StyleSheet, Pressable, View } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import {
  GroupAccountTypeEnum,
  useGetUnseenInviteNotificationsCountQuery,
} from '../api/types'
import Avatar from '../components/Avatar'
import Separator from '../components/Separator'
import SvgIcon from '../components/SvgIcon'
import Typography from '../components/Text/Typography'
import { useUser } from '../providers/UserProvider'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import { MainStackNavigationType } from '../types/navigation-types'

const AccountCard: React.FC<{ hideDetails?: boolean }> = ({ hideDetails }) => {
  const { t } = useTranslation()
  const { user, account, selectedAccountType } = useUser()
  const { data } = useGetUnseenInviteNotificationsCountQuery()
  const { navigate } = useNavigation<MainStackNavigationType<'Root'>>()

  const { avatar, greet, title } = useMemo(() => {
    if (selectedAccountType === 'User' && user) {
      return {
        avatar: { url: user.pictureUrl, gender: user.gender },
        greet: user.firstName,
        title: user.lastName,
      }
    }

    if (selectedAccountType === 'GroupAccount' && account) {
      return {
        avatar: {
          url: account?.pictureUrl,
          gender: account.accountType,
        },
        greet: t('homeHeader.greetGroupAccount'),
        title: `${account.name}${
          account.accountType === GroupAccountTypeEnum.Family
            ? ` ${t(`screens.registration.accountType.${account.accountType}`)}`
            : ''
        }`,
      }
    }

    return {}
  }, [selectedAccountType, user, account, t])

  const canChangeAccount = useMemo(
    () => user?.userType === 'UnlimitedUser',
    [user],
  )

  return (
    <>
      {avatar && <Avatar gender={avatar.gender} size={60} uri={avatar.url} />}
      <Separator width={12} />
      <View style={appStyles.fullSize}>
        {!hideDetails && (
          <Pressable
            hitSlop={15}
            disabled={!canChangeAccount}
            style={styles.nameHolderPressable}
            onPress={() => navigate('PickAccount')}>
            <Typography style={styles.greetText}>{greet}</Typography>
            <View style={appStyles.row}>
              <Typography weight="medium" style={styles.titleText}>
                {title}
              </Typography>
              {canChangeAccount && (
                <View style={styles.chevronHolder}>
                  <SvgIcon name="chevron" color={Colors['layout.dark']} />
                </View>
              )}
            </View>
            {!!data?.inviteNotifications?.totalCount && (
              <View style={styles.inviteNotificationHolder}>
                <View>
                  <SvgIcon
                    width={24}
                    height={24}
                    name="bell"
                    style={styles.bell}
                  />
                  <View style={styles.inviteNotificationIndicator}>
                    <Typography style={styles.inviteNotificationCount}>
                      {data?.inviteNotifications?.totalCount}
                    </Typography>
                  </View>
                </View>
              </View>
            )}
          </Pressable>
        )}
      </View>
    </>
  )
}

export default AccountCard

const styles = StyleSheet.create({
  nameHolderPressable: { marginRight: 'auto' },
  greetText: { fontSize: 16 },
  titleText: { fontSize: 22 },
  chevronHolder: {
    ...appStyles.center,
    paddingHorizontal: 8,
  },
  bell: {
    top: 0,
    right: 0,
    position: 'absolute',
  },
  inviteNotificationHolder: {
    top: 0,
    right: 0,
    position: 'absolute',
  },
  inviteNotificationIndicator: {
    ...appStyles.center,
    top: -5,
    right: -4,
    padding: 4,
    aspectRatio: 1,
    borderRadius: 50,
    position: 'absolute',
    backgroundColor: Colors['brand.red'],
  },
  inviteNotificationCount: {
    fontSize: 10,
    color: Colors['layout.white'],
  },
})
