import React, { useMemo } from 'react'

import { SvgProps } from 'react-native-svg'

import { UserGenderEnum, GroupAccountTypeEnum } from '../api/types'
import AccountClubSvg from '../assets/svg/account-type/club.svg'
import AccountEnterpriseSvg from '../assets/svg/account-type/enterprise.svg'
import AccountFamilySvg from '../assets/svg/account-type/family.svg'
import FemaleSvg from '../assets/svg/gender/placeholder/female.svg'
import MaleSvg from '../assets/svg/gender/placeholder/male.svg'
import OtherSvg from '../assets/svg/gender/placeholder/other.svg'

const AvatarPlaceholder: React.FC<
  SvgProps & {
    gender: UserGenderEnum | GroupAccountTypeEnum | null | undefined
  }
> = ({ gender, ...props }) => {
  const Component = useMemo(() => {
    switch (gender) {
      case UserGenderEnum.Male:
        return MaleSvg
      case UserGenderEnum.Female:
        return FemaleSvg
      case GroupAccountTypeEnum.Club:
        return AccountClubSvg
      case GroupAccountTypeEnum.Family:
        return AccountFamilySvg
      case GroupAccountTypeEnum.Enterprise:
        return AccountEnterpriseSvg
      default:
        return OtherSvg
    }
  }, [gender])

  return <Component {...props} viewBox="0 0 100 100" />
}

export default AvatarPlaceholder
