import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  View,
  Platform,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native'

import { ApolloError } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import Avatar from './Avatar'
import HeaderButton from './HeaderButton'
import { DateInputField, TextInputField, PickerInputField } from './InputFields'
import Loading from './Loading'
import Separator from './Separator'
import SvgIcon from './SvgIcon'
import WebModal from './WebModal'
import { UserTitleEnum, UserGenderEnum, UserCountryEnum } from '../api/types'
import loggingCore from '../core/logging-core'
import {
  getTitleItems,
  getGenderItems,
  getCountriesItems,
} from '../helpers/ProfileFormItems'
import toISO8601DateString from '../helpers/toISO8601DateString'
import { useProfilePictureActionSheet } from '../hooks/useProfilePictureActionSheet'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../hooks/useSafeAreaPaddedStyle'
import useStateRef from '../hooks/useStateRef'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'

type Profile = {
  gender: UserGenderEnum | null
  title: UserTitleEnum | null
  firstName: string | null
  lastName: string | null
  email: string
  birthday: Date | null
  country: UserCountryEnum | null
  pictureUrl: string | null
}

type Errors = {
  firstName?: string
  lastName?: string
  email?: { message: string; hint?: string }
}
export type ProfileFormProps = {
  initialValues?: Profile
  onCancelPress: () => void
  submit: (
    profile: Profile,
    picture: ReturnType<typeof useProfilePictureActionSheet>[0],
  ) => Promise<void>
  validate: (profile: Profile) => { isValid: boolean; errors?: Errors }
}
export const ProfileForm: React.FC<ProfileFormProps> = ({
  initialValues,
  validate,
  submit,
  onCancelPress,
}) => {
  const { t } = useTranslation()
  const [isSaving, setIsSaving, getIsSaving] = useStateRef(false)

  const titleItems = useMemo(() => getTitleItems(t), [t])
  const genderItems = useMemo(() => getGenderItems(t), [t])
  const countriesItems = useMemo(() => getCountriesItems(t), [t])

  const [gender, setGender] = useState<UserGenderEnum | null>(null)
  const [title, setTitle] = useState<UserTitleEnum | null>(null)
  const [firstName, setFirstName] = useState<string | null>(null)
  const [lastName, setLastName] = useState<string | null>(null)
  const [email, setEmail] = useState<string>('')
  const [birthday, setBirthday] = useState<Date | null>(null)
  const [country, setCountry] = useState<UserCountryEnum | null>(null)
  const [firstNameError, setFirstNameError] = useState<string>('')
  const [lastNameError, setLastNameError] = useState<string>('')
  const [emailError, setEmailError] = useState<string>('')
  const [emailErrorHint, setEmailErrorHint] = useState<string>('')
  const [pictureUrl, setPictureUrl] = useState<string | null>(null)

  const [picture, showPictureOptions] = useProfilePictureActionSheet()

  useEffect(() => {
    if (!initialValues) return

    setFirstName(initialValues.firstName)
    setTitle(initialValues.title)
    setEmail(initialValues.email)
    setGender(initialValues.gender)
    setLastName(initialValues.lastName)
    setCountry(initialValues.country)
    setBirthday(initialValues.birthday)
    setPictureUrl(initialValues.pictureUrl)
  }, [initialValues])

  const saveProfile = useCallback(() => {
    if (getIsSaving()) return

    const params = {
      title,
      email,
      gender,
      country,
      lastName,
      firstName,
      pictureUrl,
      birthday: toISO8601DateString(birthday),
    }
    const { isValid, errors } = validate(params)

    if (!isValid) {
      setFirstNameError(errors?.firstName ?? '')
      setLastNameError(errors?.lastName ?? '')
      setEmailError(errors?.email?.message ?? '')
      setEmailErrorHint(errors?.email?.hint ?? '')
      return
    }

    setIsSaving(true)

    submit(params, picture)
      .catch((error: ApolloError) => {
        const message =
          // @ts-ignore
          error?.graphQLErrors?.[0]?.extensions?.fullMessages?.[0] ??
          'Something went wrong'
        if (message) {
          switch (message) {
            case 'Email has already been taken':
              setEmailErrorHint(t('profile.error.emailTaken'))
              return setEmailError(t('profile.error.emailTaken'))
            case 'Email is invalid':
              setEmailErrorHint(t('profile.error.emailInvalid'))
              return setEmailError(t('profile.error.emailInvalid'))

            default:
              setEmailErrorHint(message)
              return setEmailError(message)
          }
        }
        loggingCore.error(`Error updating account: ${error}`)
      })
      .finally(() => setIsSaving(false))
  }, [
    t,
    title,
    email,
    gender,
    submit,
    country,
    picture,
    birthday,
    validate,
    lastName,
    firstName,
    pictureUrl,
    getIsSaving,
    setIsSaving,
  ])

  return (
    <WebModal>
      <Loading blocking loading={isSaving} />
      <View style={useSafeAreaPaddedStyle(styles.header, headerOptions)}>
        <HeaderButton
          secondary
          text={t('common.cancel')}
          onPress={onCancelPress}
        />
        <HeaderButton text={t('common.save')} onPress={saveProfile} />
      </View>
      <KeyboardAvoidingView
        style={appStyles.fullSize}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        <ScrollView
          keyboardShouldPersistTaps="handled"
          style={styles.contentContainer}>
          <TouchableOpacity
            // @ts-ignore
            hitSlop={10}
            style={styles.pictureContainer}
            onPress={() =>
              showPictureOptions({
                hasPictureUrl: !!pictureUrl,
                removePictureUrl: () => setPictureUrl(null),
              })
            }>
            <Avatar gender={gender} uri={picture?.uri ?? pictureUrl} />
            <SvgIcon
              name="add"
              color={Colors['layout.dark']}
              style={styles.addPictureButton}
            />
          </TouchableOpacity>
          <Separator height={30} />
          <PickerInputField
            selectedKey={gender}
            options={genderItems}
            label={t('profile.gender.title')}
            modalTitle={t('modals.genderPicker.title')}
            value={genderItems.find(item => item.key === gender)?.label ?? ''}
            onSelectKey={key => setGender(key as UserGenderEnum)}
          />
          <PickerInputField
            selectedKey={title}
            options={titleItems}
            label={t('profile.personTitle.title')}
            modalTitle={t('modals.titlePicker.title')}
            value={titleItems.find(item => item.key === title)?.label ?? ''}
            onSelectKey={key => setTitle(key as UserTitleEnum)}
          />
          <TextInputField
            value={firstName ?? undefined}
            maxLength={30}
            autoCapitalize="words"
            errorMessage={firstNameError}
            label={t('profile.name')}
            clearError={() => setFirstNameError('')}
            onChangeValue={setFirstName}
          />
          <TextInputField
            maxLength={30}
            value={lastName ?? undefined}
            autoCapitalize="words"
            errorMessage={lastNameError}
            label={t('profile.surname')}
            clearError={() => setLastNameError('')}
            onChangeValue={setLastName}
          />
          <TextInputField
            value={email}
            inputMode="email"
            autoComplete="email"
            autoCapitalize="none"
            hint={emailErrorHint}
            clearError={() => {
              setEmailError('')
              setEmailErrorHint('')
            }}
            errorMessage={emailError}
            label={t('profile.email')}
            textContentType="emailAddress"
            onChangeValue={setEmail}
          />
          <DateInputField
            value={birthday}
            label={t('profile.birthday')}
            onChangeValue={setBirthday}
          />
          <PickerInputField
            selectedKey={country}
            options={countriesItems}
            label={t('profile.country.title')}
            modalTitle={t('modals.countryPicker.title')}
            value={countriesItems.find(c => c.key === country)?.label ?? ''}
            onSelectKey={key => setCountry(key as UserCountryEnum)}
          />
          <Separator height={30} />
        </ScrollView>
      </KeyboardAvoidingView>
    </WebModal>
  )
}

const styles = StyleSheet.create({
  header: {
    paddingTop: 15,
    ...appStyles.row,
    paddingBottom: 23,
    paddingHorizontal: 25,
    ...appStyles.lightShadow,
    justifyContent: 'space-between',
    backgroundColor: Colors['layout.white'],
  },
  contentContainer: {
    paddingTop: 30,
    paddingHorizontal: 25,
    ...appStyles.fullSize,
  },
  pictureContainer: {
    marginRight: 'auto',
  },
  addPictureButton: {
    left: 55,
    zIndex: 1,
    bottom: -30,
    position: 'absolute',
  },
})
