import React, { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'

import { useTranslation } from 'react-i18next'

import Separator from './Separator'
import SvgIcon from './SvgIcon'
import Typography from './Text/Typography'
import { useGetUserStorageQuery, useGetAccountStorageQuery } from '../api/types'
import { useLocale } from '../providers/LocaleProvider'
import { useUser } from '../providers/UserProvider'
import Colors from '../styles/Colors'

export default function UserStogrageInfo() {
  const { locale } = useLocale()
  const { t } = useTranslation(undefined, { keyPrefix: 'screens.settings' })

  const { selectedAccountType, account } = useUser()

  const { data: userData } = useGetUserStorageQuery({
    fetchPolicy: 'network-only',
    skip: selectedAccountType !== 'User',
  })
  const { data: accountData } = useGetAccountStorageQuery({
    fetchPolicy: 'network-only',
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    variables: { accountId: account?.id! },
    skip: selectedAccountType !== 'GroupAccount' || !account?.id,
  })
  const data = useMemo(() => {
    if (selectedAccountType === 'User' && userData) {
      return userData.me
    }

    if (selectedAccountType === 'GroupAccount' && accountData) {
      return accountData.groupAccount
    }
  }, [userData, accountData, selectedAccountType])

  const usedStorage = useMemo(
    () => data?.usedStorageGb ?? 0,
    [data?.usedStorageGb],
  )
  const allocatedStorage = useMemo(
    () => data?.allocatedSpaceGb ?? 0,
    [data?.allocatedSpaceGb],
  )

  const storageTextColor: keyof typeof Colors = useMemo(() => {
    const storageRatio =
      (data?.usedStorageGb ?? 0) / (data?.allocatedSpaceGb ?? 1)
    if (storageRatio < 0.7) {
      return 'brand.action'
    }
    if (storageRatio < 0.9) {
      return 'timeline.orange'
    }
    return 'brand.red'
  }, [data?.usedStorageGb, data?.allocatedSpaceGb])

  return (
    <View style={styles.storageInfoContainer}>
      <SvgIcon name="cloud" />
      <Separator height={8} />
      <Typography style={[styles.storageText, styles.storageTitleText]}>
        {t('storage')}
      </Typography>
      <Typography
        style={[styles.storageText, { color: Colors[storageTextColor] }]}>
        {t('usedStorage', {
          usedStorage: usedStorage.toLocaleString(locale, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }),
          allocatedStorage: allocatedStorage.toLocaleString(locale, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
          }),
        })}
      </Typography>
    </View>
  )
}

const styles = StyleSheet.create({
  storageInfoContainer: {
    alignItems: 'center',
  },
  storageText: {
    fontSize: 14,
    lineHeight: 20,
  },
  storageTitleText: { opacity: 0.5 },
})
