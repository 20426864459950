import React, { useState, useEffect, useRef, useMemo } from 'react'
import {
  View,
  Keyboard,
  Animated,
  StyleSheet,
  TouchableOpacity,
} from 'react-native'

import { useKeyboard } from '@react-native-community/hooks'
import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { useAddUserToGroupMutation } from '../api/types'
import Button from '../components/Button'
import { PickerInputField, TextInputField } from '../components/InputFields'
import Loading from '../components/Loading'
import PickerModal from '../components/PickerModal'
import Separator from '../components/Separator'
import SvgIcon from '../components/SvgIcon'
import TitleText from '../components/Text/TitleText'
import usePauseSoftInputAdjust from '../hooks/usePauseSoftInputAdjust'
import useSafeAreaPaddedStyle from '../hooks/useSafeAreaPaddedStyle'
import { useWebLayout } from '../providers/WebLayoutProvider'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import { MainStackNavigationType } from '../types/navigation-types'

export default function AddAccountMemberModal(): JSX.Element {
  const { goBack } =
    useNavigation<MainStackNavigationType<'AddAccountMember'>>()
  const { t } = useTranslation()

  const [addUserToGroupMutation, { loading, client }] =
    useAddUserToGroupMutation()

  const [email, setEmail] = useState<string>('')
  const [emailErrorCode, setEmailErrorCode] = useState<
    'missing' | 'not_valid' | 'user_limit_reahed_for_account'
  >()
  const emailErrorMessage = useMemo(() => {
    switch (emailErrorCode) {
      case undefined:
        return '' // no error
      case 'not_valid':
        return t('profile.error.emailInvalid')
      case 'missing':
        return ' ' // error indicated, empty message
      case 'user_limit_reahed_for_account':
        return t('modals.addAccountMember.errors.limitReached')
      default:
        return emailErrorCode
    }
  }, [t, emailErrorCode])

  usePauseSoftInputAdjust()
  const translateY = useRef(new Animated.Value(0)).current
  const { keyboardShown, keyboardHeight } = useKeyboard()
  useEffect(() => {
    Animated.timing(translateY, {
      toValue: keyboardShown ? -keyboardHeight + 10 : 0,
      duration: 200,
      useNativeDriver: true,
    }).start()
  }, [keyboardShown, keyboardHeight, translateY])

  const onSavePress = () => {
    if (!email) {
      return setEmailErrorCode('missing')
    }

    addUserToGroupMutation({
      variables: {
        email,
      },
    })
      .then(({ data }) => {
        if (data?.addUserToGroupAccount) {
          client.cache.evict({
            fieldName: 'groupAccount',
          })
        }
      })
      .then(() => {
        Keyboard.dismiss() // Prevent usePauseSoftInputAdjust unmount making parent screen flicker/adjust
        setTimeout(goBack, 0)
      })
      .catch(e => {
        const message =
          e?.graphQLErrors?.[0]?.extensions?.message ??
          e?.graphQLErrors?.[0]?.message

        setEmailErrorCode(message)
      })
  }

  const { webLayoutEnabled } = useWebLayout()

  return (
    <PickerModal close={goBack} extraTranslateY={translateY}>
      <Loading blocking loading={loading} />
      <View
        style={useSafeAreaPaddedStyle(styles.container, {
          insetBottom: !keyboardShown,
        })}>
        <View style={appStyles.inlineContainer}>
          <View style={appStyles.fullSize}>
            <TitleText color="layout.dark" size="medium">
              {t('modals.addAccountMember.title')}
            </TitleText>
          </View>
          <TouchableOpacity
            // @ts-ignore
            hitSlop={10}
            onPress={goBack}>
            <SvgIcon name="close" />
          </TouchableOpacity>
        </View>
        <Separator height={30} />
        <TextInputField
          value={email}
          autoComplete="off"
          autoCapitalize="none"
          keyboardType="email-address"
          hint={emailErrorMessage.trim()}
          errorMessage={emailErrorMessage}
          label={t('modals.addAccountMember.emailLabel')}
          clearError={() => setEmailErrorCode(undefined)}
          onChangeValue={setEmail}
        />
        <PickerInputField
          disabled
          selectedKey={null}
          value={t('enums.groupAccountMemberRole.User')}
          label={t('modals.addAccountMember.roleLabel')}
          modalTitle={t('modals.addAccountMember.roleLabel')}
          options={[
            { key: null, label: t('enums.groupAccountMemberRole.User') },
          ]}
          onSelectKey={() => {}}
        />
        <Separator height={20} />
        <Button text={t('common.save')} onPress={onSavePress} />
      </View>
      {!webLayoutEnabled && (
        <View style={styles.keyboardAnimationBackgroundFix} />
      )}
    </PickerModal>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 18,
    paddingBottom: 24,
    paddingHorizontal: 24,
  },
  keyboardAnimationBackgroundFix: {
    ...StyleSheet.absoluteFillObject,
    top: '100%',
    bottom: -400,
    marginTop: -1,
    backgroundColor: Colors['layout.light'],
  },
})
