import React, { useState } from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'

import {
  useRoute,
  RouteProp,
  StackActions,
  useNavigation,
} from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { LifeStage, DatePrecisionEnum } from '../../api/types'
import ModalHeader from '../../components/ModalHeader'
import Separator from '../../components/Separator'
import SvgIcon from '../../components/SvgIcon'
import ButtonText from '../../components/Text/ButtonText'
import TitleText from '../../components/Text/TitleText'
import WebModal from '../../components/WebModal'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import { DatePickerModal } from '../../modals/DatePickerModal'
import { LifeStagePickerModal } from '../../modals/LifeStagePickerModal'
import { useUser } from '../../providers/UserProvider'
import { useWebLayout } from '../../providers/WebLayoutProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import {
  MainStackParamsType,
  MainStackNavigationType,
} from '../../types/navigation-types'

export default function MemoryTimePickerScreen(): JSX.Element {
  const { params } =
    useRoute<RouteProp<MainStackParamsType, 'MemoryTimePicker'>>()
  const navigation =
    useNavigation<MainStackNavigationType<'MemoryTimePicker'>>()
  const { t } = useTranslation()
  const { selectedAccountType } = useUser()

  const [isStagePickerVisible, setIsStagePickerVisible] =
    useState<boolean>(false)
  const [isDatePickerVisible, setIsDatePickerVisible] = useState<boolean>(false)

  const [date, setDate] = useState<Date>(
    new Date(params?.intialTimestamp ?? new Date()),
  )
  const [datePrecision, setDatePrecision] = useState<DatePrecisionEnum>(
    params?.initialPrecision ?? DatePrecisionEnum.Year,
  )
  const [lifeStage, setLifeStage] = useState<LifeStage>()

  const toForm = (eventAt: MainStackParamsType['MemoryForm']['eventAt']) => {
    const formKey = navigation
      .getState()
      .routes.find(({ name }) => name === 'MemoryForm')?.key

    if (formKey) {
      navigation.navigate({
        key: formKey,
        params: { eventAt },
        merge: true,
      })
    } else {
      navigation.dispatch(StackActions.replace('MemoryForm', { eventAt }))
    }
  }

  const appHeaderStyle = useSafeAreaPaddedStyle(styles.header, headerOptions)

  const { webLayoutEnabled } = useWebLayout()

  const titleText = t(
    params?.editing ? 'screens.editMemory.title' : 'screens.createMemory.title',
  )

  return (
    <WebModal>
      {webLayoutEnabled ? (
        <ModalHeader title={titleText} close={navigation.goBack} />
      ) : (
        <View style={appHeaderStyle}>
          <TouchableOpacity
            // @ts-ignore
            hitSlop={15}
            onPress={() => navigation.goBack()}>
            <SvgIcon name="back" color={Colors['layout.dark']} />
          </TouchableOpacity>
          <Separator width={23} />
          <TitleText size="medium" color="layout.dark" style={styles.title}>
            {titleText}
          </TitleText>
        </View>
      )}
      <View
        style={[
          styles.optionsHolder,
          webLayoutEnabled && styles.webOptionHolder,
        ]}>
        <TitleText color="layout.dark" size="medium">
          {t('screens.createMemory.subtitle')}
        </TitleText>
        <Separator height={21} />
        <TouchableOpacity
          style={styles.item}
          onPress={() => setIsDatePickerVisible(true)}>
          <ButtonText color="layout.dark" size="medium">
            {t('screens.createMemory.specificTime')}
          </ButtonText>
        </TouchableOpacity>
        {selectedAccountType === 'User' && (
          <>
            <Separator height={10} />
            <TouchableOpacity
              style={styles.item}
              onPress={() => setIsStagePickerVisible(true)}>
              <ButtonText color="layout.dark" size="medium">
                {t('screens.createMemory.approximateTime')}
              </ButtonText>
            </TouchableOpacity>
          </>
        )}
        <Separator height={10} />
        <TouchableOpacity style={styles.item} onPress={() => toForm(null)}>
          <ButtonText color="layout.dark" size="medium">
            {t('screens.createMemory.noSpecificTime')}
          </ButtonText>
        </TouchableOpacity>
      </View>
      <DatePickerModal
        allowPrecisionChange
        visible={isDatePickerVisible}
        close={select => {
          setIsDatePickerVisible(false)
          if (select) {
            toForm({
              timestamp: date.valueOf(),
              prec: datePrecision,
            })
          }
        }}
        selectedDate={date}
        precision={datePrecision}
        setPrecision={setDatePrecision}
        setSelectedDate={setDate}
      />
      <LifeStagePickerModal
        visible={isStagePickerVisible}
        close={() => setIsStagePickerVisible(false)}
        selected={lifeStage}
        setSelected={stage => {
          setLifeStage(stage)
          toForm(stage)
        }}
      />
    </WebModal>
  )
}

const styles = StyleSheet.create({
  header: {
    width: '100%',
    paddingTop: 15,
    paddingBottom: 14,
    paddingHorizontal: 25,
    ...appStyles.lightShadow,
    ...appStyles.inlineContainer,
    backgroundColor: Colors['layout.white'],
  },
  title: {
    flex: 1,
    fontSize: 20,
  },
  optionsHolder: {
    paddingVertical: 20,
    paddingHorizontal: 25,
  },
  webOptionHolder: {
    paddingTop: 31,
    paddingBottom: 50,
    paddingHorizontal: 50,
  },
  item: {
    height: 80,
    borderRadius: 10,
    paddingHorizontal: 20,
    ...appStyles.lightShadow,
    ...appStyles.inlineContainer,
    backgroundColor: Colors['layout.white'],
  },
})
