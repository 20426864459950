import React, { PropsWithChildren } from 'react'
import { View, StyleSheet } from 'react-native'

import { createStackNavigator } from '@react-navigation/stack'

import AccountDeletedModal from '../modals/AccountDeletedModal'
import LanguagePickerModal from '../modals/LanguagePickerModal'
import {
  useWebLayout,
  WEB_AUTH_NAVIGATOR_ASPECT_RATIO,
  WEB_AUTH_NAVIGATOR_MIN_WIDTH,
} from '../providers/WebLayoutProvider'
import AuthRootScreen from '../screens/auth-root/AuthRootScreen'
import MemoryDetailsScreen from '../screens/memory-details/MemoryDetailsScreen'
import PersonDetailsScreen from '../screens/person-details/PersonDetailsScreen'
import AccountTypesDetailedScreen from '../screens/registration/AccountTypesDetailedScreen'
import EmailConfirmationScreen from '../screens/registration/EmailConfirmationScreen'
import RegistrationScreen from '../screens/registration/RegistrationScreen'
import ForgotPasswordScreen from '../screens/sign-in/ForgotPasswordScreen'
import ResetPasswordScreen from '../screens/sign-in/ResetPasswordScreen'
import SignInScreen from '../screens/sign-in/SignInScreen'
import WelcomeScreen from '../screens/welcome/WelcomeScreen'
import appStyles from '../styles/app-styles'
import { UnauthorizedStackParamsType } from '../types/navigation-types'

const UnauthorizedStack = createStackNavigator<UnauthorizedStackParamsType>()

const Container: React.FC<PropsWithChildren> = ({ children }) => {
  const { webLayoutEnabled, showFullWidth } = useWebLayout()
  return (
    <View style={[appStyles.fullSize, appStyles.row]}>
      {webLayoutEnabled && !showFullWidth && (
        <View style={styles.welcomeHolder}>
          <WelcomeScreen />
        </View>
      )}

      <View
        style={
          webLayoutEnabled && !showFullWidth
            ? styles.navigatorHolder
            : appStyles.fullSize
        }>
        {children}
      </View>
    </View>
  )
}

export default function UnauthorizedStackNavigator(): JSX.Element {
  const { webLayoutEnabled } = useWebLayout()

  return (
    <Container>
      <UnauthorizedStack.Navigator
        initialRouteName={webLayoutEnabled ? 'AuthRoot' : 'Welcome'}
        screenOptions={{ headerShown: false }}>
        {!webLayoutEnabled && (
          <UnauthorizedStack.Screen name="Welcome" component={WelcomeScreen} />
        )}
        <UnauthorizedStack.Screen name="AuthRoot" component={AuthRootScreen} />
        <UnauthorizedStack.Screen
          name="Registration"
          component={RegistrationScreen}
        />
        <UnauthorizedStack.Screen
          name="EmailConfirmation"
          component={EmailConfirmationScreen}
        />
        <UnauthorizedStack.Screen name="SignIn" component={SignInScreen} />
        <UnauthorizedStack.Screen
          name="ForgotPassword"
          component={ForgotPasswordScreen}
        />
        <UnauthorizedStack.Screen
          name="ResetPassword"
          component={ResetPasswordScreen}
        />
        <UnauthorizedStack.Screen
          name="LanguagePicker"
          options={{ presentation: 'transparentModal' }}
          component={LanguagePickerModal}
        />
        <UnauthorizedStack.Screen
          name="AccountDeleted"
          component={AccountDeletedModal}
          options={{ presentation: 'transparentModal' }}
        />
        <UnauthorizedStack.Screen
          name="MemoryDetails"
          component={MemoryDetailsScreen}
          options={{ title: 'screens.memory.title' }}
        />
        <UnauthorizedStack.Screen
          name="PersonDetails"
          component={PersonDetailsScreen}
          options={{ title: 'screens.person.title' }}
        />
        <UnauthorizedStack.Screen
          name="AccountTypesDetailed"
          component={AccountTypesDetailedScreen}
          options={{ presentation: 'transparentModal' }}
        />
      </UnauthorizedStack.Navigator>
    </Container>
  )
}

const styles = StyleSheet.create({
  welcomeHolder: { flex: 1, overflow: 'hidden' },
  navigatorHolder: {
    height: '100%',
    aspectRatio: WEB_AUTH_NAVIGATOR_ASPECT_RATIO,
    minWidth: WEB_AUTH_NAVIGATOR_MIN_WIDTH,
  },
})
