import { TFunction } from 'i18next'

import { ZohoAddon, GroupAccountTypeEnum } from '../../../api/types'
import { AddonType } from '../../../types/SubscriptionScreen.types'

export default function getAddonRowText(
  t: TFunction,
  addon: ZohoAddon,
  addonType: AddonType,
  forGroupAccountType?: GroupAccountTypeEnum,
) {
  const amount =
    addon[
      addonType === 'members'
        ? 'maxUserCount'
        : addonType === 'storage'
        ? 'allocatedSpace'
        : 'expiresInYears'
    ]
  if (amount)
    return t(`screens.settings.subscriptions.extended.${addonType}`, { amount })

  // no amount - is the freeAddon
  // non-group plans don't have addons of "members" type so we can Bang! the group type
  if (addonType === 'members')
    return t(
      `screens.settings.subscriptions.freeAddon.plus.members.${forGroupAccountType!}`,
    )

  return t(`screens.settings.subscriptions.freeAddon.plus.${addonType}`)
}
