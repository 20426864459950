import { Scalars } from '../api/types'

function padZeros(number: number) {
  return String(number).padStart(2, '0')
}

/**
 * Fixes an issue with  dates <= 01-01-1926 and timezones.
 * We send a YYYY-MM-DD string to API
 */
export default function toISO8601DateString(
  date?: Date | null,
): Scalars['ISO8601Date'] | undefined | null {
  return (
    date &&
    `${date?.getFullYear()}-${padZeros(date.getMonth() + 1)}-${padZeros(
      date.getDate(),
    )}`
  )
}
