import { Dispatch, SetStateAction } from 'react'

import { useActionSheet } from '@expo/react-native-action-sheet'
import { useTranslation } from 'react-i18next'
import { Asset } from 'react-native-image-picker'
import { useFilePicker } from 'use-file-picker'

import { useDirectUpload } from './useDirectUpload'
import useLibraryId from './useLibraryId'
import { useListState } from './useListState'
import useShowRemoveMediaActionSheet from './useShowRemoveMediaActionSheet'
import { GetLibraryDocument, useCreateAttachmentMutation } from '../api/types'
import { MediaItem } from '../types/media-types'

export type AssetType = Asset & { description?: string }

export const useMediaActionSheet = (
  uploadDirectly: boolean,
  addFromRelefantLibrary?: () => void,
): [
  AssetType[],
  () => void,
  (
    asset: MediaItem,
    highlightImageAction?: { text: string; action: () => void },
    extraAction?: { text: string; action: () => void },
  ) => void,
  Dispatch<SetStateAction<AssetType[]>>,
  boolean,
  boolean,
] => {
  const libraryId = useLibraryId()
  const { showActionSheetWithOptions } = useActionSheet()
  const { t } = useTranslation()
  const _showRemoveMediaActionSheet = useShowRemoveMediaActionSheet()
  const { uploadMedia, isUploading } = useDirectUpload()
  const [createAttachment, { client }] = useCreateAttachmentMutation()

  const [mediaList, _, popMedia, setMediaList] = useListState<AssetType>(
    [],
    (a, b) => a.uri === b.uri,
  )

  const { openFilePicker, loading } = useFilePicker({
    readAs: 'DataURL',
    accept: ['image/*'],
    multiple: true,
    onFilesSuccessfullySelected(fileData) {
      const assets: AssetType[] = []
      // @ts-ignore
      fileData.plainFiles.forEach((file, index) => {
        assets.push({
          uri: fileData.filesContent[index].content,
          fileSize: file.size,
          type: file.type,
          fileName: file.name,
        })
      })
      if (uploadDirectly) {
        Promise.all(
          assets.map(asset =>
            uploadMedia(asset.uri, null, asset.fileName, asset.type).then(
              ({ fileBlobId }) =>
                createAttachment({
                  variables: {
                    input: {
                      fileBlobId,
                    },
                  },
                }),
            ),
          ),
        ).then(results =>
          client.cache.updateQuery(
            {
              query: GetLibraryDocument,
              variables: { id: libraryId },
            },
            data => {
              if (!data) return
              return {
                library: {
                  id: libraryId,
                  attachments: {
                    nodes: [
                      ...results.map(res => res.data?.createAttachment),
                      ...(data?.library.attachments?.nodes ?? []),
                    ],
                  },
                },
              }
            },
          ),
        )
      } else {
        setMediaList([...assets, ...mediaList])
      }
    },
  })

  const cancelButtonIndex = addFromRelefantLibrary ? 2 : 1

  const showAddMediaActionSheet = () => {
    showActionSheetWithOptions(
      {
        options: [
          t('screens.createMemory.addMediaOptions.chooseFromLibrary'),
          ...(addFromRelefantLibrary
            ? [t('screens.createMemory.addMediaOptions.chooseUsed')]
            : []),
          t('common.cancel'),
        ],
        cancelButtonIndex,
      },
      buttonIndex => {
        switch (buttonIndex) {
          case cancelButtonIndex:
            break
          case 0:
            openFilePicker()
            break
          case 1:
            addFromRelefantLibrary?.()
            break
          default:
            break
        }
      },
    )
  }

  const showMediaOptionsActionSheet = (
    asset: MediaItem,
    highlightImageAction?: { text: string; action: () => void },
    extraAction?: { text: string; action: () => void },
  ) => {
    const isImage = asset.contentType?.includes('image')

    _showRemoveMediaActionSheet(
      isImage ? 'image' : 'video',
      () => popMedia({ uri: asset.url ?? undefined }),
      // @ts-ignore
      [isImage && highlightImageAction, extraAction].filter(Boolean),
    )
  }

  return [
    mediaList,
    showAddMediaActionSheet,
    showMediaOptionsActionSheet,
    setMediaList,
    loading,
    isUploading,
  ]
}
