import React, { useCallback } from 'react'
import { StyleSheet, View } from 'react-native'

import { useRoute, RouteProp, useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { ActiveMembershipCard } from './components/ActiveMembershipCard'
import { ZohoPlan } from '../../api/types'
import Separator from '../../components/Separator'
import TitleText from '../../components/Text/TitleText'
import Typography from '../../components/Text/Typography'
import { ToggleButton } from '../../components/ToggleButton'
import TouchableSvg from '../../components/TouchableSvg'
import UserStorageInfo from '../../components/UserStorageInfo'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import {
  MainStackParamsType,
  MainStackNavigationType,
} from '../../types/navigation-types'
import { SubscriptionsScreenProps } from '../../types/SubscriptionScreen.types'

export const SubscriptionsScreenMobileActive: React.FC<
  SubscriptionsScreenProps
> = ({
  plans,
  setStep,
  showAnnual,
  selectedPlan,
  setShowAnnual,
  currentPlanCode,
  setSelectedPlan: _setSelectedPlan,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'screens.settings.subscriptions',
  })
  const { goBack } = useNavigation<MainStackNavigationType<'Subscriptions'>>()
  const { params } = useRoute<RouteProp<MainStackParamsType, 'Subscriptions'>>()

  const setSelectedPlan = useCallback(
    (plan?: ZohoPlan) => {
      _setSelectedPlan(plan)
      setStep('duration')
    },
    [_setSelectedPlan, setStep],
  )

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <TouchableSvg name="back" color="layout.dark" onPress={goBack} />
        <Separator width={5} />
        <Typography weight="medium" style={styles.headerText}>
          {t('title')}
        </Typography>
      </View>
      <Separator height={15} />
      <View style={styles.contentContainer}>
        <TitleText size="medium" color="layout.dark" style={styles.titleText}>
          {t(
            params?.personalUpgrade
              ? 'choosePlanPersonalUpgrade'
              : 'choosePlan',
          )}
        </TitleText>
        <Separator height={20} />
        <ToggleButton
          value={showAnnual}
          setValue={setShowAnnual}
          firstOptionText={t('annual')}
          secondOptionText={t('monthly')}
        />
        <Separator height={20} />
        <TitleText size="medium" color="layout.dark" style={styles.titleText}>
          {t('activeMembership.title')}
        </TitleText>
        <Separator height={20} />
        <Typography weight="light" style={styles.descriptionText}>
          {t('activeMembership.description')}
        </Typography>
        <Separator height={20} />
        {plans.map(plan => (
          <View key={plan.id}>
            <ActiveMembershipCard
              isMobile
              plan={plan}
              isSelected={plan.id === selectedPlan?.id}
              currentPlanCode={currentPlanCode}
              setSelectedPlan={setSelectedPlan}
            />
            <Separator height={20} />
          </View>
        ))}
      </View>
      <Separator height={50} />
      <UserStorageInfo />
      <Separator height={40} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors['layout.light'],
  },
  header: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingBottom: 24,
    ...appStyles.lightShadow,
    ...appStyles.inlineContainer,
    backgroundColor: Colors['layout.white'],
  },
  headerText: {
    fontSize: 20,
    lineHeight: 24,
  },
  contentContainer: {
    paddingHorizontal: 25,
  },
  titleText: { fontSize: 26 },
  descriptionText: { fontSize: 14 },
})
