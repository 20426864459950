import { User } from '../api/types'

/**
 * Users who selected group account type are assigned a managedGroupAccount which they are an admin of.
 * Unless they purchase a personal subscription, they shouldn't be shown personal account.
 * @returns whether user should have personal account hidden
 */
export default function (user?: User) {
  return (
    !!user?.managedGroupAccount?.fromRegistrationsForm && !user.currentPlanCode
  )
}
