import { useMemo } from 'react'

import { useUser } from '../providers/UserProvider'

export default function useLibraryId() {
  const { user, account, selectedAccountType } = useUser()
  return useMemo(() => {
    switch (selectedAccountType) {
      case 'User':
        return user?.libraryId
      case 'GroupAccount':
        return account?.libraryId
    }
  }, [user, account, selectedAccountType])
}
