import React, { useMemo, useCallback, useState } from 'react'
import {
  View,
  Modal,
  FlatList,
  StyleSheet,
  ListRenderItem,
  RefreshControl,
  TouchableOpacity,
} from 'react-native'

import { NetworkStatus } from '@apollo/client'
import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import {
  User,
  LimitedUser,
  useGetMembersQuery,
  useDeleteLimitedUserMutation,
  useRemoveUserFromGroupAccountMutation,
} from '../../api/types'
import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import DestructionConfirmationDialog from '../../components/DestructionConfirmationDialog'
import HeaderIconButton from '../../components/HeaderIconButton'
import Loading from '../../components/Loading'
import RootContainer from '../../components/RootContainer'
import Separator from '../../components/Separator'
import SvgIcon from '../../components/SvgIcon'
import Typography from '../../components/Text/Typography'
import TouchableSvg from '../../components/TouchableSvg'
import core from '../../core/core'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import { useUser } from '../../providers/UserProvider'
import { useWebLayout } from '../../providers/WebLayoutProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import { MainStackNavigationType } from '../../types/navigation-types'
import { convertHexToRGBA } from '../../utils/colors'

const Divider = () => <Separator height={20} />

type ListItemDataType = Pick<
  User,
  'id' | 'email' | 'firstName' | 'lastName' | 'gender' | 'pictureUrl'
> & {
  isSelf?: boolean
  isAdmin?: boolean
  confirmed?: LimitedUser['confirmed']
  __typename?: User['__typename'] | LimitedUser['__typename']
}

function keyExtractor(item: ListItemDataType, _index: number) {
  return `${item.__typename}-${item.id}`
}

export default function AccountMembersListScreen(): JSX.Element {
  const { t } = useTranslation()
  const { webLayoutEnabled } = useWebLayout()
  const { navigate, goBack } =
    useNavigation<MainStackNavigationType<'AccountMembers'>>()

  const { account, user, logOut } = useUser()
  const amAdmin = user && user.managedGroupAccount?.id === account?.id

  const { data, loading, networkStatus, refetch } = useGetMembersQuery({
    skip: !account?.id,
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    variables: { groupAccountId: account?.id! },
  })

  const [unconfirmedDeletionItem, setUnconfirmedDeletionItem] =
    useState<ListItemDataType>()
  const [
    deleteLimitedUserMutation,
    { loading: isDeletingLimitedUser, client },
  ] = useDeleteLimitedUserMutation()
  const [removeUserFromGroupAccountMutation, { loading: isRemovingUser }] =
    useRemoveUserFromGroupAccountMutation()

  const items: ListItemDataType[] = useMemo(() => {
    const users = data?.groupAccount?.users?.nodes
    const limitedUsers = data?.groupAccount?.limitedUsers?.nodes

    const aggregate = [] as ListItemDataType[]

    data?.groupAccount?.admin &&
      aggregate.push({ ...data.groupAccount.admin, isAdmin: true })

    users && aggregate.push(...users)
    limitedUsers && aggregate.push(...limitedUsers)

    return aggregate.map(item => ({
      ...item,
      isSelf:
        item.id === user?.id &&
        (item.__typename === 'LimitedUser'
          ? item.__typename === user?.userType
          : user?.userType === 'UnlimitedUser'),
    }))
  }, [data, user])

  const onAddUserPress = useCallback(() => {
    navigate('AddAccountMember')
  }, [navigate])

  const renderHeader = useCallback(
    () =>
      !items.length ? null : (
        <>
          <Typography style={styles.listHeaderText}>
            {t('screens.accountMembers.listHeader')}
          </Typography>
          <Separator height={19} />
        </>
      ),
    [items, t],
  )

  const onDeletePress = useCallback(
    (item: ListItemDataType) => setUnconfirmedDeletionItem(item),
    [],
  )

  const dismissDeleteConfirmation = useCallback(
    () => setUnconfirmedDeletionItem(undefined),
    [],
  )

  const confirmationProps = useMemo(() => {
    switch (unconfirmedDeletionItem?.__typename) {
      case 'User':
        return {
          visible: true,
          translationPrefix:
            'screens.accountMembers.removeConfirmation' as const,
          onConfirm: () => {
            dismissDeleteConfirmation()
            return removeUserFromGroupAccountMutation({
              variables: { id: unconfirmedDeletionItem.id },
            })
              .then(() => {
                if (unconfirmedDeletionItem.isSelf) return logOut()

                client.cache.evict({
                  fieldName: 'groupAccount',
                })
              })
              .catch(core.logging.error)
          },
        }
      case 'LimitedUser':
        return {
          visible: true,
          translationPrefix:
            'screens.accountMembers.limitedUserDestroyConfirmation' as const,
          onConfirm: () => {
            const deletable = unconfirmedDeletionItem
            dismissDeleteConfirmation()
            return deleteLimitedUserMutation({
              variables: { id: deletable.id },
            })
              .then(() => {
                if (deletable.isSelf) return logOut()

                client.cache.evict({
                  id: client.cache.identify(deletable),
                })
                client.cache.gc()
              })
              .catch(core.logging.error)
          },
        }

      default:
        return {
          visible: false,
          // bellow values are for ts only
          onConfirm: Promise.reject,
          translationPrefix:
            'screens.accountMembers.removeConfirmation' as const,
        }
    }
  }, [
    logOut,
    client.cache,
    unconfirmedDeletionItem,
    dismissDeleteConfirmation,
    deleteLimitedUserMutation,
    removeUserFromGroupAccountMutation,
  ])

  const renderItem: ListRenderItem<ListItemDataType> = useCallback(
    ({ item }) => {
      const name = [item.firstName, item.lastName].filter(Boolean).join(' ')
      const canDelete = !item.isAdmin && (amAdmin || item.isSelf)
      return (
        <View style={styles.itemCard}>
          {item.__typename === 'LimitedUser' &&
          !item.confirmed &&
          !item.pictureUrl ? (
            <View style={styles.picturePlaceholder} />
          ) : (
            <Avatar size={77} uri={item.pictureUrl} gender={item.gender} />
          )}
          <Separator width={14} />
          <View style={styles.itemDetailsContainer}>
            {name && (
              <Typography
                weight="bold"
                style={canDelete && styles.deleteButtonMargin}>
                {name}
              </Typography>
            )}
            {item.email && <Typography>{item.email}</Typography>}
            <Typography weight="light">
              {t(
                `enums.groupAccountMemberRole.${
                  item.isAdmin ? 'admin' : item.__typename ?? 'User'
                }`,
              )}
              {item.__typename === 'LimitedUser' &&
                !item.confirmed &&
                ` (${t('screens.accountMembers.pending')})`}
            </Typography>
          </View>
          {canDelete && (
            <TouchableOpacity
              hitSlop={10}
              style={styles.deleteButton}
              onPress={() => onDeletePress(item)}>
              <SvgIcon
                name="bin"
                color={Colors['layout.dark']}
                width={DELETE_BUTTON_SIZE}
                height={DELETE_BUTTON_SIZE}
              />
            </TouchableOpacity>
          )}
        </View>
      )
    },
    [t, amAdmin, onDeletePress],
  )

  const renderListEmpty = useCallback(
    () =>
      loading ? null : (
        <View style={styles.listEmptyCard}>
          <SvgIcon name="user-network" />
          <Separator height={16} />
          <Typography>{t('screens.accountMembers.empty')}</Typography>
          <Separator height={30} />
          <Button
            text={t('screens.accountMembers.addMemberButton')}
            type={amAdmin ? 'primary' : 'disabled'}
            onPress={onAddUserPress}
          />
        </View>
      ),
    [onAddUserPress, t, loading, amAdmin],
  )

  return (
    <RootContainer contentContainerStyle={styles.container}>
      <Loading loading={loading} />
      <Loading blocking loading={isRemovingUser || isDeletingLimitedUser} />
      <View
        style={[
          useSafeAreaPaddedStyle(styles.header, headerOptions),
          webLayoutEnabled ? styles.webHeaderModifier : appStyles.lightShadow,
        ]}>
        <View style={appStyles.inlineContainer}>
          <TouchableSvg name="back" color="layout.dark" onPress={goBack} />
          <Separator width={5} />
          <Typography weight="medium" style={styles.titleText}>
            {t('screens.accountMembers.title')}
          </Typography>
        </View>
        {amAdmin && (
          <HeaderIconButton iconName="plusThin" onPress={onAddUserPress} />
        )}
      </View>
      <FlatList
        data={items}
        style={appStyles.fullSize}
        contentContainerStyle={styles.contentContainer}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        ItemSeparatorComponent={Divider}
        ListHeaderComponent={renderHeader}
        ListEmptyComponent={renderListEmpty}
        refreshControl={
          <RefreshControl
            refreshing={networkStatus === NetworkStatus.refetch}
            onRefresh={refetch}
          />
        }
      />
      <Modal
        transparent
        statusBarTranslucent
        animationType="fade"
        visible={confirmationProps.visible}
        onRequestClose={dismissDeleteConfirmation}>
        <DestructionConfirmationDialog
          title={t(`${confirmationProps.translationPrefix}.title`)}
          confirmText={t(
            `${confirmationProps.translationPrefix}.confirmButton`,
          )}
          subtitle={t(`${confirmationProps.translationPrefix}.subtitle`, {
            defaultValue: '',
            groupName: account?.name,
            email: unconfirmedDeletionItem?.email,
            interpolation: { escapeValue: false },
          })}
          close={dismissDeleteConfirmation}
          onConfirm={confirmationProps.onConfirm}
        />
      </Modal>
    </RootContainer>
  )
}

const AVATAR_SIZE = 77
const CARD_PADDING = 13
const DELETE_BUTTON_SIZE = 30
const DELETE_BUTTON_MARGIN = -7

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors['layout.white'],
  },
  header: {
    paddingTop: 15,
    paddingBottom: 24,
    paddingHorizontal: 24,
    ...appStyles.inlineContainer,
    justifyContent: 'space-between',
    backgroundColor: Colors['layout.white'],
    borderColor: convertHexToRGBA(Colors['layout.gray'], 0.5),
  },
  webHeaderModifier: { borderBottomWidth: 1 },
  titleText: {
    fontSize: 20,
    lineHeight: 24,
  },
  contentContainer: {
    flexGrow: 1,
    paddingTop: 20,
    marginHorizontal: 24,
  },
  itemCard: {
    ...appStyles.row,
    paddingTop: 14,
    borderRadius: 10,
    paddingBottom: 13,
    paddingHorizontal: CARD_PADDING,
    backgroundColor: Colors['layout.light'],
  },
  picturePlaceholder: {
    borderRadius: 7,
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
    backgroundColor: Colors['layout.gray'],
  },
  deleteButtonMargin: {
    marginRight: DELETE_BUTTON_SIZE + DELETE_BUTTON_MARGIN,
  },
  deleteButton: {
    top: CARD_PADDING + DELETE_BUTTON_MARGIN,
    right: CARD_PADDING + DELETE_BUTTON_MARGIN,
    position: 'absolute',
  },
  itemDetailsContainer: {
    ...appStyles.fullSize,
    ...appStyles.verticalCenter,
    gap: 7,
  },
  listEmptyCard: {
    ...appStyles.horizontalCenter,
    marginTop: 20,
    borderWidth: 1,
    borderRadius: 10,
    paddingVertical: 40,
    paddingHorizontal: 32,
    borderColor: convertHexToRGBA(Colors['layout.gray'], 0.5),
  },
  listHeaderText: { opacity: 0.5 },
})
