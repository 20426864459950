import { Buffer } from 'buffer'

import React, { useMemo, useCallback, useEffect, useState } from 'react'
import {
  View,
  FlatList,
  Platform,
  StyleSheet,
  RefreshControl,
} from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import TimelineItem from './components/TimelineItem'
import { getItemLayout } from './helpers/layoutHelpers'
import {
  Memory,
  LifeStage,
  useGetMemoriesQuery,
  GetCommonMemoriesQuery,
  useGetCommonMemoriesQuery,
} from '../../api/types'
import ListHeader from '../../components/ListHeader'
import Loading from '../../components/Loading'
import RootContainer from '../../components/RootContainer'
import usePassiveUserCheck from '../../hooks/usePassiveUserCheck'
import { useLocale } from '../../providers/LocaleProvider'
import {
  QueryKey,
  useIsRefreshing,
  useShouldRefetch,
  useSetShouldRefetch,
} from '../../providers/RefetchProvider'
import { useUser } from '../../providers/UserProvider'
import Colors from '../../styles/Colors'
import {
  MainStackNavigationType,
  MainStackParamsType,
} from '../../types/navigation-types'
import { mixinOpacity } from '../../utils/colors'
import { formatDate } from '../../utils/date'

export type CommonMemoryNode =
  GetCommonMemoriesQuery['commonMemories']['nodes'][0] // CommonMemory requires quizCatergories which we arent querying
type TimelineMemory =
  | {
      __typename: 'TimelineMemory'
      memory: Memory
      parentType: 'Year' | 'LifeStage'
    }
  | {
      __typename: 'TimelineMemory'
      memory: Memory | CommonMemoryNode
      parentType: 'Year'
    }

type FoundingYearStage = {
  __typename: 'FoundingYear'
  title: string
  subtitle: string
  foundingYear: number
}
type LifeStageTop = {
  __typename: 'LifeStageTop'
  stage: LifeStage | FoundingYearStage
  color: keyof typeof Colors
  previousColor?: keyof typeof Colors
  onEditPress?: () => void
}
type LifeStageBottom = {
  __typename: 'LifeStageBottom'
  stage: LifeStage
  color: keyof typeof Colors
  addButtonText: string
}

export type TimelineItemType = {
  lineColor: string
  content:
    | number
    | TimelineMemory
    | LifeStageTop
    | LifeStageBottom
    | 'bottom'
    | 'top'
  onPlusPress: (
    eventAt: Exclude<MainStackParamsType['MemoryForm']['eventAt'], undefined>,
  ) => void
}

export const TIMELINE_COLORS: LifeStageTop['color'][] = [
  'timeline.yellow-shading-light',
  'timeline.orange',
  'timeline.dark-orange',
  'timeline.light-red',
  'brand.red',
]

const initalVariables = {
  after: '',
  first: 1000, // TODO Reduce page size, finish and test pagination when backend order is fixed [years up to lifeStage1.start][lifeStage1][years, lifeStage1.start-lifestage2.start][lifestage2] ect.
}

const REFETCH_QUERIES = [
  QueryKey.User,
  QueryKey.Memories,
  QueryKey.OtherMemories,
  QueryKey.CommonMemories,
]

export default function TimelineScreen(): JSX.Element {
  const { t } = useTranslation()
  const { locale } = useLocale()
  const { navigate } = useNavigation<MainStackNavigationType<'Timeline'>>()

  const { user, sortedLifeStages, account } = useUser()
  const setShouldRefetch = useSetShouldRefetch()
  const isRefreshing = useIsRefreshing(...REFETCH_QUERIES)
  const checkIsPassiveUser = usePassiveUserCheck()

  const commonMemoriesOptions = useMemo(
    () => ({
      variables: { eventAfter: user?.dateOfBirth },
      skip: !user?.dateOfBirth,
    }),
    [user?.dateOfBirth],
  )
  const commonMemoriesQuery = useGetCommonMemoriesQuery(commonMemoriesOptions)
  useShouldRefetch(
    QueryKey.CommonMemories,
    commonMemoriesQuery.refetch,
    commonMemoriesOptions.variables,
    commonMemoriesOptions.skip,
  )
  // TODO: handle error
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, error, refetch, loading, variables } = useGetMemoriesQuery({
    variables: initalVariables,
  })
  useShouldRefetch(QueryKey.Memories, refetch, initalVariables)

  const [_memories, setMemories] = useState<Memory[]>()
  // TODO: Update query to only get memories with eventAt | lifeStage
  const memories: (Memory | CommonMemoryNode)[] = useMemo(
    () => [
      ...(_memories?.filter(m => m.eventAt || m.lifeStage) ?? []),
      ...(commonMemoriesQuery.data?.commonMemories.nodes ?? []),
    ],
    [_memories, commonMemoriesQuery.data],
  )

  const onEndReached = useCallback(() => {
    if (loading) return
    refetch({ after: data?.memories.pageInfo.endCursor ?? '' })
  }, [data, refetch, loading])

  useEffect(() => {
    if (data?.memories.nodes) {
      setMemories(current =>
        current
          ? current
              .slice(
                0,
                variables?.after.length
                  ? parseInt(
                      Buffer.from(variables.after, 'base64').toString(),
                      10,
                    )
                  : 0,
              )
              .concat(data.memories.nodes)
          : data.memories.nodes,
      )
    }
  }, [data, variables?.after])

  const groupFoundingStage: FoundingYearStage | undefined = useMemo(
    () =>
      account?.foundingYear && {
        __typename: 'FoundingYear',
        foundingYear: new Date(account.foundingYear).getFullYear(),
        title: t('screens.timeline.groupFoundingYearLifeStageTitle', {
          accountTypeName: t(
            `screens.registration.accountType.${account.accountType}`,
          ),
        }),
        subtitle: formatDate(
          new Date(account.foundingYear),
          locale,
          account.foundingYearPrec,
        ),
      },
    [t, locale, account],
  )

  const canEditGroupAccount = user?.managedGroupAccount?.id === account?.id

  const lifeStageItems = useMemo(() => {
    const items: (LifeStageTop | LifeStageBottom)[] = []

    if (groupFoundingStage) {
      items.push({
        __typename: 'LifeStageTop',
        stage: groupFoundingStage,
        color: TIMELINE_COLORS[0],
        onEditPress: !canEditGroupAccount
          ? undefined
          : () => navigate('EditGroupAccount'),
      })
    }

    sortedLifeStages.forEach((stage, i) => {
      items.push(
        {
          __typename: 'LifeStageTop',
          stage,
          color: TIMELINE_COLORS[i],
          previousColor: TIMELINE_COLORS[i - 1],
          onEditPress: () => navigate('EditLifeStage', { stage }),
        },
        {
          __typename: 'LifeStageBottom',
          stage,
          color: TIMELINE_COLORS[i],
          addButtonText: t('screens.timeline.addMemoryTo', {
            lifeStage: stage.title,
          }),
        },
      )
    })
    return items
  }, [t, navigate, sortedLifeStages, canEditGroupAccount, groupFoundingStage])

  const timelineItems: TimelineItemType[] = useMemo(() => {
    if (!memories) return []

    type TimelineContent = Exclude<
      TimelineItemType['content'],
      'bottom' | 'top'
    >

    const startDateString = account ? account.foundingYear : user?.dateOfBirth
    const start = Math.min(
      startDateString ? new Date(startDateString).getFullYear() : Infinity,
      sortedLifeStages[0]?.startYear ?? Infinity,
      memories[0]?.eventAt
        ? new Date(memories[0].eventAt).getFullYear()
        : Infinity,
    )
    if (start === Infinity) return []

    const lastYear = new Date().getFullYear()
    const lifeStagePredicate = (stage: LifeStage | FoundingYearStage) => {
      const startYear =
        stage.__typename === 'FoundingYear'
          ? stage.foundingYear
          : stage.startYear
      return !!startYear && startYear <= lastYear
    }

    const items: TimelineContent[] = Array(lastYear - start + 1)
      .fill(undefined)
      .map((_, i) => i + start)

    memories.forEach(memory => {
      if (memory.eventAt) {
        items.push({ __typename: 'TimelineMemory', memory, parentType: 'Year' })
      } else if (
        memory.__typename === 'Memory' &&
        memory.lifeStage &&
        lifeStagePredicate(memory.lifeStage)
      ) {
        items.push({
          __typename: 'TimelineMemory',
          memory,
          parentType: 'LifeStage',
        })
      }
    })

    lifeStageItems
      .filter(({ stage }) => lifeStagePredicate(stage))
      .forEach(item => items.push(item))

    /**
     * Returns year + some offset to position item on timeline.
     * Offset absolute value must stay under 0.5 so there are no conflicts with adjacent years.
     * Offset value does not change the postion on timeline, only its order relative to other items and their offsets
     * Negative offset (year - 0.1) puts the item before year element, positive (year + 0.1) puts it after.
     */
    const getYear = (item: TimelineContent) => {
      if (typeof item === 'number') return item
      if (item.__typename === 'LifeStageTop') {
        if (item.stage.__typename === 'FoundingYear')
          return item.stage.foundingYear + 0.05
        return item.stage.startYear! - 0.3
      }
      if (item.__typename === 'LifeStageBottom')
        return item.stage.startYear! - 0.1
      const memoryYear =
        item.parentType === 'Year'
          ? new Date(item.memory.eventAt).getFullYear() + 0.1
          : item.memory.lifeStage!.startYear! - 0.2
      return memoryYear
    }
    const sorted: TimelineContent[] = items.sort(
      (a, b) => getYear(a) - getYear(b),
    )

    const finalItems: TimelineItemType[] = []
    let lineColor: string = mixinOpacity(
      Colors[TIMELINE_COLORS[0]],
      0.5,
      styles.container.backgroundColor,
    )

    const onPlusPress: TimelineItemType['onPlusPress'] = eventAt => {
      checkIsPassiveUser(false).then(isPassive => {
        if (isPassive) return
        navigate('MemoryForm', { eventAt })
      })
    }

    if (typeof sorted[0] === 'number') {
      finalItems.push({ content: 'top', lineColor, onPlusPress })
    }
    sorted.forEach(item => {
      if (typeof item !== 'number' && item.__typename === 'LifeStageTop') {
        lineColor = mixinOpacity(
          Colors[item.color],
          0.5,
          styles.container.backgroundColor,
        )
      }
      finalItems.push({ content: item, lineColor, onPlusPress })
    })
    if (!data?.memories.pageInfo.hasNextPage) {
      finalItems.push({ content: 'bottom', lineColor, onPlusPress })
    }

    return finalItems
  }, [
    user,
    account,
    memories,
    navigate,
    lifeStageItems,
    sortedLifeStages,
    checkIsPassiveUser,
    data?.memories.pageInfo.hasNextPage,
  ])

  const keyExtractor = useCallback(({ content }: TimelineItemType): string => {
    if (typeof content === 'number') return String(content)
    if (typeof content === 'string') return content
    switch (content.__typename) {
      case 'LifeStageTop':
        return content.stage.__typename === 'FoundingYear'
          ? content.stage.foundingYear + 'foundingYear'
          : content.stage.id + 'top'
      case 'LifeStageBottom':
        return content.addButtonText + 'bottom'
      default:
        return `${content.memory.__typename}${content.memory.id}`
    }
  }, [])

  const renderHeader = useCallback(
    () => (
      <View style={styles.header}>
        <ListHeader
          title={t('screens.timeline.title')}
          subtitle={t('screens.timeline.subtitle')}
          negatablePaddingSource={{
            paddingTop: styles.contentContainer.paddingTop,
            ...styles.header,
          }}
        />
      </View>
    ),
    [t],
  )

  return (
    <RootContainer>
      <Loading loading={loading || commonMemoriesQuery.loading || !user} />
      <FlatList
        data={timelineItems}
        style={styles.container}
        extraData={TimelineItem}
        keyExtractor={keyExtractor}
        getItemLayout={getItemLayout}
        renderItem={TimelineItem}
        refreshControl={
          <RefreshControl
            refreshing={isRefreshing}
            onRefresh={() => REFETCH_QUERIES.map(setShouldRefetch)}
          />
        }
        ListHeaderComponent={renderHeader}
        contentContainerStyle={styles.contentContainer}
        onEndReached={onEndReached}
      />
    </RootContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors['layout.light'],
  },
  contentContainer: {
    flexGrow: 1,
    paddingTop: 27,
    paddingLeft: 8,
    paddingRight: 10,
    paddingBottom: 10,
    height: Platform.select({ web: 0 }),
  },
  header: {
    marginLeft: -8,
    marginRight: -10,
    paddingHorizontal: 24,
  },
})
