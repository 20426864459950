import { useMemo, useRef, useEffect, useState } from 'react'

/**
 * Checks whether or not arrays are same length and the contained elements have the same id
 */
export function isArrayEqual<T extends { id: string }>(
  a: T[] | undefined,
  b: T[] | undefined,
): boolean {
  if (!a && !b) return true

  return (
    a?.length === b?.length &&
    a?.findIndex((value, index) => value.id !== b?.[index].id) === -1
  )
}

/**
 * Tracks whether passed values have changed since the initial render.
 * Option isReady can be used to update initial values after they are fetched asynchronically
 */
export default function useIsUnsaved(
  values: (
    | string
    | number
    | boolean
    | undefined
    | Date
    | null
    | { id: string }[]
  )[],
  options?: { isReady: boolean },
) {
  const [isReady, setIsReady] = useState(!options)
  const initialValues = useRef(values)

  useEffect(() => {
    if (options?.isReady) {
      initialValues.current = values
      setIsReady(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options?.isReady])

  const isUnsaved = useMemo(
    () =>
      initialValues.current.findIndex((val, index) => {
        const current = values[index]
        return Array.isArray(val) && Array.isArray(current)
          ? !isArrayEqual(val, current)
          : val instanceof Date && current instanceof Date
          ? val.valueOf() !== current.valueOf()
          : val !== current
      }) !== -1,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isReady, ...values],
  )

  return isUnsaved
}
