import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
  PropsWithChildren,
} from 'react'
import { Platform } from 'react-native'

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { useMMKVObject, useMMKVString, MMKV } from 'react-native-mmkv'

import { useLocale } from './LocaleProvider'
import { useShouldRefetch, QueryKey } from './RefetchProvider'
import buildClient from '../api/apollo-client'
import {
  User,
  LifeStage,
  GroupAccount,
  useGetUserQuery,
  useGetGroupAccountQuery,
  useSwitchGroupAccountMutation,
} from '../api/types'
import loggingCore from '../core/logging-core'
import RestApi from '../core/RestApi'
import getIsGroupAdminOnly from '../helpers/getIsGroupAdminOnly'
import { UnauthorizedStackParamsType } from '../types/navigation-types'
import { OAuth } from '../types/user-types'

type UserContextType = {
  selectedAccountType: 'User' | 'GroupAccount' | undefined
  user: User | undefined
  isLoggedIn: boolean | undefined
  login: (params: { email: string; password: string }) => Promise<void>
  logOut: () => void
  resetPassword: (params: {
    password: string
    password_confirmation: string
    reset_password_token: string
  }) => Promise<void>
  confirmEmail: (params: {
    confirmation_token: string
    user_type: Exclude<
      UnauthorizedStackParamsType['EmailConfirmation'],
      undefined
    >['type']
  }) => Promise<void>
  sortedLifeStages: LifeStage[]
  getPublicClient: (params: {
    shareToken: string
  }) => ApolloClient<NormalizedCacheObject>
  account: GroupAccount | undefined
  switchAccount: (account: GroupAccount | User) => Promise<void>
}

const UserContext = createContext<UserContextType>({
  selectedAccountType: undefined,
  user: undefined,
  isLoggedIn: undefined,
  login: () => Promise.reject(),
  logOut: () => {},
  resetPassword: () => Promise.reject(),
  confirmEmail: () => Promise.reject(),
  sortedLifeStages: [],
  getPublicClient: () => {
    throw new Error('No provider')
  },
  // TODO: scope app from user to account, if selected (https://mitigate.atlassian.net/browse/REL-348)
  account: undefined,
  switchAccount: Promise.reject,
})

const storage = new MMKV({
  id: 'user-store',
  encryptionKey: Platform.OS === 'web' ? undefined : 'XhwIjoxNjg4NTUyN',
})
const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [oAuth, setOAuth] = useMMKVObject<OAuth>('oauth', storage)
  const [groupAccountToken, setGroupAccountToken] = useMMKVString(
    'groupToken',
    storage,
  )
  const [selectedGroupAccount, setSelectedGroupAccount] =
    useState<GroupAccount>()
  const [selectedGroupAccountId, setSelectedGroupAccountId] = useMMKVString(
    'groupId',
    storage,
  )
  const hasDefaultedToGroupAccount = useRef(false)
  const isLoggedIn = useMemo(() => !!oAuth, [oAuth])

  const { setLocale } = useLocale()

  const logOut = useCallback(() => {
    setOAuth(undefined)
    setGroupAccountToken(undefined)
    setSelectedGroupAccount(undefined)
    setSelectedGroupAccountId(undefined)
    hasDefaultedToGroupAccount.current = false
  }, [setOAuth, setGroupAccountToken, setSelectedGroupAccountId])

  const isRefreshingToken = useRef(false)
  const refreshToken = useCallback(
    (retryCounter: number = 0) => {
      if (isRefreshingToken.current && retryCounter === 0) return

      setGroupAccountToken(undefined)
      isRefreshingToken.current = true
      RestApi.post('users/sign_in', {
        refresh_token: oAuth?.refresh_token,
      })
        .then(freshAuth => {
          if (freshAuth) setOAuth(freshAuth)
        })
        .catch(() => {
          if (retryCounter < 3) return refreshToken(retryCounter + 1)
          else logOut()
        })
        .finally(() => {
          isRefreshingToken.current = false
        })
    },
    [logOut, oAuth?.refresh_token, setOAuth, setGroupAccountToken],
  )

  const token = useMemo(
    () => groupAccountToken ?? oAuth?.token,
    [groupAccountToken, oAuth?.token],
  )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cache = useMemo(() => new InMemoryCache(), [token])

  const client = useMemo(
    () => buildClient(refreshToken, cache, token),
    [cache, token, refreshToken],
  )

  const getPublicClient: UserContextType['getPublicClient'] = useCallback(
    ({ shareToken }) => buildClient(refreshToken, cache, token, shareToken),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token, refreshToken],
  )

  const [switchGroupAccountMutation] = useSwitchGroupAccountMutation({ client })
  const { data, refetch } = useGetUserQuery({ skip: !isLoggedIn, client })
  useShouldRefetch(QueryKey.User, refetch, undefined, !isLoggedIn)
  const user = useMemo(() => data?.me as User, [data])

  const groupAccountId =
    selectedGroupAccountId ?? data?.me.limitedUserParentGroupAccountId
  const { data: groupAccountData } = useGetGroupAccountQuery({
    client,
    skip: !groupAccountId,
    variables: { id: groupAccountId! },
    onError: error => {
      if (error.message === 'not_found') {
        setSelectedGroupAccountId(undefined)
      }
    },
  })

  const groupAccount: GroupAccount | undefined = useMemo(() => {
    if (!groupAccountId) return

    if (groupAccountId === groupAccountData?.groupAccount?.id)
      return groupAccountData.groupAccount

    if (groupAccountId === selectedGroupAccount?.id) return selectedGroupAccount
  }, [groupAccountId, groupAccountData, selectedGroupAccount])

  const isGroupAdminOnly = getIsGroupAdminOnly(user)

  useEffect(() => {
    if (
      isGroupAdminOnly &&
      user?.managedGroupAccount?.id &&
      !hasDefaultedToGroupAccount.current
    ) {
      // This should only be done once
      hasDefaultedToGroupAccount.current = true
      setSelectedGroupAccountId(user.managedGroupAccount.id)
    }
  }, [
    isGroupAdminOnly,
    user?.managedGroupAccount?.id,
    setSelectedGroupAccountId,
  ])

  const selectedAccountType: UserContextType['selectedAccountType'] =
    useMemo(() => {
      if (
        !!groupAccountId ||
        user?.userType === 'LimitedUser' ||
        isGroupAdminOnly
      )
        return 'GroupAccount'

      if (!user) return undefined

      return 'User'
    }, [groupAccountId, user, isGroupAdminOnly])

  const language = user?.language ?? groupAccount?.language
  useEffect(() => {
    language && setLocale(language)
  }, [language, setLocale])

  const login: UserContextType['login'] = useCallback(
    ({ email, password }) =>
      RestApi.post('users/sign_in', {
        email,
        password,
      }).then(oauth => {
        setOAuth(oauth)
      }),
    [setOAuth],
  )

  const resetPassword: UserContextType['resetPassword'] = useCallback(
    ({ password, password_confirmation, reset_password_token }) =>
      RestApi.put('users/password', {
        password,
        password_confirmation,
        reset_password_token,
      }).then(setOAuth),
    [setOAuth],
  )

  const confirmEmail: UserContextType['confirmEmail'] = useCallback(
    ({ confirmation_token, user_type }) =>
      RestApi.post(`${user_type ?? 'user'}s/confirmation`, {
        confirmation_token,
      }).then(setOAuth),
    [setOAuth],
  )

  const switchAccount: UserContextType['switchAccount'] = useCallback(
    (account: GroupAccount | User) => {
      const asGroupAccount =
        account.__typename === 'GroupAccount'
          ? (account as GroupAccount)
          : undefined
      return switchGroupAccountMutation({
        variables: {
          groupAccountId: asGroupAccount?.id,
        },
      }).then(response => {
        setGroupAccountToken(response.data?.switchAccountToken?.token)
        setSelectedGroupAccount(asGroupAccount)
        setSelectedGroupAccountId(asGroupAccount?.id)
      })
    },
    [
      setGroupAccountToken,
      setSelectedGroupAccount,
      setSelectedGroupAccountId,
      switchGroupAccountMutation,
    ],
  )

  useEffect(() => {
    if (!groupAccountToken && !!selectedGroupAccountId) {
      switchGroupAccountMutation({
        variables: {
          groupAccountId: selectedGroupAccountId,
        },
      })
        .then(response => {
          setGroupAccountToken(response.data?.switchAccountToken?.token)
        })
        .catch(error => {
          if (
            error?.networkError?.statusCode === 404 ||
            error?.graphQLErrors?.[0]?.extensions.message ===
              'account_not_accessible'
          ) {
            setSelectedGroupAccountId(undefined)
          } else {
            loggingCore.error(error)
          }
        })
    }
  }, [
    groupAccountToken,
    setGroupAccountToken,
    selectedGroupAccountId,
    setSelectedGroupAccountId,
    switchGroupAccountMutation,
  ])

  const sortedLifeStages = useMemo(() => {
    if (!user?.lifeStages?.nodes || selectedAccountType !== 'User') return []

    return [...user.lifeStages.nodes].sort(
      (a, b) => (a.startYear ?? 0) - (b.startYear ?? 0),
    )
  }, [user?.lifeStages, selectedAccountType])

  return (
    <UserContext.Provider
      value={useMemo(
        () => ({
          selectedAccountType,
          user,
          isLoggedIn,
          login,
          logOut,
          resetPassword,
          confirmEmail,
          sortedLifeStages,
          getPublicClient,
          account: groupAccount,
          switchAccount,
        }),
        [
          selectedAccountType,
          user,
          isLoggedIn,
          login,
          logOut,
          resetPassword,
          confirmEmail,
          sortedLifeStages,
          getPublicClient,
          groupAccount,
          switchAccount,
        ],
      )}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </UserContext.Provider>
  )
}

export default UserProvider

export function useUser() {
  return useContext(UserContext)
}
