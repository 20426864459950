import React, { useMemo, useState } from 'react'
import { Image, Pressable, StyleSheet, View } from 'react-native'

import { useTranslation } from 'react-i18next'

import { ZohoPlan } from '../../../api/types'
import Separator from '../../../components/Separator'
import SvgIcon from '../../../components/SvgIcon'
import ButtonText from '../../../components/Text/ButtonText'
import TitleText from '../../../components/Text/TitleText'
import Typography from '../../../components/Text/Typography'
import { useLocale } from '../../../providers/LocaleProvider'
import appStyles from '../../../styles/app-styles'
import Colors from '../../../styles/Colors'
import { convertHexToRGBA, mixinOpacity } from '../../../utils/colors'
import formatPriceEuros from '../helpers/formatPriceEuros'

export const ActiveMembershipCard: React.FC<{
  plan: ZohoPlan
  isMobile?: boolean
  isSelected: boolean
  currentPlanCode?: string | null
  setSelectedPlan: (selected?: ZohoPlan) => void
}> = ({ plan, isMobile, isSelected, currentPlanCode, setSelectedPlan }) => {
  const { locale } = useLocale()
  const { t } = useTranslation(undefined, {
    keyPrefix: 'screens.settings.subscriptions',
  })
  const [isHovered, setIsHovered] = useState(false)
  const [isPressed, setIsPressed] = useState(false)
  const isCurrent = useMemo(
    () =>
      plan.planCode === currentPlanCode ||
      (currentPlanCode === null && !plan.id),
    [currentPlanCode, plan.id, plan.planCode],
  )

  const selectButtonColor = useMemo(() => {
    if (isSelected) {
      return isPressed
        ? mixinOpacity(
            Colors['timeline.yellow'],
            0.4,
            Colors['timeline.yellow-shading-dark'],
          )
        : isHovered
        ? mixinOpacity(
            Colors['timeline.yellow'],
            0.6,
            Colors['timeline.yellow-shading-dark'],
          )
        : Colors['timeline.yellow']
    } else {
      return isPressed
        ? mixinOpacity(Colors['brand.action'], 0.6, Colors['layout.dark'])
        : isHovered
        ? mixinOpacity(Colors['brand.action'], 0.8, Colors['layout.dark'])
        : Colors['brand.action']
    }
  }, [isHovered, isPressed, isSelected])

  return (
    <Pressable
      disabled={!plan.planCode && !isMobile}
      style={[
        styles.container,
        isMobile && styles.mobileContainer,
        !isMobile && isSelected && !isCurrent && styles.containerSelected,
        !isMobile && isHovered && appStyles.heavyShadow,
        isMobile && isPressed && appStyles.heavyShadow,
      ]}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
      onPressIn={() => setIsPressed(true)}
      onPressOut={() => setIsPressed(false)}
      onPress={() => {
        if (!plan.planCode && !isMobile) return
        if (isMobile) {
          setSelectedPlan(plan)
          return
        }
        if (!isCurrent) setSelectedPlan(isSelected ? undefined : plan)
      }}>
      <View
        style={[
          styles.currentIndicator,
          isCurrent && styles.currentIndicatorActive,
        ]}>
        {isCurrent && (
          <Typography style={styles.currentPlanText}>
            {t('currentPlan')}
          </Typography>
        )}
      </View>
      <View style={styles.contentContainer}>
        <Separator height={10} />
        <View style={styles.titleContainer}>
          <View style={appStyles.fullSize}>
            <View style={styles.nameContainer}>
              {isCurrent && (
                <>
                  <SvgIcon name="check" />
                  <Separator width={10} />
                </>
              )}
              <TitleText
                size="medium"
                color="layout.dark"
                style={[
                  styles.name,
                  !!plan.colorCode && { color: plan.colorCode },
                ]}>
                {plan.name}
              </TitleText>
            </View>
            <TitleText size="medium" color="layout.dark">
              {`${plan.allocatedSpace?.toLocaleString(locale, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
              })} GB`}
            </TitleText>
            <Typography weight="light" style={styles.cloudStorageText}>
              {t('cloudStorage')}
            </Typography>
          </View>
          {plan.badgeImageUrl && (
            <Image
              source={{ uri: plan.badgeImageUrl }}
              style={styles.badgeImage}
            />
          )}
        </View>
        <Separator height={10} />
        <Typography weight="light" style={styles.targetUsersText}>
          {plan.targetUsers}
        </Typography>
        <Typography weight="light" style={styles.descriptionText}>
          {plan.description?.replace(/[^\\]\\n/g, '\n')}
        </Typography>
        <Separator height={10} />
        <View style={styles.line} />
        <Separator height={20} />
        {plan.price && (
          <TitleText size="medium" color="layout.dark">
            {`${formatPriceEuros(plan.price, locale)} / ${
              plan.anual ? t('year') : t('month')
            }`}
          </TitleText>
        )}
        {plan.anual && plan.price && (
          <Typography weight="light" style={styles.cloudStorageText}>
            {t('equalMonthly', {
              price: formatPriceEuros(plan.price / 12, locale),
            })}
          </Typography>
        )}
        <Separator height={20} />
        {!isMobile && !isCurrent && !!plan.planCode && (
          <View
            style={[
              styles.selectButton,
              { backgroundColor: selectButtonColor },
            ]}>
            <ButtonText
              size="large"
              color={isSelected ? 'layout.dark' : 'layout.white'}
              style={styles.selectButtonText}>
              {isSelected ? t('selected') : t('select')}
            </ButtonText>
          </View>
        )}
        <Separator height={20} />
      </View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  container: {
    borderTopWidth: 2,
    borderLeftWidth: 2,
    borderRightWidth: 2,
    ...appStyles.fullSize,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    ...appStyles.removedOutline,
    borderColor: Colors['layout.white'],
    backgroundColor: Colors['layout.white'],
  },
  mobileContainer: {
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  containerSelected: {
    borderColor: Colors['brand.action'],
  },
  contentContainer: {
    paddingHorizontal: 14,
  },
  currentIndicator: {
    height: 25,
    marginTop: -2,
    ...appStyles.center,
    marginHorizontal: -2,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  currentIndicatorActive: {
    backgroundColor: Colors['timeline.yellow'],
  },
  currentPlanText: {
    fontSize: 14,
  },
  titleContainer: {
    ...appStyles.inlineContainer,
    justifyContent: 'space-between',
  },
  nameContainer: {
    ...appStyles.fullSize,
    ...appStyles.inlineContainer,
  },
  name: {
    ...appStyles.fullSize,
    // @ts-ignore
    wordBreak: 'break-word',
    color: Colors['layout.dark'],
  },
  cloudStorageText: {
    fontSize: 14,
  },
  badgeImage: {
    width: 59,
    height: 59,
  },
  targetUsersText: {
    fontSize: 12,
  },
  descriptionText: {
    fontSize: 14,
    opacity: 0.7,
  },
  line: {
    height: 1,
    backgroundColor: convertHexToRGBA(Colors['layout.black'], 0.2),
  },
  selectButton: {
    padding: 15,
    width: '100%',
    maxWidth: 244,
    borderRadius: 27,
    alignSelf: 'center',
  },
  selectButtonText: { textAlign: 'center' },
})
