import React, { useCallback, useMemo } from 'react'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import {
  GroupAccountForm,
  GroupAccountFormProps,
} from './components/GroupAccountForm'
import { useUpdateGroupAccountMutation } from '../../api/types'
import { useUser } from '../../providers/UserProvider'
import { MainStackNavigationType } from '../../types/navigation-types'

export default function EditGroupAccountScreen(): JSX.Element {
  const { goBack } =
    useNavigation<MainStackNavigationType<'EditGroupAccount'>>()

  const { t } = useTranslation()
  const { account } = useUser()

  const [updateGroupMutation] = useUpdateGroupAccountMutation()

  const initialValues: GroupAccountFormProps['initialValues'] = useMemo(
    () =>
      // NOTE: creating account without accountType or name is not allowed so this is purely typechecking
      account?.accountType && {
        ...account,
        name: account.name ?? '',
      },
    [account],
  )

  const validate: GroupAccountFormProps['validate'] = useCallback(
    ({ name }) =>
      !name
        ? {
            isValid: false,
            errors: { name: t('screens.registration.aboutGroup.noName') },
          }
        : { isValid: true },
    [t],
  )

  const submit: GroupAccountFormProps['submit'] = useCallback(
    input =>
      !account?.id
        ? Promise.reject()
        : updateGroupMutation({
            variables: {
              id: account.id,
              input,
            },
          }).then(goBack),
    [goBack, updateGroupMutation, account?.id],
  )
  return (
    <GroupAccountForm
      initialValues={initialValues}
      submit={submit}
      validate={validate}
      onCancelPress={goBack}
    />
  )
}
