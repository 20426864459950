import React from 'react'

import { SubscriptionsScreenMobileActive } from './SubscriptionsScreenMobileActive'
import { SubscriptionsScreenMobileAddon } from './SubscriptionsScreenMobileAddon'
import { SuscriptionsScreenMobileSummary } from './SuscriptionsScreenMobileSummary'
import { SubscriptionsScreenProps } from '../../types/SubscriptionScreen.types'

export const SubscriptionsScreenMobile: React.FC<
  SubscriptionsScreenProps
> = props => {
  const { forGroupAccountType, step, selectedPlan } = props

  if (forGroupAccountType ? step === 'plan' : !selectedPlan)
    return <SubscriptionsScreenMobileActive {...props} />
  if (step === 'duration' || !forGroupAccountType)
    return <SubscriptionsScreenMobileAddon {...props} addonType="duration" />
  if (step === 'members')
    return <SubscriptionsScreenMobileAddon {...props} addonType="members" />

  if (step === 'storage')
    return <SubscriptionsScreenMobileAddon {...props} addonType="storage" />

  return <SuscriptionsScreenMobileSummary {...props} />
}
