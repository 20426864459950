import React from 'react'
import { StyleSheet, View } from 'react-native'

import AvatarPlaceholder from './AvatarPlaceholder'
import Picture from './Picture'
import { UserGenderEnum, GroupAccountTypeEnum } from '../api/types'

type AvatarProps = {
  uri?: string | null
  gender: UserGenderEnum | GroupAccountTypeEnum | null | undefined
  size?: number
  borderRadius?: number
}
const Avatar: React.FC<AvatarProps> = ({
  uri,
  gender,
  size = 100,
  borderRadius = 20,
}) => {
  if (uri)
    return (
      <Picture
        style={[styles.picture, { width: size, height: size, borderRadius }]}
        sourceUri={uri}
      />
    )

  return (
    <View style={styles.picture}>
      <AvatarPlaceholder gender={gender} width={size} height={size} />
    </View>
  )
}

export default Avatar

const styles = StyleSheet.create({
  picture: {
    overflow: 'hidden',
  },
})
