import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'

import { useTranslation } from 'react-i18next'

import { PassiveMembershipRow } from './PassiveMembershipRow'
import { ZohoAddon, ZohoPlan, GroupAccountTypeEnum } from '../../../api/types'
import Separator from '../../../components/Separator'
import Typography from '../../../components/Text/Typography'
import appStyles from '../../../styles/app-styles'
import Colors from '../../../styles/Colors'
import {
  AddonType,
  SubscriptionsScreenProps,
} from '../../../types/SubscriptionScreen.types'
import { convertHexToRGBA } from '../../../utils/colors'
import { getAddonPredicate } from '../helpers/getAddonPredicate'

export const freeAddon: ZohoAddon = {
  id: '',
  price: 0,
  addonCode: '',
}

export const PassiveMembershipCard: React.FC<{
  plan: ZohoPlan
  isLast: boolean
  isCurrent: boolean
  addonType: AddonType
  selectedPlan?: ZohoPlan
  selectedAddon?: ZohoAddon
  currentAddonCode?: string | null
  forGroupAccountType?: GroupAccountTypeEnum
  selectAddon: SubscriptionsScreenProps['selectAddon']
}> = ({
  plan,
  isLast,
  isCurrent,
  addonType,
  selectedPlan,
  selectedAddon,
  currentAddonCode,
  selectAddon,
  forGroupAccountType,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'screens.settings.subscriptions',
  })
  const [isHovered, setIsHovered] = useState(false)

  const isSelected = useMemo(
    () => plan.id === selectedPlan?.id,
    [selectedPlan, plan],
  )

  const setSelectedAddon = useCallback(
    (addon: ZohoAddon) => {
      selectAddon(addonType, addon)
    },
    [addonType, selectAddon],
  )
  useEffect(() => {
    setSelectedAddon(freeAddon)
  }, [isSelected, setSelectedAddon])

  const addons: ZohoAddon[] = useMemo(() => {
    if (!plan.zohoAddons?.nodes) return []
    return [
      freeAddon,
      ...plan.zohoAddons.nodes.filter(getAddonPredicate(addonType)),
    ]
  }, [plan.zohoAddons?.nodes, addonType])

  const selectedAddonCode = useMemo(() => {
    const addonCode =
      addons.find(addon => addon.addonCode === selectedAddon?.addonCode)
        ?.addonCode ?? ''
    if (isCurrent && !addonCode) return currentAddonCode ?? ''
    return addonCode
  }, [addons, currentAddonCode, isCurrent, selectedAddon?.addonCode])

  return (
    <Pressable
      disabled={!isSelected}
      style={[
        styles.container,
        isLast && styles.lastContainer,
        isSelected && !isCurrent && styles.containerSelected,
        isSelected && styles.containerHighlighted,
        isHovered && appStyles.heavyShadow,
      ]}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}>
      <Separator height={20} />
      <Typography weight="bold" style={styles.descriptionText}>
        {t(`extended.title.${addonType}`, { planName: plan.name })}
      </Typography>
      <Separator height={10} />
      <View style={styles.line} />
      <Separator height={10} />
      <View pointerEvents={isSelected ? 'auto' : 'none'}>
        {addons.map(addon => (
          <View key={addon.id}>
            <PassiveMembershipRow
              addon={addon}
              addonType={addonType}
              forGroupAccountType={forGroupAccountType}
              isSelected={selectedAddonCode === addon.addonCode}
              setSelected={setSelectedAddon}
            />
            <Separator height={10} />
          </View>
        ))}
      </View>
      <Separator height={10} />
    </Pressable>
  )
}

export const PassiveMembershipEmptyCard: React.FC<{
  isLast: boolean
  showActive: boolean
  addonType: AddonType
  forGroupAccountType?: GroupAccountTypeEnum
}> = ({ isLast, addonType, showActive, forGroupAccountType }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'screens.settings.subscriptions.freePlan.included',
  })
  return (
    <View
      style={[
        styles.container,
        isLast && styles.lastContainer,
        appStyles.center,
        showActive && styles.containerHighlighted,
      ]}>
      <Typography weight="bold" style={styles.descriptionText}>
        {addonType === 'members'
          ? t(`members.${forGroupAccountType!}`)
          : t(addonType)}
      </Typography>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    opacity: 0.5,
    borderLeftWidth: 2,
    borderRightWidth: 2,
    paddingHorizontal: 14,
    ...appStyles.fullSize,
    borderColor: Colors['layout.white'],
    backgroundColor: Colors['layout.white'],
  },
  lastContainer: {
    borderBottomWidth: 2,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  containerSelected: {
    borderColor: Colors['brand.action'],
  },
  containerHighlighted: {
    opacity: 1,
  },
  line: {
    height: 1,
    backgroundColor: convertHexToRGBA(Colors['layout.black'], 0.2),
  },
  descriptionText: {
    fontSize: 14,
    textAlign: 'center',
  },
})
