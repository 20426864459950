import React, { useMemo, useCallback } from 'react'
import { View, FlatList, StyleSheet, ScrollView } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import HeaderIconButton from '../../components/HeaderIconButton'
import {
  PersonInfo,
  PersonInfoItem,
  PersonInfoItemSeparator,
} from '../../components/PersonInfoItem'
import RootContainer from '../../components/RootContainer'
import Separator from '../../components/Separator'
import Typography from '../../components/Text/Typography'
import TouchableSvg from '../../components/TouchableSvg'
import {
  getTitleItems,
  getGenderItems,
  getCountriesItems,
} from '../../helpers/ProfileFormItems'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import { useLocale } from '../../providers/LocaleProvider'
import { useUser } from '../../providers/UserProvider'
import { useWebLayout } from '../../providers/WebLayoutProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import { MainStackNavigationType } from '../../types/navigation-types'
import { convertHexToRGBA } from '../../utils/colors'
import { formatDate } from '../../utils/date'

export default function ProfileSettingsViewScreen(): JSX.Element {
  const { goBack, navigate } =
    useNavigation<MainStackNavigationType<'ProfileSettingsView'>>()
  const { webLayoutEnabled } = useWebLayout()
  const { t } = useTranslation()
  const { user } = useUser()
  const locale = useLocale()

  const profileData: PersonInfo[] = useMemo(() => {
    const genderItems = getGenderItems(t)
    const countriesItems = getCountriesItems(t)
    const titleItems = getTitleItems(t)
    return [
      {
        label: t('profile.gender.title'),
        value: genderItems.find(g => g.key === user?.gender)?.label ?? '',
      },
      {
        label: t('profile.personTitle.title'),
        value: titleItems.find(ttl => ttl.key === user?.title)?.label ?? '',
      },
      { label: t('profile.email'), value: user?.email ?? '' },
      {
        label: t('profile.birthday'),
        value: user?.dateOfBirth
          ? formatDate(new Date(user.dateOfBirth), locale.locale)
          : '',
      },
      {
        label: t('profile.country.title'),
        value: countriesItems.find(c => c.key === user?.country)?.label ?? '',
      },
    ]
  }, [
    t,
    user?.email,
    user?.title,
    user?.gender,
    user?.country,
    locale.locale,
    user?.dateOfBirth,
  ])

  const onEditPress = useCallback(() => {
    if (user?.userType === 'LimitedUser') {
      return navigate('EditLimitedUser', { userId: user.id, asProfile: true })
    }

    navigate('ProfileSettingsEdit')
  }, [user, navigate])

  return (
    <RootContainer contentContainerStyle={styles.container}>
      <View
        style={[
          useSafeAreaPaddedStyle(styles.header, headerOptions),
          webLayoutEnabled ? styles.webHeaderModifier : appStyles.lightShadow,
        ]}>
        <View style={appStyles.inlineContainer}>
          <TouchableSvg name="back" color="layout.dark" onPress={goBack} />
          <Separator width={5} />
          <Typography weight="medium" style={styles.titleText}>
            {t('screens.settings.profileSettings.title')}
          </Typography>
        </View>
        <View style={appStyles.inlineContainer}>
          <HeaderIconButton iconName="pencil" onPress={onEditPress} />
          <Separator width={20} />
        </View>
      </View>
      <ScrollView
        style={appStyles.fullSize}
        alwaysBounceVertical={false}
        contentContainerStyle={useSafeAreaPaddedStyle(styles.contentContainer, {
          insetBottom: true,
        })}>
        <Separator height={30} />
        <View style={appStyles.inlineContainer}>
          {user && <Avatar gender={user?.gender} uri={user?.pictureUrl} />}
          <Separator width={20} />
          <View>
            <Typography style={styles.nameText}>{user?.firstName}</Typography>
            <Typography weight="medium" style={styles.surnameText}>
              {user?.lastName}
            </Typography>
          </View>
        </View>
        <Separator height={20} />
        <FlatList
          data={profileData}
          scrollEnabled={false}
          renderItem={PersonInfoItem}
          keyExtractor={item => item.label}
          ItemSeparatorComponent={PersonInfoItemSeparator}
        />
        <Separator height={30} />
        <Typography>
          {t('screens.settings.profileSettings.delete.followLink')}
        </Typography>
        <Separator height={18} />
        <Button
          type="secondary"
          text={t('screens.settings.profileSettings.delete.title')}
          style={styles.deleteAccountButton}
          onPress={() => navigate('DeleteAccount')}
        />
        <Separator height={10} />
      </ScrollView>
    </RootContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors['layout.light'],
  },
  header: {
    paddingTop: 15,
    paddingBottom: 24,
    paddingHorizontal: 24,
    ...appStyles.inlineContainer,
    justifyContent: 'space-between',
    backgroundColor: Colors['layout.white'],
    borderColor: convertHexToRGBA(Colors['layout.gray'], 0.5),
  },
  webHeaderModifier: { borderBottomWidth: 1 },
  titleText: {
    fontSize: 20,
    lineHeight: 24,
  },
  contentContainer: {
    flexGrow: 1,
    marginHorizontal: 25,
  },
  nameText: {
    fontSize: 16,
    lineHeight: 18,
  },
  surnameText: {
    fontSize: 22,
    lineHeight: 24,
  },
  deleteAccountButton: {
    padding: 5,
    maxWidth: 160,
    alignSelf: 'flex-start',
  },
})
