import React, { useMemo } from 'react'
import { View, StyleSheet } from 'react-native'

import { useNavigation, useRoute } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import HeaderButton from './HeaderButton'
import HeaderIconButton from './HeaderIconButton'
import Separator from './Separator'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../hooks/useSafeAreaPaddedStyle'
import AccountCard from '../navigation/AccountCard'
import { useMediaLibraryState } from '../providers/MediaLibraryProvider'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import { MainStackNavigationType } from '../types/navigation-types'

const TabHeader: React.FC = () => {
  const { t } = useTranslation()
  const { navigate } = useNavigation<MainStackNavigationType<'Root'>>()
  const { isSelecting, setIsSelecting } = useMediaLibraryState()
  const { name } = useRoute()

  const isMediaLibrary = useMemo(() => name === 'MediaLibrary', [name])

  return (
    <View style={useSafeAreaPaddedStyle(styles.container, headerOptions)}>
      <AccountCard hideDetails={isMediaLibrary} />
      {isMediaLibrary && (
        <>
          <HeaderButton
            secondary
            text={
              isSelecting
                ? t('common.cancel')
                : t('screens.mediaLibrary.select')
            }
            onPress={() => setIsSelecting(c => !c)}
          />
          <Separator width={10} />
        </>
      )}
      <HeaderIconButton iconName="gear" onPress={() => navigate('Settings')} />
    </View>
  )
}

export default TabHeader

const TAB_HEADER_HEIGHT = 123
const styles = StyleSheet.create({
  container: {
    paddingTop: 10,
    paddingBottom: 19,
    paddingHorizontal: 24,
    ...appStyles.lightShadow,
    ...appStyles.inlineContainer,
    minHeight: TAB_HEADER_HEIGHT,
    backgroundColor: Colors['layout.white'],
  },
})
