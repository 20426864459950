import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react'
import {
  View,
  Modal,
  Keyboard,
  Animated,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native'

import {
  useRoute,
  RouteProp,
  StackActions,
  useNavigation,
} from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import {
  Memory,
  LifeStage,
  Attachment,
  MemoryInput,
  MemoryTypeEnum,
  useGetMemoryQuery,
  GetMemoryDocument,
  DatePrecisionEnum,
  AttachmentAttachable,
  useGetMemoryPeopleQuery,
  useCreateMemoryMutation,
  useUpdateMemoryMutation,
  useDeleteMemoryMutation,
  GetMemoryPeopleDocument,
} from '../../api/types'
import { AudioItem, AudioList } from '../../components/AudioList'
import Button from '../../components/Button'
import DestructionConfirmationDialog from '../../components/DestructionConfirmationDialog'
import HeaderButton from '../../components/HeaderButton'
import Loading from '../../components/Loading'
import { MediaList } from '../../components/MediaList'
import ModalFooter from '../../components/ModalFooter'
import ModalHeader from '../../components/ModalHeader'
import Separator from '../../components/Separator'
import SvgIcon from '../../components/SvgIcon'
import Typography, { TypographyInput } from '../../components/Text/Typography'
import WebModal from '../../components/WebModal'
import loggingCore from '../../core/logging-core'
import toISO8601DateString from '../../helpers/toISO8601DateString'
import { useAudioActionSheet } from '../../hooks/useAudioActionSheet'
import useIsUnsaved, { isArrayEqual } from '../../hooks/useIsUnsaved'
import { useListState } from '../../hooks/useListState'
import { useMediaActionSheet } from '../../hooks/useMediaActionSheet'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import useShowRemoveMediaActionSheet from '../../hooks/useShowRemoveMediaActionSheet'
import useStateRef from '../../hooks/useStateRef'
import useUnsavedChanges from '../../hooks/useUnsavedChanges'
import useUploadAttachments, {
  AttachmentNodes,
} from '../../hooks/useUploadAttachments'
import { AudioDescriptionModal } from '../../modals/AudioDescriptionModal'
import { MemoryTypePickerModal } from '../../modals/MemoryTypePickerModal'
import { useBanner } from '../../providers/BannerProvider'
import { useLocale } from '../../providers/LocaleProvider'
import { useSetShouldRefetch, QueryKey } from '../../providers/RefetchProvider'
import { useWebLayout } from '../../providers/WebLayoutProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import { Locale } from '../../types/localization-types'
import { MediaItem } from '../../types/media-types'
import {
  MainStackParamsType,
  MainStackNavigationType,
} from '../../types/navigation-types'
import { convertHexToRGBA } from '../../utils/colors'
import { formatDate } from '../../utils/date'
import errorIsLimitReached from '../../utils/errorIsLimitReached'
import { FilterType } from '../media-library/MediaLibraryScreen'
import { MAX_PEOPLE_ROW_COUNT } from '../memory-details/MemoryDetailsScreen'
import MemoryTypeSvg from '../timeline/components/MemoryTypeSvg'

type MemoryDate = {
  __typename?: undefined
  timestamp: number
  prec: DatePrecisionEnum
}
export type EventAtType = LifeStage | MemoryDate | null

type InputItem = 'heading' | 'description' | 'memoryType'

type EventAtMemoryInputType = Pick<
  MemoryInput,
  'eventAt' | 'eventAtPrec' | 'lifeStageId'
>

function getUpdateRefetchQueries(
  eventAtInput: EventAtMemoryInputType,
  editMemory?: Pick<Memory, 'eventAt' | 'eventAtPrec' | 'lifeStage'>,
) {
  const refetchTimeline = !editMemory
    ? eventAtInput.eventAt || eventAtInput.lifeStageId
    : editMemory.eventAt !== eventAtInput.eventAt ||
      (editMemory.eventAtPrec ?? null) !== eventAtInput.eventAtPrec ||
      (editMemory.lifeStage?.id ?? null) !== eventAtInput.lifeStageId

  const refetchOtherMemories = !editMemory
    ? !refetchTimeline
    : (!editMemory.eventAt && !editMemory.lifeStage?.id) !==
      (!eventAtInput.eventAt && !eventAtInput.lifeStageId)

  return { refetchTimeline, refetchOtherMemories }
}

function formatMemoryDate(
  memoryDate: MemoryDate,
  locale: Locale | undefined,
): string {
  const { timestamp, prec } = memoryDate
  return formatDate(new Date(timestamp), locale, prec)
}

/* eslint-disable react/jsx-max-depth */
export default function MemoryFormScreen(): JSX.Element {
  const setShouldRefetch = useSetShouldRefetch()
  const { params, key } =
    useRoute<RouteProp<MainStackParamsType, 'MemoryForm'>>()
  const { goBack, navigate, setParams, dispatch, pop } =
    useNavigation<MainStackNavigationType<'MemoryForm'>>()
  const { t } = useTranslation()
  const { locale } = useLocale()
  const showBanner = useBanner()

  const isEditing = !!params.editMemoryId

  const { data: editMemoryData, loading } = useGetMemoryQuery({
    skip: !isEditing,
    fetchPolicy: 'network-only',
    variables: { id: params.editMemoryId!, personCount: MAX_PEOPLE_ROW_COUNT },
  })

  const { data: initialTaggedPeople } = useGetMemoryPeopleQuery({
    skip: !isEditing,
    variables: { id: params.editMemoryId! },
    onCompleted: ({ memory: { people } }) =>
      !isArrayEqual(params.taggedPeople, people?.nodes) &&
      setParams({ taggedPeople: people?.nodes }),
  })

  const [oldMediaList, setOldMedia] = useState<AttachmentNodes>(
    params.attachments?.media ?? [],
  )

  const [
    mediaDescriptions,
    _pushDescription,
    popMediaDescription,
    setMediaDescriptions,
  ] = useListState<AttachmentAttachable>(
    params?.attachmentDescriptions ?? [],
    (a, b) => a.attachmentId === b.attachmentId,
  )

  useEffect(
    () => setOldMedia(params.attachments?.media ?? []),
    [params.attachments?.media],
  )

  const addFromRelefantLibrary = (availableFilters: FilterType[]) => {
    navigate('MediaLibraryPicker', {
      routeKey: key,
      availableFilters,
    })
  }

  const [
    newMediaList,
    addMedia,
    showMediaOptions,
    setNewMedia,
    isMediaLoading,
  ] = useMediaActionSheet(false, () =>
    addFromRelefantLibrary(['all', 'picture', 'video']),
  )
  const mediaList: MediaItem[] = useMemo(
    () => [
      ...newMediaList.map(asset => ({
        __typename: 'NewAttachment' as const,
        id: asset.uri ?? '',
        url: asset.uri,
        contentType: asset.type,
        signedBlobId: null,
        description: asset.description,
      })),
      ...oldMediaList,
    ],
    [oldMediaList, newMediaList],
  )
  const [oldAudioList, setOldAudios] = useState<AttachmentNodes>(
    params.attachments?.audio ?? [],
  )

  useEffect(
    () => setOldAudios(params.attachments?.audio ?? []),
    [params.attachments?.audio],
  )

  useEffect(() => {
    setParams({
      attachments: {
        media: [...(params.addedAttachments?.media ?? []), ...oldMediaList],
        audio: [...(params.addedAttachments?.audio ?? []), ...oldAudioList],
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.addedAttachments])

  const [
    newAudioList,
    addAudio,
    showAudioOptions,
    addRecordedAudio,
    setNewAudioList,
  ] = useAudioActionSheet(
    false,
    () =>
      navigate('AudioRecorder', {
        routeKey: key,
      }),
    () => addFromRelefantLibrary(['audio']),
  )
  const audioList = useMemo(
    () => [
      ...newAudioList.map(asset => ({
        __typename: 'NewAttachment' as const,
        asset,
      })),
      ...oldAudioList,
    ],
    [oldAudioList, newAudioList],
  )

  const [audioToRename, setAudioToRename] = useState<AudioItem>()

  useEffect(() => {
    if (params.recordedAudio) addRecordedAudio(params.recordedAudio)
  }, [params.recordedAudio, addRecordedAudio])

  const showRemoveMediaActionSheet = useShowRemoveMediaActionSheet()
  const uploadAttachments = useUploadAttachments()

  const [isSaving, setIsSaving, getIsSaving] = useStateRef(false)
  const [focus, setFocus] = useState<InputItem>()
  const [errors, setErrors] = useState<InputItem[]>()

  const onFocus = useCallback((item: InputItem) => {
    setFocus(item)
    setErrors(current => current?.filter(missingItem => missingItem !== item))
  }, [])
  const [isMemoryTypePickerVisible, setIsMemoryTypePickerVisible] =
    useState<boolean>(false)
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] =
    useState<boolean>(false)

  useEffect(() => {
    if (editMemoryData && params.eventAt === undefined) {
      const memory = editMemoryData.memory
      const eventAt: EventAtType | undefined = memory.eventAt
        ? {
            timestamp: new Date(memory.eventAt).valueOf(),
            prec: memory.eventAtPrec ?? DatePrecisionEnum.Day,
          }
        : memory.lifeStage || undefined
      if (eventAt) {
        setParams({ eventAt })
      }
    }
  }, [params, setParams, editMemoryData])

  const eventAt = params.eventAt
  const [heading, setHeading] = useState<string>('')
  const [memoryDescription, setMemoryDescription] = useState<string>('')
  const [memoryType, setMemoryType] = useState<MemoryTypeEnum>(
    MemoryTypeEnum.Memory,
  )
  const [highlightedPictureId, setHighlightedPictureId] = useState<
    string | null
  >()
  const [highlightedPictureUri, setHighlightedPictureUri] = useState<string>()
  const [isInitialStateSet, setIsInitialStateSet] = useState<boolean>(
    !isEditing,
  )

  useEffect(() => {
    if (!editMemoryData?.memory) return
    const { memory } = editMemoryData

    setHeading(memory.memoryTitle)
    setMemoryType(memory.memoryType)
    setMemoryDescription(memory.memoryBody ?? '')

    const media: Attachment[] = []
    const audio: Attachment[] = []
    memory.attachments?.nodes?.forEach(attachment => {
      if (!attachment.contentType || !attachment.url) return

      if (attachment.contentType.startsWith('audio')) {
        audio.push(attachment)
      } else {
        media.push(attachment)
      }
    })
    setOldMedia(media)
    setOldAudios(audio)
    setParams({
      attachments: { audio, media },
      attachmentDescriptions: memory.attachmentAttachables?.nodes ?? [],
    })
    setIsInitialStateSet(true)
  }, [editMemoryData, setParams])

  const shouldSkipUnsavedCheck = useRef(false)
  const isUnsaved = useIsUnsaved(
    [heading, memoryType, memoryDescription, oldMediaList, oldAudioList],
    { isReady: isInitialStateSet },
  )

  useUnsavedChanges(
    isUnsaved ||
      newAudioList.length > 0 ||
      newMediaList.length > 0 ||
      !isArrayEqual(
        params.taggedPeople,
        initialTaggedPeople?.memory.people?.nodes,
      ),
    () => shouldSkipUnsavedCheck.current,
  )

  const [createMemory, { client }] = useCreateMemoryMutation()
  const [updateMemory] = useUpdateMemoryMutation()
  const [deleteMemory] = useDeleteMemoryMutation()

  const onSavePress = () => {
    if (getIsSaving()) return

    const missingInputs: InputItem[] = []

    if (!heading) missingInputs.push('heading')
    if (missingInputs.length) {
      return setErrors(missingInputs)
    }

    setIsSaving(true)

    const eventAtInput: EventAtMemoryInputType = {
      eventAt: null,
      eventAtPrec: null,
      lifeStageId: null,
    }
    if (params.eventAt) {
      if (params.eventAt.__typename === 'LifeStage') {
        eventAtInput.lifeStageId = params.eventAt.id
      } else {
        const { timestamp, prec } = params.eventAt as MemoryDate
        eventAtInput.eventAt = toISO8601DateString(new Date(timestamp))
        eventAtInput.eventAtPrec = prec
      }
    }

    const { refetchTimeline, refetchOtherMemories } = getUpdateRefetchQueries(
      eventAtInput,
      editMemoryData?.memory,
    )

    uploadAttachments(
      newMediaList,
      newAudioList,
      params.attachments?.audio ?? [],
      oldAudioList,
      params.attachments?.media ?? [],
      oldMediaList,
      mediaDescriptions,
    )
      .then(attachments => {
        const highlightedPictureIndex = newMediaList.findIndex(
          media => media.uri === highlightedPictureUri,
        )

        const input = {
          memoryTitle: heading,
          memoryType,
          memoryBody: memoryDescription,
          pictureBlobId:
            highlightedPictureId ??
            attachments[
              highlightedPictureIndex < 0 && !isEditing
                ? 0
                : highlightedPictureIndex
            ]?.fileBlobId,
          ...eventAtInput,
        }

        const peopleIds = params.taggedPeople?.map(({ id }) => id)

        return isEditing
          ? updateMemory({
              variables: {
                id: params.editMemoryId,
                input,
                peopleIds,
                attachments,
                personCount: MAX_PEOPLE_ROW_COUNT,
                // @ts-ignore
                attachableDescription: mediaDescriptions.map(d => ({
                  attachmentId: d.attachmentId,
                  attachableDescription: d.attachableDescription,
                })),
              },
            }).then(({ data }) => data?.updateMemory)
          : createMemory({
              variables: {
                input,
                peopleIds,
                attachments,
                personCount: MAX_PEOPLE_ROW_COUNT,
              },
            }).then(({ data }) => data?.createMemory)
      })
      .then(memory => {
        client.cache.writeQuery({
          query: GetMemoryDocument,
          variables: { id: memory?.id },
          data: { memory },
        })
        client.cache.writeQuery({
          query: GetMemoryPeopleDocument,
          variables: { id: memory?.id },
          data: { memory },
        })
        client.cache.evict({
          fieldName: 'library',
        })
        if (refetchOtherMemories) {
          setShouldRefetch(QueryKey.OtherMemories)
        }
        refetchTimeline && setShouldRefetch(QueryKey.Memories)
        shouldSkipUnsavedCheck.current = true
        dispatch(
          StackActions.replace('MemoryDetails', {
            memoryId: memory?.id,
            isCommon: false,
          }),
        )
        if (params.editMemoryId) pop()
      })
      .catch(e => {
        if (errorIsLimitReached(e)) {
          showBanner({
            icon: 'alert',
            color: 'brand.red',
            description: t(
              `banner.dataLimitReached.${
                Platform.OS === 'web' ? 'web' : 'mobile'
              }`,
            ),
          })
        } else {
          loggingCore.error(e)
        }
      })
      .finally(() => setIsSaving(false))
  }

  const onDeleteConfirmed = () => {
    if (getIsSaving() || !isEditing) return

    setIsSaving(true)
    deleteMemory({
      variables: { id: params.editMemoryId },
      update(cache) {
        const normalizedId = cache.identify({
          __typename: 'Memory',
          id: params?.editMemoryId,
        })
        cache.evict({ id: normalizedId })
        cache.gc()
      },
    })
      .then(() => {
        shouldSkipUnsavedCheck.current = true
        pop(2)
      })
      .finally(() => setIsSaving(false))
  }
  const onDeletePress = () => setIsDeleteConfirmationVisible(true)

  const dismissDeleteConfirmation = () => setIsDeleteConfirmationVisible(false)

  const getOnBlur = useCallback(
    (inputItem: InputItem) => () =>
      setFocus(current => (current === inputItem ? undefined : current)),
    [],
  )

  const showMediaActions = (
    item: MediaItem,
    extraAction?: { text: string; action: () => void },
  ) =>
    item.__typename === 'NewAttachment'
      ? showMediaOptions(
          item,
          {
            text: t('screens.createMemory.imageOptions.highlight'),
            action: () => {
              setHighlightedPictureUri(item.url ?? undefined)
              setHighlightedPictureId(undefined)
            },
          },
          extraAction,
        )
      : showRemoveMediaActionSheet(
          item.contentType?.includes('image') ? 'image' : 'video',
          () => {
            setOldMedia(c => c.filter(({ id }) => id !== item.id))
            popMediaDescription({ attachmentId: item.id })
          },
          (item.contentType?.includes('image')
            ? [
                {
                  text: t('screens.createMemory.imageOptions.highlight'),
                  action: () => {
                    setHighlightedPictureUri(undefined)
                    setHighlightedPictureId(item.signedBlobId)
                  },
                },
              ]
            : []
          ).concat(extraAction ?? []),
        )

  const showAudioActions = (item: AudioItem) =>
    item.__typename === 'NewAttachment'
      ? showAudioOptions(item.asset, () => setAudioToRename(item))
      : showRemoveMediaActionSheet(
          'audio',
          () => {
            setOldAudios(c => c.filter(({ id }) => id !== item.id))
            popMediaDescription({ attachmentId: item.id })
          },
          [
            {
              text: t('screens.createMemory.audioOptions.rename'),
              action: () => setAudioToRename(item),
            },
          ],
        )

  const renameAudio = (item: AudioItem, description: string | undefined) => {
    if (item.__typename === 'NewAttachment') {
      setNewAudioList(prev =>
        prev.map(a =>
          a.fileCopyUri === item.asset.fileCopyUri ? { ...a, description } : a,
        ),
      )
    } else {
      setOldAudios(prev =>
        prev.map(a => (a.id === item.id ? { ...a, description } : a)),
      )
    }
  }

  const memoryTypePickerAnimation = useRef(new Animated.Value(0)).current
  const toggleMemoryTypePicker = useCallback(
    (open: boolean) =>
      open
        ? () => {
            Animated.timing(memoryTypePickerAnimation, {
              toValue: 1,
              useNativeDriver: true,
            }).start()
            onFocus('memoryType')
            Keyboard.dismiss()
            setIsMemoryTypePickerVisible(true)
          }
        : () => {
            Animated.timing(memoryTypePickerAnimation, {
              toValue: 0,
              useNativeDriver: true,
            }).start()
            setIsMemoryTypePickerVisible(false)
            setFocus(undefined)
          },
    [memoryTypePickerAnimation, onFocus],
  )

  const appHeaderStyle = useSafeAreaPaddedStyle(styles.header, headerOptions)

  const { webLayoutEnabled } = useWebLayout()

  const paddedStyle = useMemo(
    () => ({ paddingHorizontal: webLayoutEnabled ? 30 : 25 }),
    [webLayoutEnabled],
  )

  const titleText = t(
    params?.editMemoryId
      ? 'screens.editMemory.title'
      : 'screens.createMemory.title',
  )

  return (
    <WebModal>
      <Loading blocking loading={isSaving || loading} />
      {webLayoutEnabled ? (
        <ModalHeader title={titleText} close={goBack} />
      ) : (
        <View style={appHeaderStyle}>
          <HeaderButton secondary text={t('common.cancel')} onPress={goBack} />
          <HeaderButton text={t('common.save')} onPress={onSavePress} />
        </View>
      )}
      <KeyboardAvoidingView
        style={appStyles.fullSize}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        <ScrollView
          style={appStyles.fullSize}
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={useSafeAreaPaddedStyle(
            styles.scrollContentContainer,
            { insetBottom: true },
          )}>
          <Separator height={32} />
          <View style={paddedStyle}>
            <TouchableOpacity
              style={[
                appStyles.inlineContainer,
                webLayoutEnabled && styles.webEventAtRow,
              ]}
              onPress={() => {
                navigate('MemoryTimePicker', {
                  editing: isEditing,
                  intialTimestamp:
                    params.eventAt?.__typename === 'LifeStage'
                      ? new Date(params.eventAt.startYear!, 0, 1).valueOf()
                      : params.eventAt
                      ? (params.eventAt as MemoryDate).timestamp
                      : undefined,
                  initialPrecision: (params.eventAt as MemoryDate)?.prec,
                })
              }}>
              <Separator width={19} />
              <Typography weight="medium" style={styles.eventAtText}>
                {!eventAt
                  ? t('screens.createMemory.noSpecificTime')
                  : eventAt.__typename === 'LifeStage'
                  ? t('screens.timeline.lifeStageMemory', {
                      lifeStage: eventAt.title,
                    })
                  : formatMemoryDate(eventAt as MemoryDate, locale)}
              </Typography>
              <Separator width={10} />
              <View style={styles.eventAtTextEditButton}>
                <SvgIcon name="pencil" color={Colors['layout.dark']} />
              </View>
              <Separator width={13} />
            </TouchableOpacity>
            <Separator height={31} />
            <TypographyInput
              weight="medium"
              style={[
                styles.textInput,
                styles.thinInput,
                focus === 'heading' && styles.focusedInput,
                errors?.includes('heading') && styles.missingInput,
              ]}
              maxLength={100}
              value={heading}
              placeholder={t('screens.createMemory.enterHeading')}
              placeholderTextColor={placeholderTextColor}
              onFocus={() => onFocus('heading')}
              onBlur={getOnBlur('heading')}
              onChangeText={setHeading}
            />
            <Separator height={12} />
            <TypographyInput
              multiline
              weight="medium"
              style={[
                styles.textInput,
                styles.descriptionInput,
                focus === 'description' && styles.focusedInput,
              ]}
              value={memoryDescription}
              placeholder={t('screens.createMemory.writeStory')}
              placeholderTextColor={placeholderTextColor}
              onFocus={() => setFocus('description')}
              onBlur={getOnBlur('description')}
              onChangeText={setMemoryDescription}
            />
            <Separator height={12} />
            <TouchableOpacity
              style={[
                styles.textInput,
                styles.thinInput,
                styles.inputButton,
                focus === 'memoryType' && styles.focusedInput,
              ]}
              onPress={toggleMemoryTypePicker(true)}>
              <View style={styles.memoryTypeHolder}>
                <View style={styles.memoryTypeIconHolder}>
                  <MemoryTypeSvg type={memoryType} />
                </View>
                <Separator width={10} />
                <Typography weight="medium" style={styles.memoryTypeName}>
                  {t(`modals.memoryTypePicker.memoryType.${memoryType}`)}
                </Typography>
              </View>
              <Animated.View
                style={{
                  transform: [
                    {
                      rotate: memoryTypePickerAnimation.interpolate({
                        inputRange: [0, 1],
                        outputRange: ['0deg', '180deg'],
                      }),
                    },
                  ],
                }}>
                <SvgIcon
                  name="chevron"
                  color={Colors['layout.dark']}
                  opacity={0.5}
                />
              </Animated.View>
            </TouchableOpacity>
            <Separator height={12} />
            <TouchableOpacity
              style={[styles.textInput, styles.thinInput, styles.inputButton]}
              onPress={() =>
                navigate('PeoplePicker', { taggedPeople: params.taggedPeople })
              }>
              <Typography
                weight="light"
                style={[
                  styles.placeholderText,
                  !!params.taggedPeople?.length && styles.taggedNames,
                ]}>
                {!params.taggedPeople?.length
                  ? t('screens.createMemory.tagPerson')
                  : params.taggedPeople
                      .slice(0, 2)
                      .map(({ firstName, lastName }) =>
                        lastName ? `${firstName} ${lastName}` : firstName,
                      )
                      .join(', ')}
                {/* eslint-disable-next-line  @typescript-eslint/no-non-null-asserted-optional-chain */}
                {params.taggedPeople?.length! > 2 &&
                  t('screens.createMemory.plusMore', {
                    n: params.taggedPeople!.length - 2,
                  })}
              </Typography>
              <View style={styles.cheveronRight}>
                <SvgIcon
                  name="chevron"
                  color={Colors['layout.dark']}
                  opacity={0.5}
                />
              </View>
            </TouchableOpacity>
          </View>
          <Separator height={34} />
          <View>
            <MediaList
              canEdit
              isMediaLoading={isMediaLoading}
              media={mediaList}
              mediaDescriptions={mediaDescriptions}
              galleryTitle={heading}
              showMediaActions={showMediaActions}
              setNewMedia={setNewMedia}
              setMediaDescriptions={setMediaDescriptions}
              addMedia={addMedia}
            />
            <Separator height={34} />
            <View style={paddedStyle}>
              <AudioList
                canEdit
                audio={audioList}
                addAudio={addAudio}
                showAudioActions={showAudioActions}
              />
            </View>
          </View>
          <AudioDescriptionModal
            audio={audioToRename}
            saveDescription={renameAudio}
            close={() => setAudioToRename(undefined)}
          />

          {isEditing && (
            <>
              <Separator height={34} />
              <Button
                text={t('screens.editMemory.deleteMemory')}
                type="destructive"
                style={styles.deleteButton}
                onPress={onDeletePress}
              />
            </>
          )}
          <MemoryTypePickerModal
            visible={isMemoryTypePickerVisible}
            setSelected={setMemoryType}
            selected={memoryType}
            close={toggleMemoryTypePicker(false)}
          />
        </ScrollView>
      </KeyboardAvoidingView>
      {webLayoutEnabled && (
        <ModalFooter>
          <Button
            text={t('common.save')}
            style={styles.webSaveButton}
            onPress={onSavePress}
          />
        </ModalFooter>
      )}
      <Modal
        transparent
        statusBarTranslucent
        animationType="fade"
        visible={isDeleteConfirmationVisible}
        onRequestClose={dismissDeleteConfirmation}>
        <DestructionConfirmationDialog
          confirmText={t('common.delete')}
          title={t('screens.editMemory.deleteMemory')}
          subtitle={t('screens.editMemory.deleteMemoryDescripion')}
          close={dismissDeleteConfirmation}
          onConfirm={onDeleteConfirmed}
        />
      </Modal>
    </WebModal>
  )
}
/* eslint-enable react/jsx-max-depth */

const placeholderTextColor = convertHexToRGBA(Colors['layout.dark'], 0.5)
const styles = StyleSheet.create({
  scrollContentContainer: {
    flexGrow: 1,
    paddingBottom: 30,
  },
  header: {
    width: '100%',
    paddingTop: 15,
    paddingBottom: 14,
    paddingHorizontal: 25,
    ...appStyles.lightShadow,
    ...appStyles.inlineContainer,
    justifyContent: 'space-between',
    backgroundColor: Colors['layout.white'],
  },
  webEventAtRow: { marginRight: 'auto' },
  eventAtText: {
    flex: 1,
    fontSize: 16,
  },
  eventAtTextEditButton: {
    height: 40,
    width: 40,
    ...appStyles.center,
  },
  textInput: {
    fontSize: 16,
    borderRadius: 14,
    paddingVertical: 7,
    paddingHorizontal: 20,
    ...appStyles.lightShadow,
    backgroundColor: Colors['layout.white'],
  },
  thinInput: {
    height: 54,
  },
  focusedInput: {
    borderWidth: 1,
    borderColor: Colors['brand.action'],
  },
  missingInput: {
    borderWidth: 1,
    borderColor: Colors['brand.red'],
  },
  descriptionInput: {
    minHeight: 120,
    maxHeight: 500,
    textAlignVertical: 'top',
  },
  inputButton: {
    ...appStyles.center,
    ...appStyles.inlineContainer,
  },
  memoryTypeHolder: { flex: 1, ...appStyles.inlineContainer },
  memoryTypeIconHolder: {
    width: 34,
    height: 34,
    marginLeft: -10,
    borderRadius: 10,
    ...appStyles.center,
    backgroundColor: Colors['layout.gray'],
  },
  memoryTypeName: {
    fontSize: 15,
  },
  placeholderText: {
    flex: 1,
    fontSize: 14,
    color: placeholderTextColor,
  },
  taggedNames: {
    color: Colors['layout.dark'],
  },
  cheveronRight: { transform: [{ rotate: '-90deg' }] },
  deleteButton: {
    marginTop: 'auto',
  },
  webSaveButton: { maxWidth: 580 },
})
