import React, { useEffect, useRef, useCallback } from 'react'
import { View, StyleSheet, ScrollView } from 'react-native'

import { useTranslation } from 'react-i18next'

import { SubscriptionsSummary } from './components/SubscriptionsSummary'
import Button from '../../components/Button'
import Separator from '../../components/Separator'
import Typography from '../../components/Text/Typography'
import TouchableSvg from '../../components/TouchableSvg'
import UserStorageInfo from '../../components/UserStorageInfo'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import { SubscriptionsScreenProps } from '../../types/SubscriptionScreen.types'

export const SuscriptionsScreenMobileSummary: React.FC<
  SubscriptionsScreenProps
> = props => {
  const { setStep, unsubscribe, currentPlanCode, setTermsAccepted } = props
  const { t } = useTranslation(undefined, {
    keyPrefix: 'screens.settings.subscriptions',
  })
  const scrollViewRef = useRef<ScrollView>(null)

  useEffect(() => {
    scrollViewRef.current?.scrollTo({ y: 0, animated: true })
  }, [])

  const onBackPress = useCallback(() => {
    setStep('storage')
    setTermsAccepted(false)
  }, [setStep, setTermsAccepted])

  return (
    <ScrollView
      ref={scrollViewRef}
      style={styles.container}
      contentContainerStyle={styles.scrollContentContainer}>
      <View style={styles.header}>
        <TouchableSvg name="back" color="layout.dark" onPress={onBackPress} />
        <Separator width={5} />
        <Typography weight="medium" style={styles.headerText}>
          {t('title')}
        </Typography>
      </View>
      <Separator height={20} />
      <View style={styles.contentContainer}>
        <SubscriptionsSummary
          {...props}
          addonTypes={['duration', 'members', 'storage']}
        />
        {currentPlanCode && (
          <>
            <Separator height={50} />
            <Typography>{t('cancel.followLink')}</Typography>
            <Separator height={18} />
            <Button
              type="secondary"
              text={t('cancel.title')}
              style={styles.unsubscribeButton}
              onPress={unsubscribe}
            />
          </>
        )}
      </View>
      <Separator height={50} />
      <UserStorageInfo />
      <Separator height={40} />
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    ...appStyles.fullSize,
    backgroundColor: Colors['layout.light'],
  },
  scrollContentContainer: {
    height: 0,
    flexGrow: 1,
  },
  header: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingBottom: 24,
    ...appStyles.lightShadow,
    ...appStyles.inlineContainer,
    backgroundColor: Colors['layout.white'],
  },
  headerText: {
    fontSize: 20,
    lineHeight: 24,
  },
  contentContainer: {
    paddingHorizontal: 25,
  },
  unsubscribeButton: {
    padding: 5,
    maxWidth: 130,
  },
})
