import React, { useRef, useMemo } from 'react'
import { View, ScrollView, StyleSheet, useWindowDimensions } from 'react-native'

import { useRoute, RouteProp, useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { ActiveMembershipCard } from './components/ActiveMembershipCard'
import {
  PassiveMembershipCard,
  PassiveMembershipEmptyCard,
} from './components/PassiveMembershipCard'
import { SubscriptionsSummary } from './components/SubscriptionsSummary'
import { SUBSCRIPTIONS_CONTENT_MIN_WIDTH } from './SubscriptionsScreen'
import Button from '../../components/Button'
import Separator from '../../components/Separator'
import TitleText from '../../components/Text/TitleText'
import Typography from '../../components/Text/Typography'
import { ToggleButton } from '../../components/ToggleButton'
import TouchableSvg from '../../components/TouchableSvg'
import UserStorageInfo from '../../components/UserStorageInfo'
import Drawer from '../../navigation/Drawer'
import { SIDE_COLUMN_WIDTH } from '../../providers/WebLayoutProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import {
  MainStackParamsType,
  MainStackNavigationType,
} from '../../types/navigation-types'
import {
  AddonType,
  SubscriptionsScreenProps,
} from '../../types/SubscriptionScreen.types'
import { convertHexToRGBA } from '../../utils/colors'

export const SubscriptionsScreenWeb: React.FC<
  SubscriptionsScreenProps
> = props => {
  const {
    plans,
    showAnnual,
    selectAddon,
    unsubscribe,
    selectedPlan,
    setShowAnnual,
    selectedAddons,
    setSelectedPlan,
    currentPlanCode,
    setTermsAccepted,
    currentAddonCodes,
    resetSelectedPlan,
    forGroupAccountType,
  } = props
  const dimensions = useWindowDimensions()
  const { goBack } = useNavigation<MainStackNavigationType<'Subscriptions'>>()
  const { params } = useRoute<RouteProp<MainStackParamsType, 'Subscriptions'>>()
  const scrollViewRef = useRef<ScrollView>(null)
  const { t } = useTranslation(undefined, {
    keyPrefix: 'screens.settings.subscriptions',
  })

  const showDrawer =
    dimensions.width >= SUBSCRIPTIONS_CONTENT_MIN_WIDTH + SIDE_COLUMN_WIDTH

  const addonTypes: AddonType[] = useMemo(
    () =>
      forGroupAccountType ? ['duration', 'members', 'storage'] : ['duration'],
    [forGroupAccountType],
  )

  const renderSection = (addonType: AddonType, sectionIndex: number) => {
    const isLast = sectionIndex === addonTypes.length - 1
    const translationKey =
      addonType === 'duration' ? 'passiveMembership' : addonType
    const currentAddonCode = currentAddonCodes[addonType]
    const selectedAddon = selectedAddons[addonType]

    return (
      <View key={addonType}>
        <Separator height={30} />
        <TitleText size="medium" color="layout.dark" style={styles.titleText}>
          {t(`${translationKey}.title`)}
        </TitleText>
        <Separator height={10} />
        <Typography weight="light" style={styles.descriptionText}>
          {t(`${translationKey}.description`)}
        </Typography>
        <Separator height={10} />
        <View style={appStyles.row}>
          <View style={[appStyles.fullSize, appStyles.row]}>
            <PassiveMembershipEmptyCard
              isLast={isLast}
              addonType={addonType}
              showActive={!currentPlanCode && !selectedPlan}
              forGroupAccountType={forGroupAccountType}
            />
            <Separator width={20} />
          </View>
          {/* NOTE: First plan is free and it's addons are rendered with PassiveMembershipEmptyCard above */}
          {/* NOTE: We don't support more than 4 cards/plans */}
          {plans.slice(1, 4).map((plan, index) => (
            <View key={plan.id} style={[appStyles.fullSize, appStyles.row]}>
              {index !== 0 && <Separator width={20} />}
              <PassiveMembershipCard
                plan={plan}
                isLast={isLast}
                addonType={addonType}
                selectedPlan={selectedPlan}
                currentAddonCode={currentAddonCode}
                selectedAddon={selectedAddon}
                forGroupAccountType={forGroupAccountType}
                isCurrent={plan.planCode === currentPlanCode}
                selectAddon={selectAddon}
              />
            </View>
          ))}
        </View>
        <Separator height={30} />
      </View>
    )
  }

  const isSelectionChanged =
    (selectedPlan && selectedPlan?.planCode !== (currentPlanCode ?? '')) ||
    addonTypes.find(
      type =>
        selectedAddons[type]?.addonCode !== (currentAddonCodes[type] ?? ''),
    )

  return (
    <View style={styles.container}>
      {showDrawer && <Drawer />}
      <ScrollView
        ref={scrollViewRef}
        style={[
          styles.scrollContainer,
          {
            maxWidth: showDrawer ? SUBSCRIPTIONS_CONTENT_MAX_WIDTH : undefined,
          },
        ]}
        contentContainerStyle={styles.scrollContentContainer}>
        <View style={styles.header}>
          <TouchableSvg name="back" color="layout.dark" onPress={goBack} />
          <Separator width={5} />
          <Typography weight="medium" style={styles.headerText}>
            {t('title')}
          </Typography>
        </View>
        <View style={styles.contentContainer}>
          <Separator height={26} />
          <View style={styles.planSwitcherContainer}>
            <TitleText
              size="medium"
              color="layout.dark"
              style={styles.titleText}>
              {t(
                params?.personalUpgrade
                  ? 'choosePlanPersonalUpgrade'
                  : 'choosePlan',
              )}
            </TitleText>
            <Separator height={20} />
            <ToggleButton
              value={showAnnual}
              setValue={setShowAnnual}
              firstOptionText={t('annual')}
              secondOptionText={t('monthly')}
            />
          </View>
          <Separator height={30} />
          <TitleText size="medium" color="layout.dark" style={styles.titleText}>
            {t('activeMembership.title')}
          </TitleText>
          <Separator height={10} />
          <Typography weight="light" style={styles.descriptionText}>
            {t('activeMembership.description')}
          </Typography>
          <Separator height={10} />
          <View style={appStyles.row}>
            {plans.map((plan, index) => (
              <View key={plan.id} style={[appStyles.fullSize, appStyles.row]}>
                {index !== 0 && <Separator width={20} />}
                <ActiveMembershipCard
                  plan={plan}
                  isSelected={plan.id === selectedPlan?.id}
                  currentPlanCode={currentPlanCode}
                  setSelectedPlan={selected => {
                    selected ? setSelectedPlan(selected) : resetSelectedPlan()
                    selected &&
                      setTimeout(() => scrollViewRef.current?.scrollToEnd(), 50)
                    setTermsAccepted(false)
                  }}
                />
              </View>
            ))}
          </View>
          {addonTypes.map(renderSection)}
          {isSelectionChanged && (
            <>
              <SubscriptionsSummary
                {...props}
                withDottedLine
                addonTypes={addonTypes}
              />
              <Separator height={18} />
            </>
          )}
          {currentPlanCode && (
            <>
              <Separator height={40} />
              <Typography>{t('cancel.followLink')}</Typography>
              <Separator height={18} />
              <Button
                type="secondary"
                text={t('cancel.title')}
                style={styles.unsubscribeButton}
                onPress={unsubscribe}
              />
            </>
          )}
          <Separator height={50} />
          <UserStorageInfo />
          <Separator height={40} />
        </View>
      </ScrollView>
    </View>
  )
}

const SUBSCRIPTIONS_CONTENT_MAX_WIDTH = 1600
const styles = StyleSheet.create({
  container: {
    ...appStyles.row,
    ...appStyles.fullSize,
    backgroundColor: Colors['layout.white'],
  },
  scrollContainer: {
    ...appStyles.fullSize,
    backgroundColor: Colors['layout.light'],
  },
  scrollContentContainer: {
    height: 0,
    flexGrow: 1,
    borderRightWidth: 1,
    backgroundColor: Colors['layout.light'],
    borderColor: convertHexToRGBA(Colors['layout.gray'], 0.5),
  },
  header: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingBottom: 24,
    borderBottomWidth: 1,
    ...appStyles.inlineContainer,
    backgroundColor: Colors['layout.white'],
    borderColor: convertHexToRGBA(Colors['layout.gray'], 0.5),
  },
  headerText: {
    fontSize: 20,
    lineHeight: 24,
  },
  planSwitcherContainer: {
    ...appStyles.inlineContainer,
    justifyContent: 'space-between',
  },
  contentContainer: {
    marginBottom: 20,
    paddingHorizontal: 20,
  },
  titleText: { fontSize: 26 },
  descriptionText: { fontSize: 16 },
  unsubscribeButton: {
    padding: 5,
    maxWidth: 130,
    alignSelf: 'flex-start',
  },
})
