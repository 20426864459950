import React, { useEffect, useMemo, useState } from 'react'
import { View, TouchableOpacity, StyleSheet } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import AccountCard from './AccountCard'
import Separator from '../components/Separator'
import SvgIcon, { SvgName } from '../components/SvgIcon'
import ButtonText from '../components/Text/ButtonText'
import Typography from '../components/Text/Typography'
import { useMediaLibraryAddActionSheet } from '../hooks/useMediaLibraryAddActionSheet'
import usePassiveUserCheck from '../hooks/usePassiveUserCheck'
import { useWebLayout, SIDE_COLUMN_WIDTH } from '../providers/WebLayoutProvider'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import FontSizes from '../styles/FontSizes'
import { MainStackNavigationType } from '../types/navigation-types'
import { convertHexToRGBA } from '../utils/colors'

const ROOT_ROUTES = [
  'Timeline',
  'OtherMemories',
  'People',
  'MediaLibrary',
] as const

const SETTINGS_ROUTES = [
  'Subscriptions',
  'DeleteAccount',
  'ProfileSettingsView',
  'GlobalEventSettings',
  'ProfileSettingsEdit',
  'Unsubscribe',
]

const Drawer: React.FC = () => {
  const navigation = useNavigation<MainStackNavigationType<'Root'>>()
  const { t } = useTranslation()
  const checkIsPassiveUser = usePassiveUserCheck()
  const [showAddActionSheet] = useMediaLibraryAddActionSheet()

  const [activeRoute, setActiveRoute] = useState<string>()

  useEffect(() => {
    const unsub = navigation.addListener('state', e => {
      setActiveRoute(e.data.state.routes[e.data.state.index].name)
    })
    return unsub
  }, [navigation])

  const addButtonText = useMemo(() => {
    switch (activeRoute) {
      case 'People':
        return 'drawer.addPerson'
      case 'MediaLibrary':
        return 'screens.createMemory.addMedia'
      default:
        return 'drawer.addMemory'
    }
  }, [activeRoute])

  const { webLayoutEnabled } = useWebLayout()
  if (!webLayoutEnabled) return null

  const renderItem = (
    icon: SvgName,
    text: string,
    route:
      | 'Timeline'
      | 'OtherMemories'
      | 'People'
      | 'Settings'
      | 'MediaLibrary',
  ) => {
    const isFocused =
      route === activeRoute ||
      (activeRoute &&
        route === 'Settings' &&
        SETTINGS_ROUTES.includes(activeRoute))
    return (
      <TouchableOpacity
        key={route}
        style={styles.item}
        onPress={() => navigation.navigate(route)}>
        <SvgIcon
          name={icon}
          color={
            isFocused
              ? Colors['brand.action']
              : convertHexToRGBA(Colors['layout.dark'], 0.5)
          }
        />
        <Separator width={10} />
        <Typography
          weight={isFocused ? 'bold' : undefined}
          style={{ fontSize: FontSizes['button.medium'] }}>
          {text}
        </Typography>
      </TouchableOpacity>
    )
  }

  return (
    <View style={styles.container}>
      <View style={styles.logoContainer}>
        <SvgIcon name="logo-colored" />
      </View>
      <Separator height={42} />

      {ROOT_ROUTES.map(route =>
        renderItem(`drawer-${route}`, t(`drawer.${route}`), route),
      )}
      <TouchableOpacity
        style={styles.button}
        onPress={() => {
          if (activeRoute === 'MediaLibrary') return showAddActionSheet()

          checkIsPassiveUser(activeRoute === 'People').then(isPassive => {
            if (isPassive) return
            switch (activeRoute) {
              case 'OtherMemories':
                return navigation.navigate('MemoryForm', { eventAt: undefined })
              case 'People':
                return navigation.navigate('PersonForm')
              default:
                return navigation.navigate('MemoryTimePicker')
            }
          })
        }}>
        <SvgIcon
          name="plus"
          color={Colors['layout.white']}
          width={16}
          height={16}
        />
        <Separator width={10} />
        <ButtonText color="layout.white" size="medium">
          {t(addButtonText)}
        </ButtonText>
      </TouchableOpacity>

      <View style={styles.footerContainer}>
        <View style={styles.profileContainer}>
          <AccountCard />
        </View>
        {renderItem('drawer-Settings', t('screens.settings.title'), 'Settings')}
      </View>
    </View>
  )
}

export default Drawer

const styles = StyleSheet.create({
  container: {
    height: '100%',
    paddingTop: 41,
    paddingLeft: 122,
    paddingRight: 35,
    paddingBottom: 35,
    borderRightWidth: 1,
    width: SIDE_COLUMN_WIDTH,
    backgroundColor: Colors['layout.white'],
    borderColor: convertHexToRGBA(Colors['layout.gray'], 0.5),
  },
  logoContainer: { paddingLeft: 4 },
  item: {
    marginBottom: 20,
    paddingVertical: 10,
    ...appStyles.inlineContainer,
  },
  button: {
    height: 50,
    padding: 10,
    borderRadius: 25,
    ...appStyles.row,
    ...appStyles.center,
    backgroundColor: Colors['layout.dark'],
  },
  footerContainer: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  profileContainer: {
    paddingBottom: 10,
    ...appStyles.inlineContainer,
  },
})
