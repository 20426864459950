import React, { useMemo, useCallback } from 'react'
import { StyleSheet, View, FlatList, ListRenderItem } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import {
  AccountTypesDetailedListItemProps,
  AccountTypesDetailedListItem,
} from './components/AccountTypesDetailedListItem'
import { GroupAccountTypeEnum } from '../../api/types'
import Separator from '../../components/Separator'
import Typography from '../../components/Text/Typography'
import TouchableSvg from '../../components/TouchableSvg'
import { getAccountTypeItems } from '../../helpers/ProfileFormItems'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import { useWebLayout } from '../../providers/WebLayoutProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import { UnauthorizedStackNavigationType } from '../../types/navigation-types'

const Divider = () => <Separator height={15} />
type ListItemDataType = AccountTypesDetailedListItemProps['accountType']
const keyExtractor = (item: ListItemDataType, _index: number) => item.key ?? ''

export default function AccountTypesDetailedScreen(): JSX.Element {
  const { t, i18n } = useTranslation(undefined, {
    keyPrefix: 'screens.accountTypesDetailed',
  })
  const { goBack, navigate } =
    useNavigation<UnauthorizedStackNavigationType<'AccountTypesDetailed'>>()
  const { webLayoutEnabled } = useWebLayout()

  const items: ListItemDataType[] = useMemo(
    () =>
      getAccountTypeItems(i18n.t).map(item => {
        const accountType = (item.key as GroupAccountTypeEnum) ?? 'personal'
        return {
          ...item,
          subtitle: t(`subtitle.${accountType}`),
          description: t(`description.${accountType}`),
        }
      }),
    [t, i18n.t],
  )

  const onItemPress = useCallback(
    (item: ListItemDataType) =>
      navigate('Registration', {
        accountTypePickFromDetailedList: item.key as GroupAccountTypeEnum,
      }),
    [navigate],
  )

  const renderItem: ListRenderItem<ListItemDataType> = useCallback(
    ({ item }) => (
      <AccountTypesDetailedListItem
        accountType={item}
        onPress={() => onItemPress(item)}
      />
    ),
    [onItemPress],
  )

  return (
    <View style={styles.container}>
      <View style={useSafeAreaPaddedStyle(styles.header, headerOptions)}>
        <TouchableSvg name="back" color="layout.dark" onPress={goBack} />
        <Separator width={5} />
        <Typography weight="medium" style={styles.headerText}>
          {t('title')}
        </Typography>
      </View>
      <FlatList
        data={items}
        style={appStyles.fullSize}
        contentContainerStyle={[
          styles.scrollContentContainer,
          webLayoutEnabled && styles.webScrollContentContainer,
        ]}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        ItemSeparatorComponent={Divider}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    ...appStyles.fullSize,
    backgroundColor: Colors['layout.light'],
  },
  header: {
    paddingTop: 10,
    paddingBottom: 15,
    paddingHorizontal: 15,
    ...appStyles.inlineContainer,
    backgroundColor: Colors['layout.white'],
  },
  headerText: {
    fontSize: 20,
    lineHeight: 24,
  },
  scrollContentContainer: {
    flexGrow: 1,
    paddingTop: 20,
    paddingBottom: 30,
    paddingHorizontal: 25,
  },
  webScrollContentContainer: { paddingHorizontal: 100, paddingTop: 34 },
})
