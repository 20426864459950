import React, { useCallback, useMemo } from 'react'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import {
  UserGenderEnum,
  GetUserDocument,
  UserCountryEnum,
  useUpdateUserMutation,
  useDeleteProfilePictureMutation,
} from '../../api/types'
import { ProfileForm, ProfileFormProps } from '../../components/ProfileForm'
import { useDirectUpload } from '../../hooks/useDirectUpload'
import { useSetShouldRefetch, QueryKey } from '../../providers/RefetchProvider'
import { useUser } from '../../providers/UserProvider'
import { MainStackNavigationType } from '../../types/navigation-types'

export default function ProfileSettingsEditScree(): JSX.Element {
  const { goBack } =
    useNavigation<MainStackNavigationType<'ProfileSettingsEdit'>>()
  const { t } = useTranslation()
  const setShouldRefetch = useSetShouldRefetch()
  const { user } = useUser()
  const [updateUser, { client }] = useUpdateUserMutation()
  const [deleteProfilePicture] = useDeleteProfilePictureMutation()

  const { uploadMedia } = useDirectUpload()

  const initialValues: ProfileFormProps['initialValues'] = useMemo(() => {
    if (!user) return undefined

    return {
      title: user.title ?? null,
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      email: user.email ?? '',
      pictureUrl: user.pictureUrl ?? null,
      birthday: new Date(user.dateOfBirth),
      gender: user.gender ?? UserGenderEnum.Other,
      country: UserCountryEnum.Other,
    }
  }, [user])

  const validate: ProfileFormProps['validate'] = useCallback(
    ({ firstName, lastName, email }) => {
      const errors: ReturnType<ProfileFormProps['validate']>['errors'] = {}
      let hasErrors = false

      if (!firstName) {
        errors.firstName = t('profile.error.noName')
        hasErrors = true
      }
      if (!lastName) {
        errors.lastName = t('profile.error.noSurname')
        hasErrors = true
      }
      if (!email) {
        errors.email = { message: t('profile.error.noEmail') }
        hasErrors = true
      }

      return { isValid: !hasErrors, errors }
    },
    [t],
  )

  const updatePicture = useCallback(
    async (
      pictureUrl: string | null,
      picture: Parameters<ProfileFormProps['submit']>[1],
    ) => {
      if (picture) {
        const { uri, fileName, type } = picture
        return uploadMedia(uri, null, fileName, type)
      }

      if (user?.pictureUrl && !pictureUrl) {
        await deleteProfilePicture()
      }
    },
    [uploadMedia, user?.pictureUrl, deleteProfilePicture],
  )

  const submit: ProfileFormProps['submit'] = useCallback(
    ({ pictureUrl, birthday, ...userProps }, picture) => {
      return updatePicture(pictureUrl, picture)
        .then(uploadData =>
          updateUser({
            variables: {
              profileInput: {
                ...userProps,
                dateOfBirth: birthday,
                pictureBlobId: uploadData?.fileBlobId,
              },
            },
          }),
        )
        .then(result => {
          if (!result?.data?.updateProfile)
            throw new Error('Error while saving data')
          if (userProps.country !== user?.country) {
            setShouldRefetch(QueryKey.CommonMemories)
          }
          client.cache.updateQuery(
            {
              query: GetUserDocument,
            },
            previous => ({
              me: {
                ...previous.me,
                ...result?.data?.updateProfile,
              },
            }),
          )
          goBack()
        })
    },
    [
      updatePicture,
      updateUser,
      goBack,
      client.cache,
      setShouldRefetch,
      user?.country,
    ],
  )

  return (
    <ProfileForm
      initialValues={initialValues}
      submit={submit}
      validate={validate}
      onCancelPress={goBack}
    />
  )
}
