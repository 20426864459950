import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  View,
  Platform,
  StyleSheet,
  ScrollView,
  KeyboardAvoidingView,
} from 'react-native'

import { ApolloError } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import {
  UserCountryEnum,
  DatePrecisionEnum,
  GroupAccountInput,
  GroupAccountTypeEnum,
} from '../../../api/types'
import HeaderButton from '../../../components/HeaderButton'
import {
  DateInputField,
  TextInputField,
  PickerInputField,
} from '../../../components/InputFields'
import Loading from '../../../components/Loading'
import WebModal from '../../../components/WebModal'
import loggingCore from '../../../core/logging-core'
import {
  getCountriesItems,
  getAccountTypeItems,
} from '../../../helpers/ProfileFormItems'
import toISO8601DateString from '../../../helpers/toISO8601DateString'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../../hooks/useSafeAreaPaddedStyle'
import useStateRef from '../../../hooks/useStateRef'
import appStyles from '../../../styles/app-styles'
import Colors from '../../../styles/Colors'

type Errors = {
  name?: string
}
export type GroupAccountFormProps = {
  canChangeType?: boolean
  initialValues?: GroupAccountInput
  onCancelPress: () => void
  submit: (profile: GroupAccountInput) => Promise<void>
  validate: (profile: GroupAccountInput) => {
    isValid: boolean
    errors?: Errors
  }
}
export const GroupAccountForm: React.FC<GroupAccountFormProps> = ({
  submit,
  validate,
  canChangeType,
  initialValues,
  onCancelPress,
}) => {
  const { t } = useTranslation()
  const [isSaving, setIsSaving, getIsSaving] = useStateRef(false)

  const accountTypeItems = useMemo(
    () => canChangeType && getAccountTypeItems(t),
    [t, canChangeType],
  )

  const countriesItems = useMemo(() => getCountriesItems(t), [t])

  const [name, setName] = useState<string>()
  const [accountType, setType] = useState<GroupAccountTypeEnum>()
  const [country, setCountry] = useState<UserCountryEnum | null>()
  const [foundingYear, setFoundingYear] = useState<Date | null>(null)
  const [foundingYearPrec, setFoundingYearPrec] =
    useState<DatePrecisionEnum | null>()
  const [nameError, setNameError] = useState<string>('')

  useEffect(() => {
    if (!initialValues) return

    setName(initialValues.name)
    setType(initialValues.accountType)
    setFoundingYearPrec(initialValues.foundingYearPrec)
    setCountry(initialValues.country as UserCountryEnum)
    setFoundingYear(
      initialValues.foundingYear && new Date(initialValues.foundingYear),
    )
  }, [initialValues])

  const saveProfile = useCallback(() => {
    if (getIsSaving()) return

    const params = {
      accountType: accountType!,
      country,
      foundingYear: toISO8601DateString(foundingYear),
      foundingYearPrec,
      name: name!,
    }
    const { isValid, errors } = validate(params)

    if (!isValid) {
      setNameError(errors?.name ?? '')
      return
    }

    setIsSaving(true)

    submit(params)
      .catch((error: ApolloError) => {
        loggingCore.error(`Error updating account: ${error}`)
      })
      .finally(() => setIsSaving(false))
  }, [
    name,
    submit,
    country,
    validate,
    getIsSaving,
    setIsSaving,
    accountType,
    foundingYear,
    foundingYearPrec,
  ])

  return (
    <WebModal>
      <Loading blocking loading={isSaving} />
      <View style={useSafeAreaPaddedStyle(styles.header, headerOptions)}>
        <HeaderButton
          secondary
          text={t('common.cancel')}
          onPress={onCancelPress}
        />
        <HeaderButton text={t('common.save')} onPress={saveProfile} />
      </View>
      <KeyboardAvoidingView
        style={appStyles.fullSize}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        <ScrollView
          keyboardShouldPersistTaps="handled"
          style={styles.contentContainer}>
          {accountTypeItems && (
            <PickerInputField
              selectedKey={accountType}
              options={accountTypeItems}
              label={t('screens.registration.selectAccountType')}
              modalTitle={t('screens.registration.selectAccountType')}
              value={
                accountTypeItems.find(c => c.key === accountType)?.label ?? ''
              }
              onSelectKey={key => setType(key as GroupAccountTypeEnum)}
            />
          )}
          <TextInputField
            value={name ?? undefined}
            maxLength={30}
            autoCapitalize="words"
            errorMessage={nameError}
            label={t(
              `screens.registration.aboutGroup.nameLabel.${
                accountType ?? GroupAccountTypeEnum.Club
              }`,
            )}
            clearError={() => setNameError('')}
            onChangeValue={setName}
          />
          <DateInputField
            allowPrecisionChange
            value={foundingYear}
            precision={foundingYearPrec ?? undefined}
            label={t(
              `screens.registration.aboutGroup.foundedAtLabel.${
                accountType ?? GroupAccountTypeEnum.Club
              }`,
            )}
            setPrecision={setFoundingYearPrec}
            onChangeValue={setFoundingYear}
          />
          <PickerInputField
            selectedKey={country}
            options={countriesItems}
            label={t('profile.country.title')}
            modalTitle={t('modals.countryPicker.title')}
            value={countriesItems.find(c => c.key === country)?.label ?? ''}
            onSelectKey={key => setCountry(key as UserCountryEnum)}
          />
        </ScrollView>
      </KeyboardAvoidingView>
    </WebModal>
  )
}

const styles = StyleSheet.create({
  header: {
    paddingTop: 15,
    ...appStyles.row,
    paddingBottom: 23,
    paddingHorizontal: 25,
    ...appStyles.lightShadow,
    justifyContent: 'space-between',
    backgroundColor: Colors['layout.white'],
  },
  contentContainer: {
    paddingVertical: 30,
    paddingHorizontal: 25,
    ...appStyles.fullSize,
  },
})
