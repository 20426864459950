import { useEffect } from 'react'

import { useActionSheet } from '@expo/react-native-action-sheet'
import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { useAudioActionSheet } from './useAudioActionSheet'
import { useMediaActionSheet } from './useMediaActionSheet'
import { useMediaLibraryState } from '../providers/MediaLibraryProvider'

export const useMediaLibraryAddActionSheet = (): [() => void] => {
  const { showActionSheetWithOptions } = useActionSheet()
  const { navigate } = useNavigation()
  const [
    _mediaList,
    addMedia,
    _showOptions,
    _setMedia,
    isMediaLoading,
    isMediaUploading,
  ] = useMediaActionSheet(true)
  const [
    _newAudioList,
    addAudio,
    _popAudio,
    _addRecorded,
    _setNewAudioList,
    isAudioUploading,
  ] = useAudioActionSheet(true, () =>
    // @ts-ignore
    navigate('AudioRecorder', { uploadDirectly: true }),
  )
  const { t } = useTranslation()
  const { setIsUploadingMedia } = useMediaLibraryState()

  useEffect(
    () =>
      setIsUploadingMedia(
        isAudioUploading || isMediaLoading || isMediaUploading,
      ),
    [isAudioUploading, isMediaLoading, isMediaUploading, setIsUploadingMedia],
  )

  const showAddActionSheet = () => {
    const options = [
      t('screens.createMemory.addMedia'),
      t('screens.mediaLibrary.addAudio'),
      t('common.cancel'),
    ]

    const cancelButtonIndex = 2

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      buttonIndex => {
        switch (buttonIndex) {
          case 0:
            // NOTE: Opening next action sheet as this one closes may not work - opening on next frame fixes this
            setTimeout(addMedia, 0)
            break
          case 1:
            // NOTE: Opening next action sheet as this one closes may not work - opening on next frame fixes this
            setTimeout(addAudio, 0)
            break
          default:
            break
        }
      },
    )
  }
  return [showAddActionSheet]
}
