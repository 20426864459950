import React, { useMemo } from 'react'

import { SvgProps } from 'react-native-svg'

import AccountClubSvg from '../assets/svg/account-type/club.svg'
import AccountEnterpriseSvg from '../assets/svg/account-type/enterprise.svg'
import AccountFamilySvg from '../assets/svg/account-type/family.svg'
import AccountPersonalSvg from '../assets/svg/account-type/personal.svg'
import AddAudioSvg from '../assets/svg/add-audio.svg'
import AddMediaSvg from '../assets/svg/add-media.svg'
import AddSvg from '../assets/svg/add.svg'
import AlertSvg from '../assets/svg/alert.svg'
import ArrowSvg from '../assets/svg/arrow.svg'
import AudioSvg from '../assets/svg/audio.svg'
import BackSvg from '../assets/svg/back.svg'
import BellSvg from '../assets/svg/bell.svg'
import BinSvg from '../assets/svg/bin.svg'
import CalendarSvg from '../assets/svg/calendar.svg'
import CheckFilledSvg from '../assets/svg/check-filled.svg'
import CheckSvg from '../assets/svg/check.svg'
import ChevronSvg from '../assets/svg/chevron-down.svg'
import CloseSvg from '../assets/svg/close.svg'
import CloudSvg from '../assets/svg/cloud.svg'
import CrossSvg from '../assets/svg/cross.svg'
import DrawerMediaLibrarySvg from '../assets/svg/drawer-MediaLibrary.svg'
import DrawerOtherMemoriesSvg from '../assets/svg/drawer-OtherMemories.svg'
import DrawerPeopleSvg from '../assets/svg/drawer-People.svg'
import DrawerSettingsSvg from '../assets/svg/drawer-Settings.svg'
import DrawerTimelineSvg from '../assets/svg/drawer-Timeline.svg'
import ExclaimationSvg from '../assets/svg/exclaimation.svg'
import EyeSvg from '../assets/svg/eye.svg'
import AustrianSvg from '../assets/svg/flag/austrian.svg'
import EnglishSvg from '../assets/svg/flag/english.svg'
import FlagOtherSvg from '../assets/svg/flag/flag-other.svg'
import GermanSvg from '../assets/svg/flag/german.svg'
import SwissSvg from '../assets/svg/flag/swiss.svg'
import GearSvg from '../assets/svg/gear.svg'
import FemaleSvg from '../assets/svg/gender/female.svg'
import GenderOtherSvg from '../assets/svg/gender/gender-other.svg'
import MaleSvg from '../assets/svg/gender/male.svg'
import GradientLineSvg from '../assets/svg/gradient-line.svg'
import LetterSvg from '../assets/svg/letter.svg'
import LogoSvg from '../assets/svg/logo.svg'
import AppLogoSvg from '../assets/svg/logoApp.svg'
import LogoColoredSvg from '../assets/svg/logoColored.svg'
import NewMemorySvg from '../assets/svg/new-memory.svg'
import NotepadSvg from '../assets/svg/notepad.svg'
import OptionsSvg from '../assets/svg/options.svg'
import PauseSvg from '../assets/svg/pause.svg'
import PencilSvg from '../assets/svg/pencil.svg'
import PlaySvg from '../assets/svg/play.svg'
import PlusSvg from '../assets/svg/plus.svg'
import PlusPersonSvg from '../assets/svg/plusPerson.svg'
import PlusThinSvg from '../assets/svg/plusThin.svg'
import RecordEndSvg from '../assets/svg/record-end.svg'
import RecordSvg from '../assets/svg/record.svg'
import SearchSvg from '../assets/svg/search.svg'
import SelectedSvg from '../assets/svg/selected.svg'
import CardSvg from '../assets/svg/settings/card.svg'
import GlobalSvg from '../assets/svg/settings/global.svg'
import ProfileSvg from '../assets/svg/settings/profile.svg'
import SignoutSvg from '../assets/svg/settings/signout.svg'
import TermsSvg from '../assets/svg/settings/terms.svg'
import ShareAccountSvg from '../assets/svg/share-account.svg'
import ShareIosSvg from '../assets/svg/share-ios.svg'
import ShareSvg from '../assets/svg/share.svg'
import SortSvg from '../assets/svg/sort.svg'
import StoreAndroidSvg from '../assets/svg/store-android.svg'
import StoreIosSvg from '../assets/svg/store-ios.svg'
import SubscriptionsSvg from '../assets/svg/subscriptions.svg'
import UserNetworkSvg from '../assets/svg/user-network.svg'
import VideoPlaySvg from '../assets/svg/video-play.svg'

export type SvgName =
  | 'bell'
  | 'chevron'
  | 'close'
  | 'gradient-line'
  | 'logo'
  | 'logo-colored'
  | 'add-audio'
  | 'add-media'
  | 'add'
  | 'back'
  | 'calendar'
  | 'eye'
  | 'austrian'
  | 'english'
  | 'german'
  | 'swiss'
  | 'flag-other'
  | 'male'
  | 'female'
  | 'gender-other'
  | 'plus'
  | 'plusThin'
  | 'plusPerson'
  | 'pencil'
  | 'gear'
  | 'share'
  | 'share-ios'
  | 'arrow'
  | 'notepad'
  | 'global'
  | 'profile'
  | 'terms'
  | 'signout'
  | 'audio'
  | 'letter'
  | 'video-play'
  | 'options'
  | 'search'
  | 'card'
  | 'cloud'
  | 'drawer-People'
  | 'drawer-Timeline'
  | 'drawer-Settings'
  | 'drawer-OtherMemories'
  | 'drawer-MediaLibrary'
  | 'app-logo'
  | 'store-ios'
  | 'store-android'
  | 'subscriptions'
  | 'check'
  | 'alert'
  | 'play'
  | 'pause'
  | 'bin'
  | 'cross'
  | 'check-filled'
  | 'exclaimation'
  | 'sort'
  | 'selected'
  | 'record'
  | 'record-end'
  | 'new-memory'
  | 'account-personal'
  | 'account-family'
  | 'account-club'
  | 'account-enterprise'
  | 'share-account'
  | 'user-network'

const SvgIcon: React.FC<SvgProps & { name: SvgName }> = ({
  name,
  ...props
}) => {
  const Component = useMemo(() => {
    switch (name) {
      case 'bell':
        return BellSvg
      case 'chevron':
        return ChevronSvg
      case 'close':
        return CloseSvg
      case 'gradient-line':
        return GradientLineSvg
      case 'logo':
        return LogoSvg
      case 'logo-colored':
        return LogoColoredSvg
      case 'add-audio':
        return AddAudioSvg
      case 'add-media':
        return AddMediaSvg
      case 'add':
        return AddSvg
      case 'back':
        return BackSvg
      case 'calendar':
        return CalendarSvg
      case 'eye':
        return EyeSvg
      case 'austrian':
        return AustrianSvg
      case 'english':
        return EnglishSvg
      case 'german':
        return GermanSvg
      case 'swiss':
        return SwissSvg
      case 'flag-other':
        return FlagOtherSvg
      case 'male':
        return MaleSvg
      case 'female':
        return FemaleSvg
      case 'gender-other':
        return GenderOtherSvg
      case 'plus':
        return PlusSvg
      case 'plusThin':
        return PlusThinSvg
      case 'plusPerson':
        return PlusPersonSvg
      case 'pencil':
        return PencilSvg
      case 'gear':
        return GearSvg
      case 'share':
        return ShareSvg
      case 'share-ios':
        return ShareIosSvg
      case 'arrow':
        return ArrowSvg
      case 'notepad':
        return NotepadSvg
      case 'global':
        return GlobalSvg
      case 'profile':
        return ProfileSvg
      case 'terms':
        return TermsSvg
      case 'signout':
        return SignoutSvg
      case 'audio':
        return AudioSvg
      case 'letter':
        return LetterSvg
      case 'video-play':
        return VideoPlaySvg
      case 'options':
        return OptionsSvg
      case 'search':
        return SearchSvg
      case 'card':
        return CardSvg
      case 'cloud':
        return CloudSvg
      case 'drawer-People':
        return DrawerPeopleSvg
      case 'drawer-Timeline':
        return DrawerTimelineSvg
      case 'drawer-Settings':
        return DrawerSettingsSvg
      case 'drawer-OtherMemories':
        return DrawerOtherMemoriesSvg
      case 'drawer-MediaLibrary':
        return DrawerMediaLibrarySvg
      case 'app-logo':
        return AppLogoSvg
      case 'store-ios':
        return StoreIosSvg
      case 'store-android':
        return StoreAndroidSvg
      case 'subscriptions':
        return SubscriptionsSvg
      case 'check':
        return CheckSvg
      case 'alert':
        return AlertSvg
      case 'play':
        return PlaySvg
      case 'pause':
        return PauseSvg
      case 'bin':
        return BinSvg
      case 'cross':
        return CrossSvg
      case 'check-filled':
        return CheckFilledSvg
      case 'exclaimation':
        return ExclaimationSvg
      case 'sort':
        return SortSvg
      case 'selected':
        return SelectedSvg
      case 'record':
        return RecordSvg
      case 'record-end':
        return RecordEndSvg
      case 'new-memory':
        return NewMemorySvg
      case 'account-personal':
        return AccountPersonalSvg
      case 'account-family':
        return AccountFamilySvg
      case 'account-club':
        return AccountClubSvg
      case 'account-enterprise':
        return AccountEnterpriseSvg
      case 'share-account':
        return ShareAccountSvg
      case 'user-network':
        return UserNetworkSvg
    }
  }, [name])

  const defaultColor = useMemo(() => {
    switch (name) {
      case 'close':
        return '#1D352A'
      default:
        return '#F0F' // For color to work svg needs to contain "currentColor". In that case color is required
    }
  }, [name])

  const viewBox = useMemo(() => {
    // matches .svg, needed for resizing to work in web
    switch (name) {
      case 'bell':
        return '0 0 50 59'
      case 'plus':
        return '0 0 5 5'
      case 'plusThin':
        return '0 0 16 16'
      case 'plusPerson':
        return '0 0 34 34'
      case 'close':
        return '0 0 40 40'
      case 'video-play':
        return '0 0 48 48'
      case 'bin':
        return '0 0 30 30'
      case 'account-personal':
      case 'account-family':
      case 'account-club':
      case 'account-enterprise':
        return '0 0 100 100'
    }
  }, [name])

  return <Component color={defaultColor} viewBox={viewBox} {...props} />
}

export default SvgIcon
