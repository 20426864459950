import React, { useState } from 'react'
import { View, StyleSheet } from 'react-native'

import { useTranslation } from 'react-i18next'

import { DatePrecisionEnum, GroupAccountTypeEnum } from '../../../api/types'
import Button from '../../../components/Button'
import { DateInputField, TextInputField } from '../../../components/InputFields'
import Separator from '../../../components/Separator'
import TitleText from '../../../components/Text/TitleText'
import { useWebLayout } from '../../../providers/WebLayoutProvider'
import FontWeights from '../../../styles/FontWeights'

export type GroupParams = {
  foundedAt: Date | null
  groupName: string
  foundedAtPrecision: DatePrecisionEnum
}
const GroupDetailsForm: React.FC<{
  onContinue: (groupParams: GroupParams) => void
  width: number
  accountType: GroupAccountTypeEnum
}> = ({ onContinue, width, accountType }) => {
  const { t } = useTranslation()
  const { webLayoutEnabled } = useWebLayout()
  const [groupName, setGroupName] = useState<string>('')
  const [nameError, setNameError] = useState<string>('')

  const [foundedAt, setFoundedAt] = useState<Date | null>(null)
  const [foundedAtPrecision, setFoundedAtPercision] =
    useState<DatePrecisionEnum>(DatePrecisionEnum.Year)

  const onContinuePress = () => {
    if (!groupName) {
      setNameError(t('screens.registration.aboutGroup.noName'))
      return
    }

    onContinue({ groupName, foundedAt, foundedAtPrecision })
  }

  return (
    <View
      style={[
        styles.groupDetailsForm,
        { width },
        webLayoutEnabled && styles.webForm,
      ]}>
      <Separator height={20} />
      <TitleText
        color="layout.dark"
        size="medium"
        style={[styles.titleText, webLayoutEnabled && styles.webTitleText]}>
        {t(`screens.registration.aboutGroup.title.${accountType}`)}
      </TitleText>
      <Separator height={20} />
      <TextInputField
        value={groupName}
        maxLength={30}
        autoCapitalize="words"
        errorMessage={nameError}
        label={t(`screens.registration.aboutGroup.nameLabel.${accountType}`)}
        clearError={() => setNameError('')}
        onChangeValue={setGroupName}
      />
      <DateInputField
        allowPrecisionChange
        precision={foundedAtPrecision}
        setPrecision={setFoundedAtPercision}
        value={foundedAt}
        label={t(
          `screens.registration.aboutGroup.foundedAtLabel.${accountType}`,
        )}
        onChangeValue={setFoundedAt}
      />
      <Separator height={20} />
      <Button
        text={t('screens.registration.aboutGroup.continueButton')}
        onPress={onContinuePress}
      />
    </View>
  )
}

export default GroupDetailsForm

const styles = StyleSheet.create({
  titleText: {
    fontWeight: FontWeights.bold,
  },
  webTitleText: { marginTop: 14 },
  groupDetailsForm: {
    paddingHorizontal: 25,
  },
  webForm: {
    paddingHorizontal: 100,
  },
})
