import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { Linking, useWindowDimensions } from 'react-native'

import { useNavigation, useRoute, RouteProp } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { freeAddon } from './components/PassiveMembershipCard'
import { SubscriptionsScreenMobile } from './SubscriptionsScreenMobile'
import { SubscriptionsScreenWeb } from './SubscriptionsScreenWeb'
import {
  ZohoPlan,
  ZohoPlanConnection,
  HostedPaymentPageInput,
  useGetUserCurrentPlanQuery,
  useGetGroupCurrentPlanQuery,
  useGetSubscriptionPlansQuery,
  useGetPlanPaymentPageMutation,
  useGetAddonPaymentPageMutation,
  useGetGroupAccountOneTimeAddonPaymentPageMutation,
} from '../../api/types'
import Loading from '../../components/Loading'
import loggingCore from '../../core/logging-core'
import isNotBlank from '../../helpers/isNotBlank'
import { useBanner } from '../../providers/BannerProvider'
import { useUser } from '../../providers/UserProvider'
import {
  MainStackParamsType,
  MainStackNavigationType,
} from '../../types/navigation-types'
import {
  Step,
  SelectedAdons,
  CurrentAddonCodes,
  SubscriptionsScreenProps,
} from '../../types/SubscriptionScreen.types'

export const SUBSCRIPTIONS_CONTENT_MIN_WIDTH = 1000

export default function SubscriptionsScreen(): JSX.Element {
  const showBanner = useBanner()
  const { navigate } = useNavigation<MainStackNavigationType<'Subscriptions'>>()

  const { params } = useRoute<RouteProp<MainStackParamsType, 'Subscriptions'>>()
  const { t } = useTranslation(undefined, {
    keyPrefix: 'screens.settings.subscriptions',
  })
  const { account } = useUser()
  const forGroupAccountType =
    !account || params?.personalUpgrade ? undefined : account.accountType
  const dimensions = useWindowDimensions()
  const { data: plansData, loading: isLoadingPlans } =
    useGetSubscriptionPlansQuery({
      variables: {
        filter: forGroupAccountType
          ? { forGroupAccountType }
          : { forGroupAccount: false },
      },
    })
  const userCurrentPlanQuery = useGetUserCurrentPlanQuery({
    fetchPolicy: 'network-only',
    skip: !!forGroupAccountType,
  })
  const groupCurrentPlanQuery = useGetGroupCurrentPlanQuery({
    fetchPolicy: 'network-only',
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    variables: { id: account?.id! },
    skip: !forGroupAccountType || !account,
  })

  const { refetch, isLoadingCurrentPlan, currentPlanData } = useMemo(() => {
    return forGroupAccountType
      ? {
          refetch: groupCurrentPlanQuery.refetch,
          isLoadingCurrentPlan: groupCurrentPlanQuery.loading,
          currentPlanData: groupCurrentPlanQuery.data?.groupAccount,
        }
      : {
          refetch: userCurrentPlanQuery.refetch,
          isLoadingCurrentPlan: userCurrentPlanQuery.loading,
          currentPlanData: userCurrentPlanQuery.data?.me,
        }
  }, [forGroupAccountType, groupCurrentPlanQuery, userCurrentPlanQuery])

  useEffect(() => {
    const onFocus = () => refetch()
    // @ts-ignore
    window.addEventListener('focus', onFocus)
    return () => {
      // @ts-ignore
      window.removeEventListener('focus', onFocus)
    }
  }, [refetch])

  const [getPlanPaymentPage] = useGetPlanPaymentPageMutation()
  const [getAddonPaymentPage] = useGetAddonPaymentPageMutation()
  const [getGroupAccountOneTimeAddonPaymentPage] =
    useGetGroupAccountOneTimeAddonPaymentPageMutation()
  const [step, setStep] = useState<Step>('plan')
  const [showAnnual, setShowAnnual] = useState(true)
  const [selectedPlan, setSelectedPlan] = useState<ZohoPlan>()
  const [selectedAddons, setSelectedAddons] = useState<SelectedAdons>({
    duration: undefined,
    members: undefined,
    storage: undefined,
  })
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const freePlan: ZohoPlan = useMemo(() => {
    return {
      id: '',
      allocatedSpace: 0.5,
      name: t('freePlan.name'),
      description: t('freePlan.description'),
      targetUsers: t('freePlan.targetUsers'),
      planCode: '',
    }
  }, [t])

  const plans: ZohoPlan[] = useMemo(() => {
    if (!plansData) return []
    const filteredPlans = plansData.zohoPlans.nodes.filter(
      plan => plan.anual === showAnnual,
    ) as ZohoPlan[]
    return [freePlan, ...filteredPlans]
  }, [plansData, freePlan, showAnnual])

  const currentPlanCode = currentPlanData?.currentPlanCode

  const currentPlan: ZohoPlan | undefined = useMemo(
    () =>
      plansData?.zohoPlans &&
      (plansData.zohoPlans as ZohoPlanConnection).nodes?.find(
        plan => plan.planCode === currentPlanCode,
      ),
    [currentPlanCode, plansData],
  )

  const currentAddonCodes: CurrentAddonCodes = useMemo(() => {
    const currentAddons = currentPlan?.zohoAddons?.nodes.filter(addon =>
      currentPlanData?.currentAddonCodes?.includes(addon.addonCode),
    )
    return {
      duration: currentAddons?.find(addon => addon.extraTimeAddon)?.addonCode,
      members: currentAddons?.find(addon => addon.extraAccessAddon)?.addonCode,
      storage: currentAddons?.find(addon => addon.extraStorageAddon)?.addonCode,
    }
  }, [currentPlanData?.currentAddonCodes, currentPlan])

  const resetSelectedPlan = useCallback(() => {
    setSelectedPlan(plans.find(plan => plan.planCode === currentPlanCode))
  }, [currentPlanCode, plans])

  useEffect(() => {
    resetSelectedPlan()
  }, [resetSelectedPlan, plans])

  useEffect(() => {
    setSelectedAddons({
      duration: freeAddon,
      members: freeAddon,
      storage: freeAddon,
    })
  }, [selectedPlan?.id])

  useEffect(() => {
    if (currentPlan && currentPlan.anual !== showAnnual)
      setShowAnnual(currentPlan.anual ?? true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlan])

  const openPaymentPageUrl = useCallback(async () => {
    setIsLoading(true)
    const input: HostedPaymentPageInput = {
      planCode: selectedPlan?.planCode,
      addonCodes: Object.values(selectedAddons)
        .filter(isNotBlank)
        .map(addon => addon?.addonCode),
    }

    const [mutation, dataKey] =
      currentPlanCode !== selectedPlan?.planCode
        ? [getPlanPaymentPage, 'getHostedPaymentPage']
        : forGroupAccountType
        ? [
            getGroupAccountOneTimeAddonPaymentPage,
            'getHostedPaymentPageGroupAccountOneTimeAddon',
          ]
        : [
            getAddonPaymentPage,
            'getHostedPaymentPageExpiredSubscriptionNewAddon',
          ]

    const url = await mutation({ variables: { input } })
      // @ts-ignore
      .then(({ data }) => data?.[dataKey].paymentPageUrl)
      .catch(error => {
        if (
          error?.graphQLErrors?.[0]?.message === 'generic_error' &&
          error.graphQLErrors[0].extensions?.message?.includes?.(
            'Missing profile attributes',
          )
        ) {
          let missingAttributes
          try {
            missingAttributes = JSON.parse(
              error.graphQLErrors[0].extensions?.message,
            ).missing_attributes
          } catch (_) {
            missingAttributes = []
          }
          showBanner({
            color: 'brand.red',
            description: `${t('missingProfileAttributes')}: ${missingAttributes
              .map((attributeKey: string) =>
                t(`profileAttributes.${attributeKey}`, attributeKey),
              )
              .join(', ')}`,
          })
        }
        loggingCore.error(error)
        return undefined
      })

    if (url) Linking.openURL(url)
    setIsLoading(false)
  }, [
    t,
    showBanner,
    setIsLoading,
    selectedAddons,
    currentPlanCode,
    getPlanPaymentPage,
    getAddonPaymentPage,
    forGroupAccountType,
    selectedPlan?.planCode,
    getGroupAccountOneTimeAddonPaymentPage,
  ])

  const selectAddon: SubscriptionsScreenProps['selectAddon'] = useCallback(
    (type, addon) => {
      setSelectedAddons(current => ({ ...current, [type]: addon }))
    },
    [setSelectedAddons],
  )

  const unsubscribe = useCallback(() => navigate('Unsubscribe'), [navigate])

  const webLayoutEnabled = dimensions.width >= SUBSCRIPTIONS_CONTENT_MIN_WIDTH

  const props: SubscriptionsScreenProps = {
    step,
    plans,
    setStep,
    showAnnual,
    unsubscribe,
    selectAddon,
    selectedPlan,
    setShowAnnual,
    termsAccepted,
    selectedAddons,
    currentPlanCode,
    setSelectedPlan,
    setTermsAccepted,
    currentAddonCodes,
    resetSelectedPlan,
    openPaymentPageUrl,
    forGroupAccountType,
  }

  return (
    <>
      <Loading
        blocking={isLoading}
        loading={isLoading || isLoadingPlans || isLoadingCurrentPlan}
      />
      {webLayoutEnabled ? (
        <SubscriptionsScreenWeb {...props} />
      ) : (
        <SubscriptionsScreenMobile {...props} />
      )}
    </>
  )
}
